import { IconCheckAnswer, IconNextQuestion } from 'assets';
import React, { useEffect, useRef } from 'react';
import styles from './GameInputPanel.module.scss';

interface GameInputPanelProps {
  gameHasStarted: boolean;
  roundHasChanged: boolean;
  handleCheckAnswer: () => void;
  handleNextQuestion: () => void;
  userAnswer: string;
  setUserAnswer: (value: string) => void;
}

export default function GameInputPanel({ gameHasStarted, handleCheckAnswer, handleNextQuestion, roundHasChanged, setUserAnswer, userAnswer }: GameInputPanelProps) {
  const inputRef = useRef(null);

  useEffect(() => {
    inputRef.current && inputRef.current.focus();
  }, [gameHasStarted, roundHasChanged]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (inputRef.current && !inputRef.current.contains(event.target as Node)) {
        event.preventDefault();
        inputRef.current.focus();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    // Regular expression to match only numeric values
    const regex = /^[0-9]*$/;
    if (regex.test(value)) {
      setUserAnswer(value);
    }
  };

  const handleEnterKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      if (roundHasChanged) {
        handleNextQuestion();
      } else if (!gameHasStarted && userAnswer) {
        handleCheckAnswer();
      }
    }
  };

  return (
    <div className={styles.inputPanel}>
      <input disabled={gameHasStarted} placeholder="Type Answer Here..." type="text" className={styles.input} value={userAnswer} onChange={handleInputChange} ref={inputRef} onKeyDown={handleEnterKeyDown} />
      {roundHasChanged ? (
        <button onClick={handleNextQuestion} className={styles.button}>
          <IconNextQuestion width="40" fill={'var(--white)'} />
        </button>
      ) : (
        <button onClick={handleCheckAnswer} className={styles.button} disabled={gameHasStarted || !userAnswer}>
          <IconCheckAnswer width="40" fill={'var(--white)'} />
        </button>
      )}
    </div>
  );
}
