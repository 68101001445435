import { useState, useEffect } from 'react';
import styles from './ProtectedTeacherRoute.module.scss';
import { Navigate, Outlet } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import * as ROUTES from 'helpers/routes';
import Skeleton from 'react-loading-skeleton';
import { IconLogo } from 'assets';
import GroupStackSkeleton from 'components/Teacher/GroupStack/skeleton';
import HomeworkTableSkeleton from 'components/Teacher/HomeworkTable/skeleton';
import StudentTableSkeleton from 'components/Teacher/StudentTable/skeleton';
import { useAppDispatch } from 'store/configureStore';
import { getGroups, getHomeworks, getStudents } from 'store/teacherSlice';
import { useDispatchContext } from 'context/DispatchContext';

const ProtectedTeacherRoute = () => {
  const { logout, isAuthenticated, isLoading: authLoading, getAccessTokenSilently } = useAuth0();
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useAppDispatch();
  const { setDispatched } = useDispatchContext();

  useEffect(() => {
    if (!authLoading) {
      const timer = setTimeout(() => setIsLoading(false), 2000);
      return () => clearTimeout(timer);
    }
  }, [authLoading]);

  useEffect(() => {
    const fetchAndSetToken = async () => {
      try {
        const token = await getAccessTokenSilently();
        localStorage.setItem('tokenAdmin', token);
      } catch (error) {}
    };
    if (isAuthenticated) {
      fetchAndSetToken().then(() => {
        console.log('DISPATCHING');
        dispatch(getStudents());
        dispatch(getGroups());
        dispatch(getHomeworks());
        setDispatched(true);
      });
    } else if (!isAuthenticated && !authLoading) {
      localStorage.removeItem('tokenAdmin');
      logout({ logoutParams: { returnTo: `${window.location.origin}${ROUTES.TEACHER_LOGIN}` } });
    }
  }, [isAuthenticated, getAccessTokenSilently, dispatch, setDispatched, logout, authLoading]);

  const lastVisitedTeacherRoute = localStorage.getItem('lastVisitedTeacherRoute');

  function RenderSkeleton() {
    switch (lastVisitedTeacherRoute) {
      case ROUTES.TEACHER_STUDENTS:
        return <TeacherStudentSkeleton />;
      case ROUTES.TEACHER_HOMEWORK:
        return <TeacherHomeworkSkeleton />;
      case ROUTES.TEACHER_ABACUS:
        return <TeacherAbacusSkeleton />;
      default:
        return <TeacherStudentSkeleton />;
    }
  }

  if (isLoading) {
    return <RenderSkeleton />;
  }

  return isAuthenticated ? <Outlet /> : <Navigate to={ROUTES.TEACHER_LOGIN} />;
};

export default ProtectedTeacherRoute;

const textHeight = 35;
const btnHeight = 47;
const smTextHeight = 32;

function NavSkeleton() {
  return (
    <div className={styles.skNav}>
      <IconLogo height="40" />
      <div className={styles.skNavLinks}>
        <Skeleton className="skeleton" height={smTextHeight} width={122} />
        <Skeleton className="skeleton" height={smTextHeight} width={122} />
        <Skeleton className="skeleton" height={smTextHeight} width={122} />
      </div>
      <Skeleton className="skeleton" height={60} width={60} style={{ borderRadius: 60 }} />
    </div>
  );
}

function TeacherStudentSkeleton() {
  return (
    <div className={styles.skView}>
      <NavSkeleton />
      <div className={styles.skApp}>
        <div className={styles.skSection}>
          <div className={styles.skHeader}>
            <Skeleton className="skeleton" height={textHeight} width={105} />
            <Skeleton className="skeleton" height={btnHeight} width={206} />
          </div>
          <div className={styles.skGroupStack}>
            <GroupStackSkeleton />
          </div>
        </div>
        <div className={styles.skSection}>
          <div className={styles.skHeader}>
            <Skeleton className="skeleton" height={textHeight} width={105} />
            <Skeleton className="skeleton" height={btnHeight} width={206} />
          </div>

          <StudentTableSkeleton />
          {/* <div className={styles.skGroupStack}>
              <GroupStackSkeleton />
            </div> */}
        </div>
      </div>
    </div>
  );
}

function TeacherHomeworkSkeleton() {
  return (
    <div className={styles.skView}>
      <NavSkeleton />
      <div className={styles.skApp}>
        <div className={styles.skSection}>
          <div className={styles.skHeader}>
            <Skeleton className="skeleton" height={textHeight} width={157} />
            <Skeleton className="skeleton" height={btnHeight} width={250} />
          </div>

          <HomeworkTableSkeleton />
        </div>
      </div>
    </div>
  );
}

function TeacherAbacusSkeleton() {
  return (
    <div className={styles.skView}>
      <NavSkeleton />
      <div className={styles.skApp}>
        <div className={styles.skCardRow}>
          <Skeleton className="skeleton" height={370} style={{ borderRadius: 10 }} />
          <Skeleton className="skeleton" height={370} style={{ borderRadius: 10 }} />
        </div>
      </div>
    </div>
  );
}
