import { GameCategory } from 'cubiq-abacus-types';

const FORMULA_5S_ADD = [
  { value: 1, label: '+1 = +5 - 4' },
  { value: 2, label: '+2 = +5 - 4' },
  { value: 3, label: '+3 = +5 - 2' },
  { value: 4, label: '+4 = +5 - 1' },
];

const FORMULA_5S_SUB = [
  { value: 1, label: '-1 = -5 + 4' },
  { value: 2, label: '-2 = -5 + 4' },
  { value: 3, label: '-3 = -5 + 2' },
  { value: 4, label: '-4 = -5 + 1' },
];

const FORMULA_10S_ADD = [
  { value: 1, label: '+1 = +10 - 9' },
  { value: 2, label: '+2 = +10 - 8' },
  { value: 3, label: '+3 = +10 - 7' },
  { value: 4, label: '+4 = +10 - 6' },
  { value: 5, label: '+5 = +10 - 5' },
  { value: 6, label: '+6 = +10 - 4' },
  { value: 7, label: '+7 = +10 - 3' },
  { value: 8, label: '+8 = +10 - 2' },
  { value: 9, label: '+9 = +10 - 1' },
];

const FORMULA_10S_SUB = [
  { value: 1, label: '-1 = -10 + 9' },
  { value: 2, label: '-2 = -10 + 8' },
  { value: 3, label: '-3 = -10 + 7' },
  { value: 4, label: '-4 = -10 + 6' },
  { value: 5, label: '-5 = -10 + 5' },
  { value: 6, label: '-6 = -10 + 4' },
  { value: 7, label: '-7 = -10 + 3' },
  { value: 8, label: '-8 = -10 + 2' },
  { value: 9, label: '-9 = -10 + 1' },
];

const FORMULA_MIX_ADD = [
  { value: 6, label: '+6 = +1 - 5 + 10' },
  { value: 7, label: '+7 = +2 - 5 + 10' },
  { value: 8, label: '+8 = +3 - 5 + 10' },
  { value: 9, label: '+9 = +4 - 5 + 10' },
];

const FORMULA_MIX_SUB = [
  { value: 6, label: '-6 = -1 + 5 - 10' },
  { value: 7, label: '-7 = -2 + 5 - 10' },
  { value: 8, label: '-8 = -3 + 5 - 10' },
  { value: 9, label: '-9 = -4 + 5 - 10' },
];

export const getFormulaOptions = (gameCategory) => {
  switch (gameCategory) {
    case GameCategory['5S_ADD']:
      return FORMULA_5S_ADD;
    case GameCategory['5S_SUB']:
      return FORMULA_5S_SUB;
    case GameCategory['10S_ADD']:
      return FORMULA_10S_ADD;
    case GameCategory['10S_SUB']:
      return FORMULA_10S_SUB;
    case GameCategory['MIX_ADD']:
      return FORMULA_MIX_ADD;
    case GameCategory['MIX_SUB']:
      return FORMULA_MIX_SUB;
    default:
      return []; // Return an empty array or a default set of options if necessary
  }
};
