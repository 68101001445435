import React from 'react';
import { Field } from 'formik';
import { IconImageUpload } from 'assets';

interface AvatarFieldProps {
  name: string;
  label: string;
  avatars: string[];
  error: boolean;
  setCustomFile: any;
}

const AvatarField: React.FC<AvatarFieldProps> = ({ name, label, avatars, error, setCustomFile }) => (
  <div className={`field-flex ${error && 'error'}`}>
    <label>{label}:</label>
    <div className="avatarGroup">
      <label className="uploadAvatar" htmlFor={'file'}>
        <IconImageUpload width="30" fill="var(--primary)" />
      </label>

      {avatars.map((avatar, i) => (
        <div className="avatar" key={i} onClick={() => setCustomFile(null)}>
          <Field id={`avatar-${i}`} type="radio" name={name} value={avatar} className="avatar-radio" />
          <label htmlFor={`avatar-${i}`}>
            <img src={`/avatars/${avatar}.jpg`} alt="avatar" className="avatar-image" />
          </label>
        </div>
      ))}
    </div>
  </div>
);

export default AvatarField;
