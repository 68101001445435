import styles from '../style.module.scss';
import { IconCheckCorrect, IconCorrectAnswer, IconIncorrectAnswer, IconStopwatch } from 'assets';
import { IStudentState } from 'store/studentSlice';
import { GameType } from 'cubiq-abacus-types';
import { useAppSelector } from 'store/combinedReducer';

export default function StudentHomeworkTab__SectionCompletedAssignments() {
  const student: IStudentState = useAppSelector((state) => state.student);
  return (
    <div className={styles.section}>
      <h1 className={styles.section__title}>Completed Assignments: {student.completedAssignments.length}</h1>
      <div className={styles.listFrame}>
        {student.completedAssignments.map((assignment) => (
          <div key={assignment._id}>
            <div className={styles.listFrame__header}>
              <div className={styles.listFrame__title}>{assignment.homeworkObjectId.gameSettings.mode === GameType.Column ? 'Column Abacus' : assignment.homeworkObjectId.gameSettings.mode === GameType.Flash ? 'Flash Abacus' : ''} </div>

              <div className={styles.right}>
                <div className={styles.right__iconText}>
                  <IconStopwatch width="20" fill="var(--grey)" />
                  <label>{assignment.completedTime}</label>
                </div>
                <div className={styles.right__iconText}>
                  <IconCheckCorrect width="20" fill="var(--grey)" />
                  <label>{assignment.correctAnswers}</label>
                </div>
                <div className={styles.right__iconText}>
                  <img src="/stars/Artboard 4.png" alt="" />
                  <label>{assignment.stars}</label>
                </div>
              </div>
            </div>

            <div className={styles.smallGreyText}>Completed on {assignment.completedOn}</div>

            <div className={styles.bank}>
              {assignment.bank.map((question) => (
                <div className={styles.bank__item} key={question.question}>
                  <label>{question.isCorrect ? <IconCorrectAnswer width="15" /> : <IconIncorrectAnswer width="15" />}</label>
                  <label>{question.question}</label>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
