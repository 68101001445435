import React from 'react';
import styles from './style.module.scss';
import { useAppDispatch } from 'store/configureStore';
import { logout } from 'store/studentSlice';

import { IconStudentSidebarHomeworks, IconStudentSidebarLogout, IconStudentSidebarPractice, IconToggleSidebar } from 'assets';

type ActiveContent = 'homework' | 'practice';

interface SidebarProps {
  onToggleSidebar: () => void;
  activeContent: ActiveContent;
  onContentChange: (content: ActiveContent) => void;
}

const SidebarMin: React.FC<SidebarProps> = ({ onToggleSidebar, onContentChange, activeContent }) => {
  const dispatch = useAppDispatch();

  const handleLogout = () => {
    if (window.confirm('Are you sure you want to logout?')) {
      dispatch(logout());
    }
  };

  return (
    <div className={styles.sidebar}>
      <div className={styles.header}>
        <button onClick={onToggleSidebar} className={styles.header__toggleButton} title="Toggle Sidebar">
          <IconToggleSidebar height="50" />
        </button>
        <button className={`${styles.header__menuButton} ${activeContent === 'homework' ? styles.header__menuButton__active : ''}`} title="Homework" onClick={() => onContentChange('homework')}>
          <IconStudentSidebarHomeworks width="35" />
        </button>
        <button className={`${styles.header__menuButton} ${activeContent === 'practice' ? styles.header__menuButton__active : ''}`} title="Practice" onClick={() => onContentChange('practice')}>
          <IconStudentSidebarPractice width="35" />
        </button>
      </div>
      <div className={styles.footer}>
        <button className={styles.header__menuButton_logout} title="Log Out" onClick={handleLogout}>
          <IconStudentSidebarLogout width="35" />
        </button>
      </div>
    </div>
  );
};

export default SidebarMin;
