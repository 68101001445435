import Skeleton from 'react-loading-skeleton';

function StudentTableSkeleton() {
  return (
    <>
      <Skeleton height={40} className="skeleton" />
      <table className="user-table">
        <thead>
          <tr>
            <th>Name</th>
            <th>Email</th>
            <th>Group</th>
            <th>Edit</th>
          </tr>
        </thead>
        <tbody></tbody>
      </table>
      <div className="skeleton-list">
        {Array(6)
          .fill(0)
          .map((_, index) => (
            <Skeleton className="skeleton " key={index} height={78} style={{ borderRadius: '0px' }} />
          ))}
      </div>
    </>
  );
}

export default StudentTableSkeleton;
