import { AxiosError } from 'axios';
import { ErrorResponse } from 'cubiq-abacus-types';
import { enqueueSnackbar } from 'store/snackbarSlice';
import { TeacherReducer } from 'store/teacherSlice';

export const checkToken = (dispatch: any, reducer: TeacherReducer) => {
  const token = localStorage.getItem('tokenAdmin');
  if (!token) {
    dispatch(reducer.noTokenFound());
    return false;
  }

  dispatch(reducer.tokenFound());

  const authHeader = {
    Authorization: `Bearer ${token}`,
  };

  return authHeader;
};

export const handleError = (dispatch: any, error: AxiosError<ErrorResponse>, errorAction: (payload: any) => void) => {
  console.error(error);
  dispatch(enqueueSnackbar(error.response?.data || 'Server might be down, please check back later', { variant: 'error' }));
  dispatch(errorAction(error.response));
};
