import React, { useState } from 'react';
import { Formik, Form, FormikProps } from 'formik';
import * as Yup from 'yup';
import Button from 'components/Global/Button';
import { deleteStudent, updateStudent, uploadStudentProfileAndUpdate } from 'store/teacherSlice';
import { IGroupGet, IStudentPut, IStudentDelete, IStudentGet } from 'cubiq-abacus-types';
import { useAppDispatch } from 'store/configureStore';
import { AvatarField, ErrorSummary, GenericField, PasswordField, SelectField } from 'components/Teacher/Form';
import { IconDelete } from 'assets';
import styles from './styles.module.scss';
import Cropper from 'react-easy-crop';
import { Point, Area } from 'react-easy-crop/types';
import { getCroppedImg, dataURLtoFile } from 'components/utils/image';

interface EditStudentPopupProps {
  student: IStudentGet['response'];
  groups: IGroupGet['response'][];
  closePopup: () => void;
}

const validationSchema = Yup.object({
  name: Yup.string().required('Name is required'),
  email: Yup.string().email('Please enter a valid email address').required('Email is required'),
  group: Yup.string(),
  password: Yup.string(),
});

const EditStudentPopup: React.FC<EditStudentPopupProps> = ({ student, groups, closePopup }) => {
  const [file, setFile] = React.useState(null);
  const [crop, setCrop] = useState<Point>({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedArea, setCroppedArea] = useState(null);

  const onCropComplete = (croppedArea: Area, croppedAreaPixels: Area) => {
    console.log('onCropComplete', croppedArea, croppedAreaPixels);
    setCroppedArea(croppedAreaPixels);
  };

  const dispatch = useAppDispatch();

  const initialValues: IStudentPut['payload'] = {
    name: student.name,
    email: student.email,
    groupId: student.groupObjectId.groupId,
    password: '',
    avatar: student.avatar,
  };

  const handleEditStudent = async (payload: IStudentPut['payload']) => {
    if (window.confirm('Are you sure you want to change the info of this student?')) {
      if (payload.avatar.includes('CUSTOM_AVATAR_LOCATION')) {
        const canvas = await getCroppedImg(file, croppedArea);
        const canvasDataUrl = await canvas.toDataURL('image/jpg');
        const convertedUrlToFile = dataURLtoFile(canvasDataUrl, 'cropped-image.jpg');
        console.log('convertedUrlToFile', convertedUrlToFile);
        const formData = new FormData();
        formData.append('croppedImage', convertedUrlToFile);

        dispatch(uploadStudentProfileAndUpdate(formData, payload));
      } else {
        dispatch(updateStudent(payload));
      }

      closePopup();
    }
  };

  const handleDeleteStudent = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    if (window.confirm('Are you sure you want to delete this student?')) {
      const payload: IStudentDelete['payload'] = { email: student.email };
      dispatch(deleteStudent(payload));
      closePopup();
    }
  };

  const mappedGroupOptions = groups.map((group) => ({ value: group.groupId, label: group.groupName }));
  const mappedGroupOptionsIncludingUnassigned = [{ value: 'g-unassigned', label: 'Unassigned' }, ...mappedGroupOptions];

  const fileHandler = (e, setFieldValue) => {
    if (e.target.files?.length > 0) {
      const reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);
      reader.addEventListener('load', () => {
        setFile(reader.result);
        setFieldValue('avatar', 'CUSTOM_AVATAR_LOCATION');
      });
    }
  };

  return (
    <>
      <div className={styles.overlay} onClick={closePopup}></div>
      <div className={styles.popup}>
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleEditStudent}>
          {({ errors, touched, isValid, resetForm, dirty, setFieldValue }: FormikProps<IStudentPut['payload']>) => (
            <Form>
              <div className="section-header">
                <label>Edit Student</label>
                <Button type={'button'} className={styles['list-action-button']} onClick={(e) => handleDeleteStudent(e)}>
                  <p style={{ color: 'var(--red_text)' }}>Delete Student</p>
                  <IconDelete width="20" fill="var(--red_text)" />
                </Button>
              </div>
              <div className={styles['fields-stack']}>
                <GenericField name="name" label="Name" type="text" placeholder="Enter name" error={errors.name && touched.name} required />
                <GenericField name="email" label="Email" type="email" placeholder="Enter email" error={errors.email && touched.email} required disabled />
                <PasswordField name="password" label="New Password" placeholder="Enter new password" />
                <SelectField name="groupId" label="Group" options={mappedGroupOptionsIncludingUnassigned} error={errors.groupId && touched.groupId} />
                <AvatarField setCustomFile={setFile} name="avatar" label="Avatar" avatars={Array.from({ length: 3 }, (_, i) => `default-avatar-0${i}`)} error={errors.avatar && touched.avatar} />
                <input style={{ display: 'none' }} type="file" id="file" accept=".png,.jpg,.jpeg" onChange={(e) => fileHandler(e, setFieldValue)} />
                {file && (
                  <>
                    <Cropper
                      style={{ containerStyle: { width: 200, height: 200, position: 'relative', alignSelf: 'center' } }}
                      cropShape={'round'}
                      cropSize={{ width: 200, height: 200 }}
                      image={file}
                      crop={crop}
                      zoom={zoom}
                      aspect={1}
                      onCropChange={setCrop}
                      onCropComplete={onCropComplete}
                      onZoomChange={setZoom}
                    />
                  </>
                )}
              </div>

              <ErrorSummary errors={errors} touched={touched} />

              <div className={styles['button-footer']}>
                <Button
                  theme={'secondary'}
                  onClick={() => {
                    closePopup();
                    resetForm();
                  }}
                  type="button"
                >
                  Cancel
                </Button>
                <Button theme={'primary'} type="submit" disabled={!dirty || !isValid}>
                  Save Changes
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default EditStudentPopup;
