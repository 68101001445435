import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppSelector } from 'store/combinedReducer';
import { IStudentState, self } from 'store/studentSlice';
import SidebarNormal from 'components/Student/Sidebar/Normal';
import SidebarMin from 'components/Student/Sidebar/Min';
import Homework from 'components/Student/StudentHomeworkTab';
import * as ROUTES from 'helpers/routes';
import { useDispatchContext } from 'context/DispatchContext';
import { useAppDispatch } from 'store/configureStore';
import HomeworkSkeleton from 'components/Student/StudentHomeworkTab/skeleton';
import SidebarNormalSkeleton from 'components/Student/Sidebar/Normal/skeleton';
import GamePortal from 'components/Student/GamePortal';
import Practice from 'components/Student/Practice';

type ActiveContent = 'homework' | 'practice';

const PageStudentDashboard: React.FC = () => {
  const [sidebarMoved, setSidebarMoved] = useState(false);
  const [activeContent, setActiveContent] = useState<ActiveContent>('homework');
  const [loading, setLoading] = useState(true);
  const { dispatched, setDispatched } = useDispatchContext();
  const dispatch = useAppDispatch();

  const student: IStudentState = useAppSelector((state) => state.student);
  const navigate = useNavigate();
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const asId = queryParams.get('asId');
  const foundAssignment = student.assignments.find((assignment) => assignment._id === asId);

  const handleSidebarToggle = () => {
    setSidebarMoved(!sidebarMoved);
  };

  const handleContentChange = (content: ActiveContent) => {
    setActiveContent(content);
  };

  useEffect(() => {
    document.title = 'Student Dashboard';
  }, []);

  useEffect(() => {
    if (!dispatched && !student?.self?.email) {
      dispatch(self());
      setDispatched(true);
    }
  }, [dispatch]);

  useEffect(() => {
    if (!loading && !student?.self?.email) {
      navigate(ROUTES.STUDENT_LOGIN);
    }
  }, [student.self, navigate, loading]);

  useEffect(() => {
    if (dispatched) setLoading(false);

    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, [dispatch]);

  return asId && foundAssignment ? (
    <GamePortal foundAssignment={foundAssignment} />
  ) : student?.self?.email && !loading ? (
    <section style={{ display: 'flex', backgroundColor: 'var(--primary-transparent1)' }}>
      {!sidebarMoved && <SidebarNormal onToggleSidebar={handleSidebarToggle} onContentChange={handleContentChange} activeContent={activeContent} />}
      {sidebarMoved && <SidebarMin onToggleSidebar={handleSidebarToggle} onContentChange={handleContentChange} activeContent={activeContent} />}

      {activeContent === 'homework' && <Homework />}
      {activeContent === 'practice' && <Practice />}
    </section>
  ) : (
    <section style={{ display: 'flex', backgroundColor: 'var(--primary-transparent1)' }}>
      <SidebarNormalSkeleton />
      <HomeworkSkeleton />
    </section>
  );
};

export default PageStudentDashboard;
