import React from 'react';
import { Field } from 'formik';

interface IncDecNumberFieldProps {
  name: string;
  label: string;
  error: boolean;
  required?: boolean;
}

const IncDecNumberField: React.FC<IncDecNumberFieldProps> = ({ name, label, error, required = false }) => {
  return (
    <div className={`field-flex ${error && 'error'}`}>
      <label>
        {label}
        {required && <span className="asterisk">*</span>}:
      </label>
      <Field placeholder={`Enter ${label}`} name={name} type="number" />
    </div>
  );
};

export default IncDecNumberField;
