import Skeleton from 'react-loading-skeleton';
import styles from './style.module.scss';

export default function HomeworkSkeleton() {
  return (
    <>
      <div className={styles.content}>
        <div className={styles.sectionWrapper}>
          <div className={styles.section}>
            <Skeleton width={400} height={32} className="skeleton" />
            <div className={styles.cards}>
              <Skeleton height={360} className="skeleton" />
              <Skeleton height={360} className="skeleton" />
              <Skeleton height={360} className="skeleton" />
            </div>
          </div>
          <div className={styles.section}>
            <Skeleton width={400} height={32} className="skeleton" />
            <Skeleton height={120} className="skeleton" />
          </div>
        </div>
      </div>
    </>
  );
}
