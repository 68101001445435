import React, { useEffect } from 'react';
import { SnackbarProvider, useSnackbar } from 'notistack';

// * Redux
import { useAppDispatch } from 'store/configureStore';
import { useAppSelector } from 'store/combinedReducer';
import { removeSnackbar } from 'store/snackbarSlice';

import { IconClose } from 'assets';

import './Snackbar.override.scss';

// write a hoc for SnackbarProviderexport
export const SnackProvider = ({ children }) => {
  return (
    <SnackbarProvider
      hideIconVariant
      autoHideDuration={3000}
      maxSnack={5}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
    >
      {children}
    </SnackbarProvider>
  );
};

let displayed = [];

const CustomizedSnackbars = () => {
  const dispatch = useAppDispatch();
  const notifications = useAppSelector(state => state.snackbar.notifications);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const storeDisplayed = id => {
    displayed = [...displayed, id];
  };

  const removeDisplayed = id => {
    displayed = [...displayed.filter(key => id !== key)];
  };
  const notificationsCopy = [...notifications];
  useEffect(() => {
    notificationsCopy.forEach(({ key, message, options = {}, dismissed = false }) => {
      if (dismissed) {
        // dismiss snackbar using notistack
        closeSnackbar(key);
        return;
      }

      // do nothing if snackbar is already displayed
      if (displayed.includes(key)) return;

      // display snackbar using notistack
      enqueueSnackbar(message, {
        key,
        ...options,
        action: key => <IconClose height="25" onClick={() => closeSnackbar(key)} style={{ cursor: 'pointer' }} />,
        onClose: (event, reason, myKey) => {
          if (options.onClose) {
            options.onClose(event, reason, myKey);
          }
        },
        onExited: (event, myKey) => {
          try {
            // remove this snackbar from redux store
            dispatch(removeSnackbar(myKey));
            removeDisplayed(myKey);
          } catch (error) {}
        },
      });

      // keep track of snackbars that we've displayed
      storeDisplayed(key);
    });
  }, [notifications, closeSnackbar, enqueueSnackbar]);

  return null;
};

export default CustomizedSnackbars;
