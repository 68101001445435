import React from 'react';
import Button from 'components/Global/Button';
import styles from './style.module.scss';
import { IconLogo } from 'assets';
import { useAuth0 } from '@auth0/auth0-react';

const TeacherLogin: React.FC = () => {
  const { loginWithRedirect } = useAuth0();

  return (
    <div className={styles.fullPage}>
      <div className={styles.form}>
        <IconLogo className={styles.form__logo} />

        <h1 className={styles.form__title}>Teacher Login</h1>

        <Button theme={'primary'} type="submit" onClick={() => loginWithRedirect()}>
          Login
        </Button>
      </div>
    </div>
  );
};

export default TeacherLogin;
