import { useEffect, useState } from 'react';
import Button from 'components/Global/Button';
import GroupStack from 'components/Teacher/GroupStack';
import StudentTable from 'components/Teacher/StudentTable';
import { ITeacherState } from 'store/teacherSlice';
import { useAppSelector } from 'store/combinedReducer';
import { CreateNewGroupPopup, CreateNewStudentPopup, EditGroupPopup, EditStudentPopup } from 'components/Teacher/Popups';
import { useLocation } from 'react-router-dom';

function PageTeacher() {
  const location = useLocation();

  const teacher: ITeacherState = useAppSelector((state) => state.teacher);

  const [selectedGroup, setSelectedGroup] = useState<any>(null);
  const [selectedStudent, setSelectedStudent] = useState<any>(null);
  const [showNewGroupPopup, setShowNewGroupPopup] = useState(false);
  const [showNewStudentPopup, setShowNewStudentPopup] = useState(false);

  const handleGroupClick = (group: any) => {
    setSelectedGroup(group);
  };

  const handleShowNewGroupPopup = () => {
    setShowNewGroupPopup(true);
  };

  const handleStudentClick = (student: any) => {
    setSelectedStudent(student);
  };

  const handleShowNewStudentPopup = () => {
    setShowNewStudentPopup(true);
  };

  const closePopup = () => {
    setSelectedGroup(null);
    setSelectedStudent(null);
    setShowNewGroupPopup(false);
    setShowNewStudentPopup(false);
  };

  useEffect(() => {
    document.title = 'Manage Students and Groups';
  }, []);

  useEffect(() => {
    localStorage.setItem('lastVisitedTeacherRoute', location.pathname);
  }, [location]);

  return (
    <>
      <div className="App">
        <div className="section-group">
          <div className="section-header">
            <label>Groups</label>
            <Button theme={'light'} onClick={handleShowNewGroupPopup}>
              Create New Group
            </Button>
          </div>
          {showNewGroupPopup && <CreateNewGroupPopup closePopup={closePopup} students={teacher.students} />}
          <GroupStack groups={teacher.groups} handleGroupClick={handleGroupClick} />
          {selectedGroup && <EditGroupPopup students={teacher.students} selectedGroup={selectedGroup} closePopup={closePopup} />}
        </div>
        <div className="section-group">
          <div className="section-header">
            <label>Students</label>
            <Button theme={'light'} onClick={handleShowNewStudentPopup}>
              Add New Student
            </Button>
          </div>

          {showNewStudentPopup && <CreateNewStudentPopup groups={teacher.groups} closePopup={closePopup} />}

          <StudentTable students={teacher.students} handleStudentClick={handleStudentClick} />

          {selectedStudent && <EditStudentPopup student={selectedStudent} groups={teacher.groups} closePopup={closePopup} />}
        </div>
      </div>
    </>
  );
}

export default PageTeacher;
