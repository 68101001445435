import React, { useEffect, useState } from 'react';
import styles from './DisplayFlash.module.scss';

interface DisplayFlashProps {
  currentQuestion: { question: string };
  setStopSequence: (stopSequence: boolean) => void;
  appearInterval: number;
}

function parseInput(input) {
  // Split the string by spaces to separate numbers and operators
  const parts = input.split(' ');
  const result = [];
  let pendingOperator = ''; // Initialize an empty pending operator

  parts.forEach((part) => {
    if (part === '-' || part === '+') {
      // If the part is an operator, set it as pending to add to the next number
      // "+" operator is treated by not appending it, "-" is directly appended
      pendingOperator = part;
    } else {
      // If the part is a number and there's a pending "-" operator, prepend it to the number
      const numberWithOperator = pendingOperator === '-' ? `${pendingOperator}${part}` : part;
      result.push(numberWithOperator);
      pendingOperator = ''; // Reset the pending operator after using it
    }
  });

  return result;
}

export default function DisplayFlash({ currentQuestion, setStopSequence, appearInterval }: DisplayFlashProps) {
  const [parsedInput, setParsedInput] = useState<string[]>([]);
  const [displayIndex, setDisplayIndex] = useState<number>(0);
  const [showElement, setShowElement] = useState<boolean>(true);

  useEffect(() => {
    // Update the parsed input whenever the question changes
    const parsed = parseInput(currentQuestion.question);
    setParsedInput(parsed);
    setDisplayIndex(0); // Reset to the first element
    setShowElement(true); // Start by showing the first element
  }, [currentQuestion]);

  useEffect(() => {
    if (displayIndex < parsedInput.length) {
      const timer = setTimeout(() => {
        setShowElement(!showElement); // Toggle visibility
        if (showElement) {
          // Move to next element after it's been displayed
          setDisplayIndex(displayIndex + 1);
        }
      }, showElement && appearInterval);

      return () => clearTimeout(timer);
    }
  }, [showElement, displayIndex, parsedInput]);

  const notEmpty = displayIndex > 0 && parsedInput.length > 0;

  const isDisplayIndexAtEnd = displayIndex === parsedInput.length;

  useEffect(() => {
    if (notEmpty) {
      if (isDisplayIndexAtEnd) {
        console.log('>>restart sequence\n\n');
        console.log('enable button to submit');
        setStopSequence(false);
      } else {
        console.log('>>stop sequence\n\n');
        console.log('disable button to submit');
        setStopSequence(true);
      }
    }
  }, [displayIndex, parsedInput, setStopSequence, isDisplayIndexAtEnd, notEmpty]);

  // Function to generate a style for the circle based on if it's the current index
  const getCircleStyle = (index: number) => {
    return index === displayIndex && showElement ? styles.activeCircle : styles.inactiveCircle;
  };
  return (
    <div className={styles.questionArea}>
      <div className={styles.questionArea__question}>
        {isDisplayIndexAtEnd && notEmpty ? (
          <span style={{ color: 'var(--gamemode-flash-orange)', fontSize: 120 }}>Answer</span>
        ) : (
          <span className={displayIndex % 2 !== 0 ? styles.colorOrange : styles.colorMain}>
            {showElement && parsedInput[displayIndex]}
          </span>
        )}
      </div>


      {displayIndex < parsedInput.length && (
        <div className={styles.circlesContainer}>
          {parsedInput.map((_, index) => (
            <div key={index} className={getCircleStyle(index)} />
          ))}
        </div>
      )}
    </div>
  );
}
