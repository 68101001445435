import AbacusPlayground from 'components/Teacher/AbacusPlayground';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const PageTeacherAbacus: React.FC = () => {
  const location = useLocation();

  useEffect(() => {
    document.title = 'Test Abacus Playground';
  }, []);

  useEffect(() => {
    localStorage.setItem('lastVisitedTeacherRoute', location.pathname);
  }, [location]);

  return <AbacusPlayground />;
};

export default PageTeacherAbacus;
