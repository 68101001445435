import { GameStatus, IAssignmentGet, IClientStudentAssignment, IClientStudentCompletedAssignment, IClientStudentSelf, IStudentGet } from 'cubiq-abacus-types';
import moment from 'moment';
import { convertMillisecondsToSecondsDecimal, formatMilliseconds } from 'store/utils/time';

function calculateDayDifference(date1, date2) {
  // console.log('date1', date1);
  // console.log('date2', date2);

  // Extract the year, month, and day from each date
  const year1 = date1.getFullYear();
  const month1 = date1.getMonth();
  const day1 = date1.getDate();

  const year2 = date2.getFullYear();
  const month2 = date2.getMonth();
  const day2 = date2.getDate();

  // Create new dates with the extracted components, setting time to midnight
  const dateOnly1 = new Date(year1, month1, day1);
  const dateOnly2 = new Date(year2, month2, day2);

  // Calculate the difference in milliseconds
  //@ts-ignore
  const diffTime = dateOnly1 - dateOnly2;
  // Convert the difference from milliseconds to days
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

  return diffDays;
}

export const getPendingAssignmentsAndFormat = (assignments: IAssignmentGet['response'][]): IClientStudentAssignment[] => {
  const today = new Date();

  if (!assignments || assignments.length === 0) {
    return [];
  }

  return assignments
    .filter((assignment) => {
      const daysDiff = calculateDayDifference(new Date(assignment.startOn), today);

      // console.log(`this assignment is ${assignment.status} set to end on ${assignment.endOn}\nIt is ${daysDiff} days away from today\n\n\n\n`);

      // Include assignments that have started (today or in the past)
      return daysDiff <= 0 && assignment.status === GameStatus.Pending;
    })
    .map((assignment) => {
      const daysDiff = calculateDayDifference(new Date(assignment.startOn), today);

      // console.log('endDate', endDate);
      // console.log('today', today);
      // console.log('daysDiff', daysDiff);
      let due: string;
      if (daysDiff < -1) {
        due = `Due ${Math.abs(daysDiff)} days ago`;
      } else if (daysDiff === -1) {
        due = 'Due Yesterday';
      } else if (daysDiff === 0) {
        due = 'Due Today';
      } else if (daysDiff === 1) {
        due = 'Due Tomorrow';
      } else {
        due = `Due in ${daysDiff} days`;
      }

      const formattedAssignment: IClientStudentAssignment = {
        ...assignment,
        homeworkObjectId: {
          ...assignment.homeworkObjectId,
          gameSettings: {
            ...assignment.homeworkObjectId.gameSettings,
            flashInSeconds: convertMillisecondsToSecondsDecimal(assignment.homeworkObjectId.gameSettings.flashIn),
          },
        },
        due,
        daysDiff,
      };
      return formattedAssignment;
    })
    .filter(Boolean);
};

export const getCompletedAssignmentsAndFormat = (assignments: IAssignmentGet['response'][]): IClientStudentCompletedAssignment[] => {
  if (!assignments || assignments.length === 0) {
    return [];
  }

  return assignments
    .filter((assignment) => assignment.status === GameStatus.Completed)
    .map((assignment) => {
      const correctAnswerCount = assignment.bank.filter((q) => q.correctAnswer === q.userAnswer).length;
      const totalQuestions = assignment.bank.length;
      const formattedAssignment: IClientStudentCompletedAssignment = {
        ...assignment,
        completedOn: moment(assignment.completedOn).format('MMM D, YYYY'),
        bank: assignment.bank.map((q) => ({ question: q.question + ' = ' + q.userAnswer, isCorrect: q.correctAnswer === q.userAnswer })),
        completedTime: formatMilliseconds(assignment.completedTime),
        correctAnswers: `${correctAnswerCount}/${totalQuestions}`,
        stars: correctAnswerCount,
      };
      return formattedAssignment;
    })
    .filter(Boolean);
};

export const removeAssignmentFromPending = (assignments: IClientStudentAssignment[], assignmentToRemove: IAssignmentGet['response']) => {
  return assignments.filter((assignment) => assignment._id !== assignmentToRemove._id);
};

export const addAssignmentToCompleted = (assignments: IClientStudentCompletedAssignment[], assignmentToAdd: IAssignmentGet['response']) => {
  const correctAnswerCount = assignmentToAdd.bank.filter((q) => q.correctAnswer === q.userAnswer).length;
  const totalQuestions = assignmentToAdd.bank.length;

  const formattedAssignment: IClientStudentCompletedAssignment = {
    ...assignmentToAdd,
    completedOn: moment(assignmentToAdd.completedOn).format('MMM D, YYYY'),
    bank: assignmentToAdd.bank.map((q) => ({ question: q.question + ' = ' + q.userAnswer, isCorrect: q.correctAnswer === q.userAnswer })),
    completedTime: formatMilliseconds(assignmentToAdd.completedTime),
    correctAnswers: `${correctAnswerCount}/${totalQuestions}`,
    stars: correctAnswerCount,
  };

  return [formattedAssignment, ...assignments];
};

export const updateStars = (self: IClientStudentSelf, assignment: IAssignmentGet['response']) => {
  const correctAnswerCount = assignment.bank.filter((q) => q.correctAnswer === q.userAnswer).length;
  return {
    ...self,
    stars: self.stars + correctAnswerCount,
  };
};

export const formatSelf = (self: IClientStudentSelf, response: IStudentGet['response']): IClientStudentSelf => {
  return {
    ...self,
    name: response.name,
    email: response.email,
    avatar: response.avatar,
    groupObjectId: response.groupObjectId,
    stars: response.stars,
    joinedOn: moment(response.joinedOn).format('MMM D, YYYY'),
  };
};
