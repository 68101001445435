import React, { useEffect, useRef, useState } from 'react';
import styles from '../AbacusPlayground/Cards/Cards.module.scss';
import { GameType } from 'cubiq-abacus-types';
import { IconGameSettingsPlay } from 'assets';
interface CheckboxOption {
  value: number;
  label: string;
}

interface CheckboxGroupProps {
  gameType: GameType;
  options: CheckboxOption[];
  label: string;
  formulas: number[];
  setFormulas: (formulas: number[]) => void;
}

const CheckboxGroupNoFormik = ({ gameType, options, label, formulas, setFormulas }: CheckboxGroupProps) => {
  const [isVisible, setIsVisible] = useState(false);
  const wrapperRef = useRef<HTMLDivElement>(null);

  const handleChange = (value: number) => {
    const currentIndex = formulas.indexOf(value);
    const newFormulas = [...formulas];

    if (currentIndex === -1) {
      newFormulas.push(value); // Add value if option is unchecked
    } else {
      newFormulas.splice(currentIndex, 1); // Remove value if option is checked again
    }
    setFormulas(newFormulas);
  };

  const toggleDropdown = () => setIsVisible(!isVisible);

  const handleClickOutside = (event: MouseEvent) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target as Node)) {
      setIsVisible(false);
    }
  };

  const handleClickInside = (event: React.MouseEvent) => {
    event.stopPropagation();
  };

  const getUncheckedLabels = () => {
    return options
      .filter((option) => !formulas.includes(option.value))
      .map((option) => (
        <div key={option.value} className="unchecked-label" style={{ border: gameType === GameType.Column ? 'var(--gamemode-column-green) 1px solid' : 'var(--gamemode-flash-orange) 1px solid' }}>
          {option.label}
        </div>
      ));
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className={`field-flex`}>
      <div className={styles.gameCard__inputGroup__labelGroup}>
        <IconGameSettingsPlay width="30" fill={gameType === GameType.Column ? 'var(--gamemode-column-text)' : 'var(--gamemode-flash-text)'} />

        <p className={styles.gameCard__inputGroup__label} id={gameType === GameType.Column ? 'column' : 'flash'}>
          {label}
        </p>
      </div>
      <div onClick={toggleDropdown} className={`input-checkbox ${gameType === GameType.Column ? styles.gameCard__selectColumn : styles.gameCard__selectFlash}`}>
        {getUncheckedLabels().length > 0 ? getUncheckedLabels() : 'All selected'}
        {isVisible && (
          <div ref={wrapperRef} className="input-checkbox-dropdown" onClick={handleClickInside}>
            {options.map((option) => (
              <label key={option.value} className="input-checkbox-dropdown-item">
                <input type="checkbox" value={option.value} checked={!formulas.includes(option.value)} onChange={() => handleChange(option.value)} />
                {option.label}
              </label>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default CheckboxGroupNoFormik;
