import React from 'react';
import styles from './GameControlPanel.module.scss';
import { IconGameExit } from 'assets';
import { ITimerDisplay } from 'components/utils/displayTimer';

interface GameControlPanelProps {
  rounds: Array<boolean | null>;
  currentRound: number;
  gameStatus: string;
  nextQuestionPending: boolean;
  displayTimer: ITimerDisplay;
  exitGame: () => void;
}

export default function GameControlPanel({ rounds, currentRound, displayTimer, exitGame, gameStatus, nextQuestionPending }: GameControlPanelProps) {
  const timer = displayTimer;
  return (
    <div className={styles.controlPanel}>
      <IconGameExit width="45" onClick={gameStatus === 'finished' ? exitGame : () => alert('cant exit while game is running')} style={{ cursor: 'pointer' }} className={styles.exitButton} />
      <div className={styles.roundsIndicator}>
        {rounds.map((round, index) => (
          <div
            key={index}
            className={`${styles.roundsIndicator__item} ${gameStatus === 'inProgress' && index === currentRound - 1 && !nextQuestionPending ? styles.roundsIndicator__item__current : ''} ${
              round !== null ? (round ? styles.roundsIndicator__item__correct : styles.roundsIndicator__item__incorrect) : ''
            }`}
          >
            {index + 1}
          </div>
        ))}
      </div>
      <div className={styles.timer}>
        <p>{timer.minutes}</p>:<p>{timer.seconds}</p>:<p>{timer.milliseconds}</p>
      </div>
    </div>
  );
}
