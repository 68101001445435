import React, { useEffect, useState } from 'react';
import { IconTableFilter, IconTableSearch } from 'assets'; // adjust the import path as necessary
import './styles.scss';
import { useDebounce } from 'use-debounce';
import { IClientTeacherStudents } from 'cubiq-abacus-types';

interface SearchAndFilterProps {
  students: IClientTeacherStudents[];
  filteredStudents: IClientTeacherStudents[];
  setFilteredStudents: (filteredStudents: IClientTeacherStudents[]) => void;
  showFilter?: boolean;
  showSearch?: boolean;
}

const SearchAndFilter: React.FC<SearchAndFilterProps> = ({ students, filteredStudents, setFilteredStudents, showFilter, showSearch }) => {
  const [groupCounts, setGroupCounts] = useState<Record<string, number>>({});
  const [filter, setFilter] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [debouncedSearchTerm] = useDebounce(searchTerm, 500);
  const [isSearching, setIsSearching] = useState(false);

  useEffect(() => {
    const counts = students.reduce((acc, student) => {
      const groupName = student.groupObjectId.groupName;
      acc[groupName] = (acc[groupName] || 0) + 1;
      return acc;
    }, {});

    setGroupCounts(counts);
  }, [students]);

  // Set loading state when searchTerm changes
  useEffect(() => {
    if (searchTerm) {
      setIsSearching(true);
    } else {
      setIsSearching(false);
    }
  }, [searchTerm]);

  // Reset loading state when debouncedSearchTerm updates
  useEffect(() => {
    setIsSearching(false);
    // Implement the action to be taken after debounce here, like fetching or filtering data
  }, [debouncedSearchTerm]);

  useEffect(() => {
    const filteredStudents = students.filter((student) => {
      const matchesFilter = filter ? student.groupObjectId.groupName === filter : true;
      const matchesSearch = debouncedSearchTerm
        ? student.name.toLowerCase().includes(debouncedSearchTerm.toLowerCase()) ||
          student.email.toLowerCase().includes(debouncedSearchTerm.toLowerCase()) ||
          student.groupObjectId.groupName.toLowerCase().includes(debouncedSearchTerm.toLowerCase())
        : true;

      return matchesFilter && matchesSearch;
    });

    setFilteredStudents(filteredStudents);
  }, [filter, debouncedSearchTerm, students, setFilteredStudents]);

  useEffect(() => {
    // Check if the current filter is a key in the updated groupCounts
    if (filter && !Object.keys(groupCounts).includes(filter)) {
      // If not, reset the filter to default ('')
      setFilter('');
    }
  }, [groupCounts, filter]); // Dependency array includes groupCounts and filter

  return (
    <div className="filter-result-row">
      <div className="filteringby-row-spacebetween">
        <div className="filteringby-row">
          {showFilter && (
            <>
              <IconTableFilter width="30" height="30" style={{ flexShrink: 0 }} />
              <p>Filtering by:</p>
              <select value={filter} onChange={(e) => setFilter(e.target.value)} className="group-filter-dropdown">
                <option value="">All Groups ({students.length})</option>
                {Object.entries(groupCounts).map(([groupName, count]) => (
                  <option key={groupName} value={groupName}>
                    {`${groupName} (${count})`}
                  </option>
                ))}
              </select>
              <div className="separator"></div>
            </>
          )}
          {showSearch && (
            <>
              <IconTableSearch width="30" height="30" style={{ flexShrink: 0 }} />
              <div className="input-group">
                <input type="text" placeholder="Search by name..." value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
                {isSearching && <div className="loader"></div>}
              </div>
            </>
          )}
        </div>
        <p className="filter-info">
          Results: {filteredStudents.length}/{students.length}
        </p>
      </div>
    </div>
  );
};

export default SearchAndFilter;
