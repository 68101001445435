import Skeleton from 'react-loading-skeleton';

function HomeworkTableSingleRowSkeleton() {
  return (
    <tr className="table-row">
      <td>
        <Skeleton className="skeleton " width={'100%'} style={{ borderRadius: '20px' }} />
      </td>
      <td>
        <Skeleton className="skeleton " width={'100%'} style={{ borderRadius: '20px' }} />
      </td>
      <td>
        <Skeleton className="skeleton " width={'100%'} style={{ borderRadius: '20px' }} />
      </td>
      <td>
        <Skeleton className="skeleton " width={'100%'} style={{ borderRadius: '20px' }} />
      </td>
      <td>
        <Skeleton className="skeleton " width={'100%'} style={{ borderRadius: '20px' }} />
      </td>
      <td>
        <Skeleton className="skeleton " width={'100%'} style={{ borderRadius: '20px' }} />
      </td>
    </tr>
  );
}

export default HomeworkTableSingleRowSkeleton;
