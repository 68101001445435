import React, { useEffect } from 'react';
import { format, isBefore, startOfDay } from 'date-fns';

import { Formik, Form, Field, useFormikContext } from 'formik';
import * as Yup from 'yup';
import { GameCategory, GameType, IClientTeacherGroups, IHomeworkPost } from 'cubiq-abacus-types';
import { CheckboxGroup, ErrorSummary, SelectField } from '../Form';
import { useAppDispatch } from 'store/configureStore';
import Button from 'components/Global/Button';
import DateField from '../Form/DateField';
import IncDecNumberField from '../Form/IncDecNumberField';
import { IconGamemodeColumn, IconGamemodeFlash } from 'assets';
import { postHomework } from 'store/teacherSlice';
import styles from './styles.module.scss';
import { getFormulaOptions } from 'components/utils/getFormulas';
import moment from 'moment-timezone';
interface CreateHomeworkPopupProps {
  groups: IClientTeacherGroups[];
  closePopup: () => void;
}
const DEFAULT_DIGIT_OPTIONS = [
  { value: 1, label: '1 Digit (ex. 3)' },
  { value: 2, label: '2 Digits (ex. 43)' },
  { value: 3, label: '3 Digits (ex. 645)' },
  { value: 4, label: '4 Digits (ex. 1300)' },
  { value: 5, label: '5 Digits (ex. 12550)' },
];

const LIMITED_DIGIT_OPTIONS = [
  { value: 2, label: '2 Digits (ex. 24)' },
  { value: 3, label: '3 Digits (ex. 866)' },
  { value: 4, label: '4 Digits (ex. 1250)' },
  { value: 5, label: '5 Digits (ex. 15590)' },
];

const DEFAULT = { value: 0, label: 'Please Choose' };

const CreateHomeworkPopup: React.FC<CreateHomeworkPopupProps> = ({ groups, closePopup }) => {
  const groupIds = groups.map((group) => group.groupId);
  const acceptableGroupIds = [...groupIds];

  type HomeworkKeys = keyof IHomeworkPost['payload'];
  type ValidationRules = Record<HomeworkKeys, Yup.AnySchema>;

  const validationRules: ValidationRules = {
    assignTo: Yup.string().oneOf(acceptableGroupIds, 'Invalid group').required('Group is required'),
    dateStart: Yup.date()
      .required('Start date is required')
      .test('is-not-past-date', 'Start date cannot be in the past', (value) => {
        const today = startOfDay(new Date());
        return !isBefore(new Date(value), today);
      }),

    dateEnd: Yup.date()
      .required('End date is required')
      .when('dateStart', (dateStart, schema) => schema.min(dateStart, 'End date cannot be before the start date'))
      .test('is-not-past-date', 'End date cannot be in the past', (value) => {
        const today = startOfDay(new Date());
        return !isBefore(new Date(value), today);
      }),
    gameType: Yup.string().oneOf(Object.values(GameType), 'Invalid game type').required('Game type is required'),
    gameCategory: Yup.string().oneOf(Object.values(GameCategory), 'Invalid category').required('Game category is required'),
    gameDigits: Yup.number().min(1, 'Make sure to select the digit').required('Digits is required'),
    gameChain: Yup.number().min(2, 'Chain should be greater than 2').max(20, 'Chain should be less than 20').required('Chain is required'),
    gameFormulas: Yup.array().of(Yup.number()).required('At least one formula must be selected'),
    gameRounds: Yup.number().min(5, 'Rounds should be greater than 5').max(100, 'Rounds should be less than 100').required('Rounds is required'),
    flashIn: Yup.number().when('gameType', (gameType, schema) => {
      //@ts-ignore
      return gameType === GameType.Flash ? schema.required('Appear interval is required').positive('Appear interval must be positive') : schema;
    }),
  };

  const validationSchema = Yup.object().shape(validationRules);

  const dispatch = useAppDispatch();

  const today = new Date(); // Get today's date
  const oneWeekLater = new Date(); // Create a new Date object for manipulation
  oneWeekLater.setDate(today.getDate() + 7); // Set the date to one week later

  const initialValues: IHomeworkPost['payload'] = {
    assignTo: acceptableGroupIds[0],
    dateStart: format(today, 'yyyy-MM-dd'),
    dateEnd: format(oneWeekLater, 'yyyy-MM-dd'),
    gameType: GameType.Column,
    gameCategory: GameCategory['5S_ADD'],
    gameDigits: 2,
    gameChain: 2,
    gameFormulas: [],
    gameRounds: 5,
    flashIn: 300,
  };

  const handleCreateHomework = (values: IHomeworkPost['payload']) => {
    let payload: IHomeworkPost['payload'] = {
      assignTo: values.assignTo,
      dateStart: values.dateStart,
      dateEnd: values.dateEnd,
      gameType: values.gameType,
      gameCategory: values.gameCategory,
      gameDigits: values.gameDigits,
      gameChain: values.gameChain - 2,
      gameFormulas: values.gameFormulas,
      gameRounds: values.gameRounds,
    };

    if (values.gameType === GameType.Flash) {
      payload = {
        ...payload,
        flashIn: values.flashIn,
      };
    }
    const todayInUTC = moment.utc().format('YYYY-MM-DD');
    const todayInLATime = moment.tz(new Date(), 'America/Los_Angeles').format('YYYY-MM-DD');

    console.log('CZZ>> values.dateStart', values.dateStart);
    console.log('CZZ>> todayInUTC', todayInUTC);
    if (values.dateStart === todayInLATime) {
      // If the start date is today, use the current date and time in UTC
      payload.dateStart = todayInUTC;
    }

    // console.log('CZZ>> values.dateStart', values.dateStart);
    // console.log('CZZ>> todayInLATime', todayInLATime);
    // if (values.dateStart === todayInLATime) {
    //   // If the start date is today, use the current date and time
    //   payload.dateStart = moment.tz(new Date(), 'America/Los_Angeles').format();
    // }

    dispatch(postHomework(payload));

    console.log('Creating homework with payload:', payload);
    closePopup();
  };

  // A component to handle effects based on gameCategory changes
  const FormEffect = () => {
    const { values, setFieldValue } = useFormikContext();

    const v = values as IHomeworkPost['payload'];

    useEffect(() => {
      setFieldValue('gameFormulas', []);
    }, [v.gameCategory, setFieldValue]);

    return null; // This component does not render anything
  };

  return (
    <>
      <div className={styles.overlay} onClick={closePopup}></div>

      <div className={styles.popup}>
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleCreateHomework}>
          {({ errors, touched, isValid, resetForm, values, setFieldValue }) => (
            <Form>
              <FormEffect />
              <div className="section-header">
                <label>New Daily Homework</label>
              </div>
              <div className={styles['fields-stack']}>
                <SelectField name="assignTo" label="Assign to" options={groups.map((group) => ({ value: group.groupId, label: group.groupName }))} error={errors.assignTo && touched.assignTo} required />
                <DateField name="dateStart" label="Start Date" error={!!errors.dateStart && !!touched.dateStart} required min={format(today, 'yyyy-MM-dd')} />
                <DateField name="dateEnd" label="End Date" error={!!errors.dateStart && !!touched.dateStart} required min={format(today, 'yyyy-MM-dd')} />
                <SelectField
                  name="gameType"
                  label="Game mode"
                  options={[
                    { value: GameType.Column, label: GameType.Column },
                    { value: GameType.Flash, label: GameType.Flash },
                  ]}
                  error={errors.gameType && touched.gameType}
                  required
                />
              </div>
              <div className={styles.separator}></div>

              {values.gameType === GameType.Column && (
                <>
                  <div className={styles['gameType-header']}>
                    <IconGamemodeColumn width="50" />
                    <p>Column Mode</p>
                  </div>
                  <div className={styles['fields-stack']}>
                    <SelectField name="gameCategory" label="Game category" options={Object.entries(GameCategory).map(([key, value]) => ({ value: value, label: value }))} error={errors.hasOwnProperty('gameCategory')} required />
                    <SelectField
                      name="gameDigits"
                      label="Digits"
                      options={
                        values.gameCategory === GameCategory['5S_ADD'] || values.gameCategory === GameCategory['5S_SUB'] || values.gameCategory === GameCategory['DIRECT'] || values.gameCategory === GameCategory['DIRECT_STRICT']
                          ? DEFAULT_DIGIT_OPTIONS
                          : values.gameCategory === GameCategory['10S_ADD'] || values.gameCategory === GameCategory['10S_SUB'] || values.gameCategory === GameCategory['MIX_ADD'] || values.gameCategory === GameCategory['MIX_SUB']
                            ? LIMITED_DIGIT_OPTIONS
                            : null
                      }
                      defaultOption={DEFAULT}
                      error={errors.hasOwnProperty('gameDigits')}
                      required
                    />
                    <IncDecNumberField name="gameChain" label="Chain" error={errors.hasOwnProperty('gameChain')} required />
                    {![GameCategory.DIRECT, GameCategory.DIRECT_STRICT].includes(values.gameCategory) && (
                      <CheckboxGroup name="gameFormulas" options={getFormulaOptions(values.gameCategory)} label="Formulas" error={errors.gameFormulas && touched.gameFormulas} required />
                    )}
                    <IncDecNumberField name="gameRounds" label="Rounds" error={errors.hasOwnProperty('gameRounds')} required />
                  </div>
                </>
              )}

              {values.gameType === GameType.Flash && (
                <>
                  <div className={styles['gameType-header']}>
                    <IconGamemodeFlash width="50" />
                    <p>Flash Mode</p>
                  </div>
                  <div className={styles['fields-stack']}>
                    <SelectField name="gameCategory" label="Game category" options={Object.entries(GameCategory).map(([key, value]) => ({ value: value, label: value }))} error={errors.hasOwnProperty('gameCategory')} required />
                    <SelectField
                      name="gameDigits"
                      label="Digits"
                      options={
                        values.gameCategory === GameCategory['5S_ADD'] || values.gameCategory === GameCategory['5S_SUB'] || values.gameCategory === GameCategory['DIRECT'] || values.gameCategory === GameCategory['DIRECT_STRICT']
                          ? DEFAULT_DIGIT_OPTIONS
                          : values.gameCategory === GameCategory['10S_ADD'] || values.gameCategory === GameCategory['10S_SUB'] || values.gameCategory === GameCategory['MIX_ADD'] || values.gameCategory === GameCategory['MIX_SUB']
                            ? LIMITED_DIGIT_OPTIONS
                            : null
                      }
                      defaultOption={DEFAULT}
                      error={errors.hasOwnProperty('gameDigits')}
                      required
                    />
                    <IncDecNumberField name="gameChain" label="Chain" error={errors.hasOwnProperty('gameChain')} required />
                    {![GameCategory.DIRECT, GameCategory.DIRECT_STRICT].includes(values.gameCategory) && (
                      <CheckboxGroup name="gameFormulas" options={getFormulaOptions(values.gameCategory)} label="Select Formulas" error={errors.gameFormulas && touched.gameFormulas} required />
                    )}
                    <IncDecNumberField name="gameRounds" label="Rounds" error={errors.hasOwnProperty('gameRounds')} required />
                    <IncDecNumberField name="flashIn" label="Appear Interval" error={errors.hasOwnProperty('flashIn')} required />
                  </div>
                </>
              )}

              <ErrorSummary errors={errors} touched={touched} />

              <div className={styles['button-footer']}>
                <Button
                  theme={'secondary'}
                  onClick={() => {
                    closePopup();
                    resetForm();
                  }}
                >
                  Cancel
                </Button>
                <Button theme={'primary'} type="submit" disabled={!isValid}>
                  Create New Homework
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default CreateHomeworkPopup;
