import React, { useEffect, useState } from 'react';
import { IconTableFilter, IconTableSearch } from 'assets'; // adjust the import path as necessary
import './styles.scss';
import { useDebounce } from 'use-debounce';
import { IClientTeacherHomeworks } from 'cubiq-abacus-types';

interface SearchAndFilterHomeworkProps {
  item: IClientTeacherHomeworks[];
  filteredItems: IClientTeacherHomeworks[];
  setFilteredItems: (filteredItems: IClientTeacherHomeworks[]) => void;
  showFilter?: boolean;
  showSearch?: boolean;
}

const SearchAndFilterHomework: React.FC<SearchAndFilterHomeworkProps> = ({ item, filteredItems, setFilteredItems, showFilter, showSearch }) => {
  const [resultCount, setResultCount] = useState<Record<string, number>>({});
  const [filter, setFilter] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [debouncedSearchTerm] = useDebounce(searchTerm, 500);
  const [isSearching, setIsSearching] = useState(false);

  useEffect(() => {
    const counts = item.reduce((acc, i: IClientTeacherHomeworks) => {
      const id = i.groupName;
      acc[id] = (acc[id] || 0) + 1;
      return acc;
    }, {});

    setResultCount(counts);
  }, [item]);

  // Set loading state when searchTerm changes
  useEffect(() => {
    if (searchTerm) {
      setIsSearching(true);
    } else {
      setIsSearching(false);
    }
  }, [searchTerm]);

  // Reset loading state when debouncedSearchTerm updates
  useEffect(() => {
    setIsSearching(false);
    // Implement the action to be taken after debounce here, like fetching or filtering data
  }, [debouncedSearchTerm]);

  useEffect(() => {
    const filteredItems = item.filter((i: IClientTeacherHomeworks) => {
      const matchesFilter = filter ? i.groupName === filter : true;
      const matchesSearch = debouncedSearchTerm
        ? i.groupName.toLowerCase().includes(debouncedSearchTerm.toLowerCase()) ||
          i.status.toLowerCase().includes(debouncedSearchTerm.toLowerCase()) ||
          i.startDate.toLowerCase().includes(debouncedSearchTerm.toLowerCase()) ||
          i.endDate.toLowerCase().includes(debouncedSearchTerm.toLowerCase()) ||
          i.id.toLowerCase().includes(debouncedSearchTerm.toLowerCase())
        : true;

      return matchesFilter && matchesSearch;
    });

    setFilteredItems(filteredItems);
  }, [filter, debouncedSearchTerm, item, setFilteredItems]);

  useEffect(() => {
    // Check if the current filter is a key in the updated resultCount
    if (filter && !Object.keys(resultCount).includes(filter)) {
      // If not, reset the filter to default ('')
      setFilter('');
    }
  }, [resultCount, filter]); // Dependency array includes resultCount and filter

  return (
    <div className="filter-result-row">
      <div className="filteringby-row-spacebetween">
        <div className="filteringby-row">
          {showFilter && (
            <>
              <IconTableFilter width="30" height="30" style={{ flexShrink: 0 }} />
              <p>Filtering by:</p>
              <select value={filter} onChange={(e) => setFilter(e.target.value)} className="group-filter-dropdown">
                <option value="">All Groups ({item.length})</option>
                {Object.entries(resultCount).map(([groupName, count]) => (
                  <option key={groupName} value={groupName}>
                    {`${groupName} (${count})`}
                  </option>
                ))}
              </select>
              <div className="separator"></div>
            </>
          )}
          {showSearch && (
            <>
              <IconTableSearch width="30" height="30" style={{ flexShrink: 0 }} />
              <div className="input-group">
                <input type="text" placeholder="Search by name..." value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
                {isSearching && <div className="loader"></div>}
              </div>
            </>
          )}
        </div>
        <p className="filter-info">
          Results: {filteredItems.length}/{item.length}
        </p>
      </div>
    </div>
  );
};

export default SearchAndFilterHomework;
