import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { AxiosError, AxiosResponse } from 'axios';
import { ErrorResponse, IAssignmentGet, IAssignmentPut, IClientStudentAssignment, IClientStudentCompletedAssignment, IClientStudentSelf, IStudentGet } from 'cubiq-abacus-types';
import { enqueueSnackbar } from 'store/snackbarSlice';
import CUBIQ from 'helpers/api';
import * as helper from './helpers';
import { handleError } from 'store/utils/helpers';

export interface IStudentState {
  self: IClientStudentSelf;
  selfLoading: boolean;
  assignments: IClientStudentAssignment[];
  assignmentsLoading: boolean;
  completedAssignments: IClientStudentCompletedAssignment[];
  completedAssignmentsLoading: boolean;
  errors: AxiosResponse<ErrorResponse, any>[];
}

const initialState: IStudentState = {
  self: {
    name: '',
    email: '',
    avatar: '',
    groupObjectId: {
      groupName: '',
      groupId: '',
    },
    stars: 0,
    joinedOn: '',
  },
  selfLoading: false,
  assignments: [],
  assignmentsLoading: false,
  completedAssignments: [],
  completedAssignmentsLoading: false,
  errors: [],
};

const slice = createSlice({
  name: 'student',
  initialState,
  reducers: {
    checkingToken: (_) => {},
    noTokenFound: (_) => {},
    tokenFound: (_) => {},
    selfRequested: (_) => {
      _.selfLoading = true;
    },
    selfReceived: (_, { payload: response }: PayloadAction<IStudentGet['response']>) => {
      _.self = helper.formatSelf(_.self, response);
      _.selfLoading = false;
    },
    selfFailed: (_, { payload: response }: PayloadAction<AxiosResponse<ErrorResponse, any>>) => {
      _.selfLoading = false;
      _.errors.push(response);
    },
    selfLoggedout: (_) => {
      _.self = initialState.self;
      _.selfLoading = false;
      _.assignments = initialState.assignments;
      _.assignmentsLoading = false;
      _.completedAssignments = initialState.completedAssignments;
      _.completedAssignmentsLoading = false;
    },
    assignmentsRequested: (_) => {
      _.assignmentsLoading = true;
      _.completedAssignmentsLoading = true;
    },
    assignmentsReceived: (_, { payload: response }: PayloadAction<IAssignmentGet['response'][]>) => {
      _.assignments = helper.getPendingAssignmentsAndFormat(response);
      _.completedAssignments = helper.getCompletedAssignmentsAndFormat(response);
      _.assignmentsLoading = false;
      _.completedAssignmentsLoading = false;
    },
    assignmentSubmitted: (_, { payload: response }: PayloadAction<IAssignmentGet['response']>) => {
      _.assignmentsLoading = false;
      _.completedAssignmentsLoading = false;
      _.assignments = helper.removeAssignmentFromPending(_.assignments, response);
      _.completedAssignments = helper.addAssignmentToCompleted(_.completedAssignments, response);
      _.self = helper.updateStars(_.self, response);
    },
    assignmentsFailed: (_, { payload: response }: PayloadAction<AxiosResponse<ErrorResponse, any>>) => {
      _.assignmentsLoading = false;
      _.completedAssignmentsLoading = false;
      _.errors.push(response);
    },
  },
});

const reducer = slice.actions;
export type StudentReducer = typeof slice.actions;

export default slice.reducer;

// Action Creators

export const login = (payload: { email: string; password: string }) => (dispatch) => {
  dispatch(reducer.selfRequested());
  CUBIQ('/api/student/auth', {
    method: 'POST',
    data: payload,
  })
    .then((res: AxiosResponse<{ _id: string; token: string }>) => {
      // dispatch(enqueueSnackbar('Logged in', { variant: 'success' }));
      // dispatch(reducer.selfReceived(res.data));
      localStorage.setItem('token', res.data.token);
      dispatch(self());
    })
    .catch((err: AxiosError<ErrorResponse>) => {
      handleError(dispatch, err, reducer.selfFailed);
    });
};

export const logout = () => (dispatch) => {
  localStorage.removeItem('token');
  dispatch(reducer.selfLoggedout());
};

export const self = () => (dispatch) => {
  dispatch(reducer.checkingToken());
  const token = localStorage.getItem('token');
  if (!token) {
    dispatch(reducer.noTokenFound());
    return;
  }

  dispatch(reducer.tokenFound());
  dispatch(reducer.selfRequested());
  CUBIQ('/api/student/auth', {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res: AxiosResponse<IStudentGet['response']>) => {
      // dispatch(enqueueSnackbar('Got self', { variant: 'success' }));
      dispatch(getAssignments());
      dispatch(reducer.selfReceived(res.data));
    })
    .catch((err: AxiosError<ErrorResponse>) => {
      handleError(dispatch, err, reducer.selfFailed);
    });
};

export const getAssignments = () => (dispatch) => {
  dispatch(reducer.checkingToken());
  const token = localStorage.getItem('token');
  if (!token) {
    dispatch(reducer.noTokenFound());
    return;
  }

  dispatch(reducer.tokenFound());
  dispatch(reducer.assignmentsRequested());
  CUBIQ('/api/student/assignments', {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res: AxiosResponse<IAssignmentGet['response'][]>) => {
      // dispatch(enqueueSnackbar('Got assignments', { variant: 'success' }));
      dispatch(reducer.assignmentsReceived(res.data));
    })
    .catch((err: AxiosError<ErrorResponse>) => {
      handleError(dispatch, err, reducer.selfFailed);
    });
};

export const submitAssignment = (payload: IAssignmentPut['payload']) => (dispatch) => {
  dispatch(reducer.checkingToken());
  const token = localStorage.getItem('token');
  if (!token) {
    dispatch(reducer.noTokenFound());
    return;
  }

  dispatch(reducer.tokenFound());
  dispatch(reducer.assignmentsRequested());
  CUBIQ('/api/student/assignments', {
    method: 'PUT',
    data: payload,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res: AxiosResponse<IAssignmentGet['response']>) => {
      dispatch(enqueueSnackbar('Submitted assignment', { variant: 'success' }));
      dispatch(reducer.assignmentSubmitted(res.data));
    })
    .catch((err: AxiosError<ErrorResponse>) => {
      handleError(dispatch, err, reducer.selfFailed);
    });
};
