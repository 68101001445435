import React from 'react';
import styles from './Pill.module.scss';

interface PillProps extends React.ButtonHTMLAttributes<HTMLDivElement> {
  theme: 'primary' | 'warn' | 'danger';
  children: React.ReactNode;
}

const Pill: React.FC<PillProps> = ({ theme, children, ...restProps }) => {
  return (
    <div className={`${styles.pill} ${styles[theme || '']}`} {...restProps}>
      {children}
    </div>
  );
};

export default Pill;
