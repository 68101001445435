import React, { useState } from 'react';
import { Field } from 'formik';
import { IconEyeFill, IconEyeSlashFill } from 'assets';

interface PasswordFieldProps {
  name: string;
  label: string;
  placeholder: string;
  error?: boolean;
  required?: boolean;
}

const PasswordField: React.FC<PasswordFieldProps> = ({ name, label, placeholder, error, required = false }) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <div className={`field-flex ${error && 'error'}`}>
      <label>
        {label}
        {required && <span className="asterisk">*</span>}: <span className="field-note">{`(${showPassword ? 'visible' : 'hidden'})`}</span>
      </label>
      <div className="field-with-icon">
        <Field name={name} type={showPassword ? 'text' : 'password'} placeholder={placeholder} />
        <div onClick={() => setShowPassword(!showPassword)}>{showPassword ? <IconEyeSlashFill width="30" height="30" fill="var(--primary)" /> : <IconEyeFill width="30" height="30" fill="var(--primary)" />}</div>
      </div>
    </div>
  );
};

export default PasswordField;
