import styles from './style.module.scss';
import { useAppSelector } from 'store/combinedReducer';
import { IStudentState } from 'store/studentSlice';
import SectionPendingAssignments from './Sections/SectionPendingAssignments';
import SectionCompletedAssignments from './Sections/SectionCompletedAssignments';

export default function StudentHomeworkTab() {
  const student: IStudentState = useAppSelector((state) => state.student);

  return (
    <div className={styles.content}>
      <div className={styles.sectionWrapper}>
        <SectionPendingAssignments />
        {student.completedAssignments.length > 0 && <SectionCompletedAssignments />}
      </div>
    </div>
  );
}
