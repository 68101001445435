import { IconStudentAdd, IconStudentRemove } from 'assets';
import Button from 'components/Global/Button';
import { getStudentAvatar } from 'components/utils/getStudentAvatar';
import { IStudentGet } from 'cubiq-abacus-types';

interface StudentSelectionProps {
  student: IStudentGet['response'];
  currentStudentSelection: string[];
  setCurrentStudentSelection: React.Dispatch<React.SetStateAction<string[]>>;
  onStudentAdded?: (email: string) => void; // New prop
  onStudentRemoved?: (email: string) => void; // New prop
}

const StudentSelection: React.FC<StudentSelectionProps> = ({ student, currentStudentSelection, setCurrentStudentSelection, onStudentRemoved, onStudentAdded }) => {
  const hasBeenAdded = currentStudentSelection.includes(student.email);

  const handleToggleStudent = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    setCurrentStudentSelection((prevStudents) => {
      if (prevStudents.includes(student.email)) {
        onStudentRemoved?.(student.email); // Call the callback when a student is removed
        return prevStudents.filter((studentEmail) => studentEmail !== student.email);
      }
      onStudentAdded?.(student.email); // Call the callback when a student is added
      return [...prevStudents, student.email];
    });
  };

  return (
    <div className="field-flex">
      <div className={`row ${!hasBeenAdded && 'inactive'}`}>
        <img src={getStudentAvatar(student.avatar)} alt="" />
        <div>{student.name}</div>
      </div>

      <Button className={'list-action-button'} onClick={(e) => handleToggleStudent(e)}>
        <p style={{ color: hasBeenAdded ? 'var(--red_text)' : 'var(--primary)' }}>{hasBeenAdded ? 'Remove' : 'Add'}</p>
        {hasBeenAdded ? <IconStudentRemove width="23" fill="var(--red_text)" /> : <IconStudentAdd width="23" fill="var(--primary)" />}
      </Button>
    </div>
  );
};

export default StudentSelection;
