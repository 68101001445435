import Skeleton from 'react-loading-skeleton';

function HomeworkTableSkeleton() {
  return (
    <>
      <Skeleton height={40} className="skeleton" />

      <table className="homework-table">
        <thead>
          <tr>
            <th>Created</th>
            <th>Assigned to</th>
            <th>Start Date</th>
            <th>Expires</th>
            <th>Status</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody></tbody>
      </table>
      <div className="skeleton-list">
        {Array(5)
          .fill(0)
          .map((_, index) => (
            <Skeleton className="skeleton " key={index} height={67} style={{ borderRadius: '0px' }} />
          ))}
      </div>
    </>
  );
}

export default HomeworkTableSkeleton;
