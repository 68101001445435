import React from 'react';
import styles from './style.module.scss';
import { format } from 'date-fns';
import { useAppDispatch } from 'store/configureStore';
import { IStudentState, logout } from 'store/studentSlice';
import { useAppSelector } from 'store/combinedReducer';
import { IconCircleClass, IconCircleTime, IconEmail, IconLogo, IconStudentSidebarHomeworks, IconStudentSidebarLogout, IconStudentSidebarPractice, IconToggleSidebar } from 'assets';
import { getStudentAvatar } from 'components/utils/getStudentAvatar';

type ActiveContent = 'homework' | 'practice';

interface SidebarProps {
  onToggleSidebar: () => void;
  activeContent: ActiveContent;
  onContentChange: (content: ActiveContent) => void;
}

const Sidebar: React.FC<SidebarProps> = ({ onToggleSidebar, onContentChange, activeContent }) => {
  const dispatch = useAppDispatch();
  const student: IStudentState = useAppSelector((state) => state.student);

  const handleLogout = () => {
    if (window.confirm('Are you sure you want to logout?')) {
      dispatch(logout());
    }
  };

  return (
    <div className={styles.sidebar}>
      <div className={styles.header}>
        <div className={styles.header__profileHeader}>
          <div className={styles.header__profileHeader__contentMain}>
            <img src={getStudentAvatar(student.self.avatar)} alt="" />
            <p>{student.self.name.split(' ')[0]}</p>
          </div>

          <button onClick={onToggleSidebar} className={styles.header__profileHeader__toggleButton}>
            <IconToggleSidebar height="50" />
          </button>
        </div>
        <div className={styles.header__profileContentList}>
          <div className={styles.header__profileContentList__item}>
            <IconCircleClass width="20" />
            <p>{student.self.groupObjectId.groupName}</p>
          </div>
          <div className={styles.header__profileContentList__item}>
            <IconCircleTime width="20" />
            {student.self.joinedOn && <p>Joined {format(student.self.joinedOn, 'MMM dd, yyyy')}</p>}
          </div>
          <div className={styles.header__profileContentList__item}>
            <IconEmail width="20" />
            <p>{student.self.email}</p>
          </div>
        </div>

        <div className={styles.buttons}>
          <div className={`${styles.buttons__item} ${activeContent === 'homework' ? styles.buttons__item__active : ''}`} onClick={() => onContentChange('homework')}>
            <IconStudentSidebarHomeworks width="40" />
            <p>HOMEWORK</p>
            <p className={styles.buttons__item__number}>{student.assignments.length}</p>
          </div>
          <div className={`${styles.buttons__item} ${activeContent === 'practice' ? styles.buttons__item__active : ''}`} onClick={() => onContentChange('practice')}>
            <IconStudentSidebarPractice width="40" />
            <p>PRACTICE</p>
          </div>
          <div className={styles.buttons__item_logout} onClick={handleLogout}>
            <IconStudentSidebarLogout width="40" />
            <p>LOG OUT</p>
          </div>
        </div>
      </div>

      <div className={styles.footer}>
        <div className={styles.footer__starBlock}>
          <div className={styles.footer__starBlock__content}>
            <p className={styles.footer__starBlock__content__title}>Stars</p>
            <p className={styles.footer__starBlock__content__subtitle}>{student.self.stars}</p>
          </div>
          <div className={styles.footer__starBlock__starImg}>
            <img src="/stars/Artboard 4.png" alt="" />
          </div>
        </div>

        <IconLogo height="25" />
      </div>
    </div>
  );
};

export default Sidebar;
