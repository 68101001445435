import React, { useState } from 'react';
import Button from 'components/Global/Button';
import { IClientTeacherStudents, IGroupPost } from 'cubiq-abacus-types';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { postGroup } from 'store/teacherSlice';
import { useAppDispatch } from 'store/configureStore';
import { ErrorSummary, GenericField, StudentSelection } from 'components/Teacher/Form';
import styles from './styles.module.scss';

interface CreateNewGroupPopupProps {
  students: IClientTeacherStudents[];
  closePopup: () => void;
}

const validationSchema = Yup.object({
  groupName: Yup.string().required('Group name is required'),
});

const CreateNewGroupPopup: React.FC<CreateNewGroupPopupProps> = ({ closePopup, students }) => {
  const dispatch = useAppDispatch();
  const [currentStudentSelection, setCurrentStudentSelection] = useState<string[]>([]);

  const initialValues: IGroupPost['payload'] = {
    groupName: '',
    studentEmails: [],
  };

  const handleCreateNewGroup = (values: IGroupPost['payload']) => {
    const payload = {
      ...values,
      studentEmails: currentStudentSelection,
    };

    console.log('create new group', payload);
    dispatch(postGroup(payload));
    closePopup();
  };

  const filteredStudentsWhoAreUnassigned = students.filter((student) => {
    return student.groupObjectId.groupId === 'g-unassigned';
  });

  return (
    <>
      <div className={styles.overlay} onClick={closePopup}></div>

      <div className={styles.popup}>
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleCreateNewGroup} enableReinitialize>
          {({ errors, touched, isValid, resetForm }) => (
            <Form>
              <div className="section-header">
                <label>Add New Group</label>
              </div>
              <div className={styles['fields-stack']}>
                <GenericField name="groupName" label="Group Name" type="text" placeholder="Enter group name" error={errors.groupName && touched.groupName} required />
              </div>
              <div className={styles.separator}></div>
              <div className="section-header">
                <label>Students</label>
                <p>
                  Added: {currentStudentSelection.length}/{filteredStudentsWhoAreUnassigned.length}
                </p>
              </div>
              <div className={styles['fields-stack']}>
                {filteredStudentsWhoAreUnassigned.map((student) => (
                  <StudentSelection key={student.email} student={student} currentStudentSelection={currentStudentSelection} setCurrentStudentSelection={setCurrentStudentSelection} />
                ))}
              </div>

              <ErrorSummary errors={errors} touched={touched} />

              <div className={styles['button-footer']}>
                <Button
                  theme={'secondary'}
                  onClick={() => {
                    closePopup();
                    resetForm();
                  }}
                >
                  Cancel
                </Button>
                <Button theme={'primary'} type="submit" disabled={isValid ? false : true}>
                  Add New Student
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default CreateNewGroupPopup;
