import { ReactComponent as Close } from './icons/icon-close.svg';
import { ReactComponent as TableFilter } from './icons/icon-table-filter.svg';
import { ReactComponent as TableSearch } from './icons/icon-table-search.svg';
import { ReactComponent as EyeFill } from './icons/icon-eye-fill.svg';
import { ReactComponent as EyeSlashFill } from './icons/icon-eye-slash-fill.svg';
import { ReactComponent as ToggleSidebar } from './icons/icon-toggle.svg';
import { ReactComponent as CircleClass } from './icons/icon-circle-class.svg';
import { ReactComponent as CircleTime } from './icons/icon-circle-time.svg';
import { ReactComponent as Email } from './icons/icon-email.svg';

import { ReactComponent as StudentSidebarHomeworks } from './icons/icon-student-sidebar-homeworks.svg';
import { ReactComponent as StudentSidebarPractice } from './icons/icon-student-sidebar-practice.svg';
import { ReactComponent as StudentSidebarLogout } from './icons/icon-student-sidebar-logout.svg';

import { ReactComponent as Logo } from './icons/logo.svg';

import { ReactComponent as GameModeColumn } from './icons/icon-gamemode-column.svg';
import { ReactComponent as GameModeFlash } from './icons/icon-gamemode-flash.svg';

import { ReactComponent as Minus } from './icons/icon-minus.svg';
import { ReactComponent as Plus } from './icons/icon-plus.svg';

import { ReactComponent as GameSettingsCategory } from './icons/icon-game-category.svg';
import { ReactComponent as GameSettingsChain } from './icons/icon-game-chain.svg';
import { ReactComponent as GameSettingsDigits } from './icons/icon-game-digits.svg';
import { ReactComponent as GameSettingsInterval } from './icons/icon-game-interval.svg';
import { ReactComponent as GameSettingsRounds } from './icons/icon-game-rounds.svg';
import { ReactComponent as GameSettingsPlay } from './icons/icon-game-play.svg';

import { ReactComponent as GameExit } from './icons/icon-game-exit.svg';

import { ReactComponent as CheckAnswer } from './icons/icon-check-answer.svg';
import { ReactComponent as NextQuestion } from './icons/icon-next-question.svg';

import { ReactComponent as ResultsExit } from './icons/icon-results-exit.svg';
import { ReactComponent as ResultsAgain } from './icons/icon-results-again.svg';

import { ReactComponent as StudentAdd } from './icons/icon-student-add.svg';
import { ReactComponent as StudentRemove } from './icons/icon-student-remove.svg';

import { ReactComponent as Delete } from './icons/icon-delete.svg';
import { ReactComponent as CardEdit } from './icons/icon-card-edit.svg';

import { ReactComponent as SquiggleArrow } from './icons/squiggle-arrow.svg';

import { ReactComponent as PillTriangle } from './icons/icon-pill-triangle.svg';

import { ReactComponent as AnswerCorrect } from './icons/icon-correct.svg';
import { ReactComponent as AnswerIncorrect } from './icons/icon-incorrect.svg';

import { ReactComponent as Stopwatch } from './icons/icon-stopwatch.svg';
import { ReactComponent as CheckCorrect } from './icons/icon-check-correct.svg';

import { ReactComponent as ImageUpload } from './icons/icon-image-upload.svg';
import { ReactComponent as Info } from './icons/icon-info.svg';

export interface IconProps extends React.SVGProps<SVGSVGElement> {
  fill?: string;
  height?: string;
  width?: string;
  onClick?: () => void;
  id?: string;
  title?: string;
}

export const IconClose = ({ onClick, height, width, fill, ...restProps }: IconProps) => <Close onClick={onClick} height={height} width={width} fill={fill || 'var(--black)'} {...restProps} />;

export const IconTableFilter = ({ onClick, height, width, fill, ...restProps }: IconProps) => <TableFilter onClick={onClick} height={height} width={width} fill={fill || 'var(--black)'} {...restProps} />;

export const IconTableSearch = ({ onClick, height, width, fill, ...restProps }: IconProps) => <TableSearch onClick={onClick} height={height} width={width} fill={fill || 'var(--black)'} {...restProps} />;

export const IconEyeFill = ({ onClick, height, width, fill, ...restProps }: IconProps) => <EyeFill onClick={onClick} height={height} width={width} fill={fill || 'var(--black)'} {...restProps} />;

export const IconEyeSlashFill = ({ onClick, height, width, fill, ...restProps }: IconProps) => <EyeSlashFill onClick={onClick} height={height} width={width} fill={fill || 'var(--black)'} {...restProps} />;

export const IconToggleSidebar = ({ onClick, height, width, fill, ...restProps }: IconProps) => <ToggleSidebar onClick={onClick} height={height} width={width} {...restProps} />;

export const IconCircleClass = ({ onClick, height, width, fill, ...restProps }: IconProps) => <CircleClass onClick={onClick} height={height} width={width} {...restProps} />;

export const IconCircleTime = ({ onClick, height, width, fill, ...restProps }: IconProps) => <CircleTime onClick={onClick} height={height} width={width} {...restProps} />;

export const IconEmail = ({ onClick, height, width, fill, ...restProps }: IconProps) => <Email onClick={onClick} height={height} width={width} {...restProps} />;

export const IconStudentSidebarHomeworks = ({ onClick, height, width, fill, ...restProps }: IconProps) => <StudentSidebarHomeworks onClick={onClick} height={height} width={width} {...restProps} />;
export const IconStudentSidebarPractice = ({ onClick, height, width, fill, ...restProps }: IconProps) => <StudentSidebarPractice onClick={onClick} height={height} width={width} {...restProps} />;
export const IconStudentSidebarLogout = ({ onClick, height, width, fill, ...restProps }: IconProps) => <StudentSidebarLogout onClick={onClick} height={height} width={width} {...restProps} />;

export const IconLogo = ({ onClick, height, width, fill, ...restProps }: IconProps) => <Logo onClick={onClick} height={height} width={width} {...restProps} />;

export const IconGamemodeColumn = ({ onClick, height, width, fill, ...restProps }: IconProps) => <GameModeColumn onClick={onClick} height={height} width={width} {...restProps} />;
export const IconGamemodeFlash = ({ onClick, height, width, fill, ...restProps }: IconProps) => <GameModeFlash onClick={onClick} height={height} width={width} {...restProps} />;

export const IconMinus = ({ onClick, height, width, fill, ...restProps }: IconProps) => <Minus onClick={onClick} height={height} width={width} fill={fill} {...restProps} />;
export const IconPlus = ({ onClick, height, width, fill, ...restProps }: IconProps) => <Plus onClick={onClick} height={height} width={width} fill={fill} {...restProps} />;

export const IconGameSettingsCategory = ({ onClick, height, width, fill, ...restProps }: IconProps) => <GameSettingsCategory onClick={onClick} height={height} width={width} fill={fill} {...restProps} />;
export const IconGameSettingsChain = ({ onClick, height, width, fill, ...restProps }: IconProps) => <GameSettingsChain onClick={onClick} height={height} width={width} fill={fill} {...restProps} />;
export const IconGameSettingsDigits = ({ onClick, height, width, fill, ...restProps }: IconProps) => <GameSettingsDigits onClick={onClick} height={height} width={width} fill={fill} {...restProps} />;
export const IconGameSettingsInterval = ({ onClick, height, width, fill, ...restProps }: IconProps) => <GameSettingsInterval onClick={onClick} height={height} width={width} fill={fill} {...restProps} />;
export const IconGameSettingsRounds = ({ onClick, height, width, fill, ...restProps }: IconProps) => <GameSettingsRounds onClick={onClick} height={height} width={width} fill={fill} {...restProps} />;
export const IconGameSettingsPlay = ({ onClick, height, width, fill, ...restProps }: IconProps) => <GameSettingsPlay onClick={onClick} height={height} width={width} fill={fill} {...restProps} />;

export const IconGameExit = ({ onClick, height, width, fill, ...restProps }: IconProps) => <GameExit onClick={onClick} height={height} width={width} fill={fill} {...restProps} />;

export const IconCheckAnswer = ({ onClick, height, width, fill, ...restProps }: IconProps) => <CheckAnswer onClick={onClick} height={height} width={width} fill={fill || 'var(--white)'} {...restProps} />;
export const IconNextQuestion = ({ onClick, height, width, fill, ...restProps }: IconProps) => <NextQuestion onClick={onClick} height={height} width={width} fill={fill || 'var(--white)'} {...restProps} />;

export const IconResultsExit = ({ onClick, height, width, ...restProps }: IconProps) => <ResultsExit onClick={onClick} height={height} width={width} {...restProps} />;
export const IconResultsAgain = ({ onClick, height, width, ...restProps }: IconProps) => <ResultsAgain onClick={onClick} height={height} width={width} {...restProps} />;

export const IconStudentAdd = ({ onClick, height, width, ...restProps }: IconProps) => <StudentAdd onClick={onClick} height={height} width={width} {...restProps} />;
export const IconStudentRemove = ({ onClick, height, width, ...restProps }: IconProps) => <StudentRemove onClick={onClick} height={height} width={width} {...restProps} />;

export const IconDelete = ({ onClick, height, width, ...restProps }: IconProps) => <Delete onClick={onClick} height={height} width={width} {...restProps} />;
export const IconCardEdit = ({ onClick, height, width, ...restProps }: IconProps) => <CardEdit onClick={onClick} height={height} width={width} {...restProps} />;

export const IconSquiggleArrow = ({ height, width, fill, ...restProps }: IconProps) => <SquiggleArrow height={height} width={width} fill={fill} {...restProps} />;

export const IconPillTriangle = ({ height, width, fill, ...restProps }: IconProps) => <PillTriangle height={height} width={width} fill={fill} {...restProps} />;

export const IconCorrectAnswer = ({ height, width, fill, ...restProps }: IconProps) => <AnswerCorrect height={height} width={width} fill={fill} {...restProps} />;
export const IconIncorrectAnswer = ({ height, width, fill, ...restProps }: IconProps) => <AnswerIncorrect height={height} width={width} fill={fill} {...restProps} />;

export const IconStopwatch = ({ height, width, fill, ...restProps }: IconProps) => <Stopwatch height={height} width={width} fill={fill} {...restProps} />;
export const IconCheckCorrect = ({ height, width, fill, ...restProps }: IconProps) => <CheckCorrect height={height} width={width} fill={fill} {...restProps} />;

export const IconImageUpload = ({ height, width, fill, ...restProps }: IconProps) => <ImageUpload height={height} width={width} fill={fill} {...restProps} />;
export const IconInfo = ({ height, width, fill, ...restProps }: IconProps) => <Info height={height} width={width} fill={fill} {...restProps} />;
