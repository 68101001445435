import { AppState, Auth0Provider } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import { Outlet } from 'react-router-dom';
import * as ROUTES from 'helpers/routes';

export default function Auth0Wrapper() {
  const navigate = useNavigate();

  const onRedirectCallback = (appState: AppState) => {
    navigate((appState && appState.returnTo) || window.location.pathname);
  };

  return (
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN || ''}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID || ''}
      authorizationParams={{
        redirect_uri: `${window.location.origin}${ROUTES.TEACHER}`,
        audience: process.env.REACT_APP_AUTH0_AUDIENCE || '',
        scope: 'openid profile email',
      }}
      onRedirectCallback={onRedirectCallback}
      cacheLocation="localstorage"
    >
      <Outlet />
    </Auth0Provider>
  );
}
