import { IconResultsExit } from 'assets';
import styles from './GameResults.module.scss';
import { ITimerDisplay } from 'components/utils/displayTimer';
import { IResults } from '..';
import { useEffect } from 'react';
import { IAssignmentPut, IClientStudentAssignment } from 'cubiq-abacus-types';
import { submitAssignment } from 'store/studentSlice';
import { useAppDispatch } from 'store/configureStore';

interface GameResultsProps {
  foundAssignment: IClientStudentAssignment;
  displayTimer: ITimerDisplay;
  results: IResults[];
  exitGame: () => void;
  totalRounds: number;
}

function checkResults(results: any[]): string {
  const totalResults = results.length;
  const correctResults = results.filter((result) => result.correct).length;
  const incorrectResults = results.filter((result) => !result.correct).length;

  if (incorrectResults === totalResults) {
    return 'Better Luck Next Time';
  } else if (correctResults === totalResults) {
    return 'Congrats! 🎉';
  } else if (correctResults / totalResults >= 0.5) {
    return 'Almost There!';
  } else {
    return '';
  }
}

export default function AssignmentResults({ foundAssignment, displayTimer, exitGame, results, totalRounds }: GameResultsProps) {
  const timer = displayTimer;

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (results.length === totalRounds && results.every((result) => result.userAnswer !== '')) {
      const resultsObject: IAssignmentPut['payload'] = {
        assignmentId: foundAssignment._id,
        timeMs: timer.totalMilliseconds,
        answers: results.reduce((obj, result) => {
          obj[result._id] = Number(result.userAnswer);

          return obj;
        }, {}),
      };

      dispatch(submitAssignment(resultsObject));
    }
  }, [results]);

  return (
    <>
      <div className={styles.gameResults__content}>
        <div className={styles.gameResults__content__title}>{checkResults(results)}</div>
        <p className={styles.gameResults__content__score}>
          Your time:{' '}
          <span>
            {timer.minutes}:{timer.seconds}:{timer.milliseconds}
          </span>
        </p>
        <p className={styles.gameResults__content__score}>
          Your score:{' '}
          <span>
            {results.filter((result) => result.correct).length}/{results.length}
          </span>
        </p>
      </div>

      <div className={styles.gameResults__buttonContainer}>
        <button onClick={exitGame} className={`${styles.gameResults__button} ${styles.gameResults__button__exit}`}>
          <IconResultsExit width="40" fill="var(--primary)" />
          <p>Exit</p>
        </button>
      </div>
    </>
  );
}
