import { TypedUseSelectorHook, useSelector } from 'react-redux';
import { combineReducers } from 'redux';
import studentReducer from './studentSlice';
import teacherReducer from './teacherSlice';
import snackReducer from './snackbarSlice';

const rootReducer = combineReducers({
  student: studentReducer,
  teacher: teacherReducer,
  snackbar: snackReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
export const useAppSelector: TypedUseSelectorHook<RootState | any> = useSelector;

export default rootReducer;
