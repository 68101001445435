import React from 'react';
import { useAppDispatch } from 'store/configureStore';
import { login } from 'store/studentSlice';
import Button from 'components/Global/Button';
import { useFormik } from 'formik';
import styles from './style.module.scss';
import { IconLogo } from 'assets';

const StudentLogin: React.FC = () => {
  const dispatch = useAppDispatch();

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    onSubmit: (values) => {
      dispatch(login(values));
    },
  });

  return (
    <div className={styles.fullPage}>
      <form onSubmit={formik.handleSubmit} className={styles.form}>
        <IconLogo className={styles.form__logo} />

        <h1 className={styles.form__title}>Student Login</h1>

        <div className={styles.form__fieldGroup}>
          <div className={styles.form__group}>
            <label htmlFor="email">Email</label>
            <input type="email" id="email" name="email" placeholder="Enter your email" onChange={formik.handleChange} value={formik.values.email} required />
          </div>
          <div className={styles.form__group}>
            <label htmlFor="password">Password</label>
            <input type="password" id="password" name="password" placeholder="Enter your password" onChange={formik.handleChange} value={formik.values.password} required />
          </div>
        </div>

        <Button theme={'primary'} type="submit">
          Login
        </Button>
      </form>
    </div>
  );
};

export default StudentLogin;
