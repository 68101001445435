import React, { createContext, useState, useContext } from 'react';
import { Outlet } from 'react-router-dom';

const DispatchContext = createContext({
  dispatched: false,
  setDispatched: (dispatched: boolean) => {},
});

export const useDispatchContext = () => useContext(DispatchContext);

export const DispatchProvider = () => {
  const [dispatched, setDispatched] = useState(false);

  return (
    <DispatchContext.Provider value={{ dispatched, setDispatched }}>
      <Outlet />
    </DispatchContext.Provider>
  );
};
