// AnswerFeedback.js
import React, { useEffect } from 'react';
import styles from './AnswerFeedback.module.scss'; // Ensure you have some basic styling

import correctSound from 'assets/audio/correct.mp3';
import incorrectSound from 'assets/audio/mistake.mp3';

interface AnswerFeedbackProps {
  isCorrect: boolean;
  userAnswer: string;
  correctAnswer: string;
}

const AnswerFeedback = ({ isCorrect, correctAnswer, userAnswer }: AnswerFeedbackProps) => {
  const imageToShow = isCorrect ? '/illustrations/hedgehog-correct.png' : '/illustrations/hedgehog-mistake.png';
  const conditionalCircle = isCorrect ? styles.answerFeedback__expressionRow__greenCircle : styles.answerFeedback__expressionRow__redCircle;

  useEffect(() => {
    const audioToPlay = new Audio(isCorrect ? correctSound : incorrectSound);

    audioToPlay.play();
  }, [isCorrect]);

  return (
    <div className={styles.answerFeedback}>
      <div className={styles.answerFeedback__small}>{isCorrect ? `You got it the answer is ${userAnswer}!` : ` Oops! Mistakes Happen!`}</div>

      <div className={styles.answerFeedback__expressionRow}>
        <div className={conditionalCircle} />
        {/* <img src={imageToShow} alt={isCorrect ? 'Correct' : 'Incorrect'} /> */}
        {/* <div className={conditionalCircle} /> */}
      </div>

      <div className={styles.answerFeedback__small}>{isCorrect ? `Well Done!` : `The correct answer is ${correctAnswer}!`}</div>
    </div>
  );
};

export default AnswerFeedback;
