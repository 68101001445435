import { GameCategory } from 'cubiq-abacus-types';

interface Problem {
  question: string;
  correctAnswer: number;
}

interface ProblemSet {
  op: string;
  newSet: number[];
}

function excludeRanges(originalRanges, choices) {
  console.log('originalRanges', originalRanges);
  // Helper function to remove a number from a range
  function excludeFromRange(range, number) {
    const [start, end] = range;
    if (number < start || number > end) {
      return [range]; // Number not in range, return range unchanged
    } else if (number === start) {
      if (start === end) return [];
      else return [[start + 1, end]];
    } else if (number === end) {
      return [[start, end - 1]];
    } else {
      return [
        [start, number - 1],
        [number + 1, end],
      ];
    }
  }

  // Start with the original ranges and exclude numbers specified in choices
  let modifiedRanges = originalRanges.slice();
  choices.forEach((choice) => {
    let newRanges = [];
    modifiedRanges.forEach((range) => {
      excludeFromRange(range, choice).forEach((subrange) => newRanges.push(subrange));
    });
    modifiedRanges = newRanges;
  });

  // Check if the resulting array is empty, and if so, return [[0, 0]]
  if (modifiedRanges.length === 0) {
    console.log('modifiedRanges', modifiedRanges);
    console.log('pickedRange', [0, 0]);
    return [0, 0];
  }

  console.log('modifiedRanges', modifiedRanges);
  const pickedRange = pickOne(modifiedRanges);
  console.log('pickedRange', pickedRange);
  return pickedRange;
}

function pickOne(arr) {
  const randomIndex = Math.floor(Math.random() * arr.length);
  return arr[randomIndex];
}

function getIntFromArr(numbers) {
  // Input validation
  if (!Array.isArray(numbers) || !numbers.every((num) => Array.isArray(num) && num.length >= 2)) {
    throw new Error('Invalid input: each item in the numbers array must be an array with at least 2 numeric elements.');
  }

  let totalChoices = 0;
  for (let i = 0; i < numbers.length; i++) {
    totalChoices += numbers[i][1] - numbers[i][0] + 1;
  }

  let randomChoice = Math.floor(Math.random() * totalChoices);
  let index = 0;
  while (randomChoice >= numbers[index][1] - numbers[index][0] + 1) {
    randomChoice -= numbers[index][1] - numbers[index][0] + 1;
    index++;
  }

  // Make sure the index is within bounds before attempting to access
  if (index < numbers.length) {
    return numbers[index][0] + randomChoice;
  } else {
    throw new Error('Index out of bounds: the calculated index is beyond the input array.');
  }
}

//--------------------DIRECT--------------------//
export function generateArithmeticProblems(digitCount: number, extraOp: number = 0, _: number[], problemCount: number = 1): Problem[] {
  const additionRules = {
    0: [1, 2, 3, 4, 5, 6, 7, 8, 9],
    1: [1, 2, 3, 5, 6, 7, 8],
    2: [1, 2, 5, 6, 7],
    3: [1, 5, 6],
    4: [5],
    5: [1, 2, 3, 4],
    6: [1, 2, 3],
    7: [1, 2],
    8: [1],
    9: [],
  };

  const subtractionRules = {
    0: [],
    1: [1],
    2: [1, 2],
    3: [1, 2, 3],
    4: [1, 2, 3, 4],
    5: [5],
    6: [1, 5, 6],
    7: [1, 2, 5, 6, 7],
    8: [1, 2, 3, 5, 6, 7, 8],
    9: [1, 2, 3, 4, 5, 6, 7, 8, 9],
  };

  function generateNumber(digits: number): number {
    let result = '';
    for (let i = 0; i < digits; i++) {
      if (i === 0) {
        result += Math.floor(Math.random() * 9) + 1;
      } else {
        result += Math.floor(Math.random() * 10);
      }
    }
    return parseInt(result, 10);
  }

  function generateOperand(baseNumber: number, currentResult: number): { operand: number; operation: string } {
    const baseDigits = String(baseNumber).split('').map(Number);
    let operandDigits: number[] = [];
    let operation = Math.random() < 0.5 ? '+' : '-';
    let rules = operation === '+' ? additionRules : subtractionRules;

    // Force subtraction if the first digit of the current result is 9
    if (String(currentResult)[0] === '9') {
      operation = '-';
      rules = subtractionRules;
    }

    for (let i = 0; i < baseDigits.length; i++) {
      const baseDigit = baseDigits[i];
      let allowedDigits = rules[baseDigit];

      if (allowedDigits.length === 0) {
        // If no valid digits, switch operation and recalculate
        operation = operation === '+' ? '-' : '+';
        rules = operation === '+' ? additionRules : subtractionRules;
        allowedDigits = rules[baseDigit];
      }

      const selectedDigit = allowedDigits[Math.floor(Math.random() * allowedDigits.length)];
      operandDigits.push(selectedDigit);
    }

    return { operand: parseInt(operandDigits.join(''), 10), operation };
  }

  function generateSingleProblem(): { question: string; correctAnswer: number } {
    const numbers: number[] = [generateNumber(digitCount)];
    const operations: string[] = [];
    let result = numbers[0];

    for (let i = 0; i <= extraOp; i++) {
      const { operand, operation } = generateOperand(result, result);
      numbers.push(operand);
      operations.push(operation);

      if (operation === '+') {
        result += operand;
      } else {
        result -= operand;
      }

      // Ensure result stays positive and within the digit count
      if (result < 0 || String(result).length > digitCount) {
        result = Math.abs(result) % 10 ** digitCount;
        operations[operations.length - 1] = operations[operations.length - 1] === '+' ? '-' : '+';
      }
    }

    const question = numbers.reduce((acc, num, index) => {
      if (index === 0) return num.toString();
      return `${acc} ${operations[index - 1]} ${num}`;
    }, '');

    return { question, correctAnswer: result };
  }

  const problems: Array<{ question: string; correctAnswer: number }> = [];

  for (let i = 0; i < problemCount; i++) {
    problems.push(generateSingleProblem());
  }

  return problems;
}

export function generateArithmeticProblemsStrict(digits: number, extraOp: number = 0, _: number[], problemCount: number = 1): Problem[] {
  const additionRules = {
    0: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
    1: [0, 1, 2, 3, 5, 6, 7, 8],
    2: [0, 1, 2, 5, 6, 7],
    3: [0, 1, 5, 6],
    4: [0, 5],
    5: [0, 1, 2, 3, 4],
    6: [0, 1, 2, 3],
    7: [0, 1, 2],
    8: [0, 1],
    9: [0],
  };

  const subtractionRules = {
    0: [0],
    1: [0, 1],
    2: [0, 1, 2],
    3: [0, 1, 2, 3],
    4: [0, 1, 2, 3, 4],
    5: [0, 5],
    6: [0, 1, 5, 6],
    7: [0, 1, 2, 5, 6, 7],
    8: [0, 1, 2, 3, 5, 6, 7, 8],
    9: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
  };

  function generateNumber(digits: number): number {
    let result = '';
    for (let i = 0; i < digits; i++) {
      if (i === 0) {
        result += Math.floor(Math.random() * 9) + 1;
      } else {
        result += Math.floor(Math.random() * 10);
      }
    }
    return parseInt(result, 10);
  }

  function generateOperand(baseNumber: number, currentResult: number, maxDigits: number): { operand: number; operation: string } {
    const baseDigits = String(baseNumber).split('').map(Number);
    let operandDigits: number[] = [];
    let operation = '+';
    let rules = additionRules;

    // Check if addition would exceed the digit limit
    if (String(currentResult + baseNumber).length > maxDigits) {
      operation = '-';
      rules = subtractionRules;
    }

    for (let i = 0; i < baseDigits.length; i++) {
      const baseDigit = baseDigits[i];
      let allowedDigits = rules[baseDigit];

      let selectedDigit: number;
      if (i === 0) {
        const nonZeroDigits = allowedDigits.filter((d) => d !== 0);
        selectedDigit = nonZeroDigits[Math.floor(Math.random() * nonZeroDigits.length)];
      } else {
        selectedDigit = allowedDigits[Math.floor(Math.random() * allowedDigits.length)];
      }

      operandDigits.push(selectedDigit);
    }

    return { operand: parseInt(operandDigits.join(''), 10), operation };
  }

  function generateSingleProblem(): { question: string; correctAnswer: number } {
    const numbers: number[] = [generateNumber(digits)];
    const operations: string[] = [];
    let result = numbers[0];

    for (let i = 0; i <= extraOp; i++) {
      const { operand, operation } = generateOperand(numbers[numbers.length - 1], result, digits);
      numbers.push(operand);
      operations.push(operation);

      if (operation === '+') {
        result += operand;
      } else {
        result -= operand;
      }

      // Ensure result stays positive
      if (result < 0) {
        result = Math.abs(result);
        operations[operations.length - 1] = operations[operations.length - 1] === '+' ? '-' : '+';
      }
    }

    const question = numbers.reduce((acc, num, index) => {
      if (index === 0) return num.toString();
      return `${acc} ${operations[index - 1]} ${num}`;
    }, '');

    return { question, correctAnswer: result };
  }

  const problems: Array<{ question: string; correctAnswer: number }> = [];

  for (let i = 0; i < problemCount; i++) {
    problems.push(generateSingleProblem());
  }

  return problems;
}

//--------------------5S--------------------//

function get_ADD_5(xDigit, choices) {
  const ranges = {
    1: [[4, 4]],
    2: [[3, 4]],
    3: [[2, 4]],
    4: [[1, 4]],
  };
  return excludeRanges(ranges[xDigit], choices);
}

function get_SUB_5(xDigit, choices) {
  const ranges = {
    5: [[1, 4]],
    6: [[2, 4]],
    7: [[3, 4]],
    8: [[4, 4]],
  };
  return excludeRanges(ranges[xDigit], choices);
}

function get_ADD_5_DIRECT(xDigit) {
  const ranges = {
    0: [[0, 9]],
    1: [
      [0, 3],
      [5, 8],
    ],
    2: [
      [0, 2],
      [5, 7],
    ],
    3: [
      [0, 1],
      [5, 6],
    ],
    4: [
      [0, 0],
      [5, 5],
    ],
    5: [[0, 4]],
    6: [[0, 3]],
    7: [[0, 2]],
    8: [[0, 1]],
    9: [[0, 0]],
  };
  return pickOne(ranges[xDigit] || [0, 0]);
}

function get_SUB_5_DIRECT(xDigit) {
  const ranges = {
    0: [[0, 0]],
    1: [[0, 1]],
    2: [[0, 2]],
    3: [[0, 3]],
    4: [[0, 4]],
    5: [
      [0, 0],
      [5, 5],
    ],
    6: [
      [0, 1],
      [5, 6],
    ],
    7: [
      [0, 2],
      [5, 7],
    ],
    8: [
      [0, 3],
      [5, 8],
    ],
    9: [[0, 9]],
  };
  return pickOne(ranges[xDigit] || []);
}

export function _5sSUB(bucketLength: number, extraSetNumber: number, selected: number[], problemCount: number): Problem[] {
  const problems = [];

  for (let problemIndex = 0; problemIndex < problemCount; problemIndex++) {
    let sets: (number[] | ProblemSet)[] = [new Array(bucketLength).fill(0), new Array(bucketLength).fill(0)];

    let resultSet = new Array(bucketLength).fill(0);
    let specialPickedIndex = Math.floor(Math.random() * bucketLength);

    sets[0][specialPickedIndex] = getIntFromArr([[5, 8]]);
    for (let i = 0; i < bucketLength; i++) {
      if (i !== specialPickedIndex) {
        sets[0][i] = getIntFromArr([[1, 9]]);
      }
    }

    sets[1][specialPickedIndex] = getIntFromArr([get_SUB_5(sets[0][specialPickedIndex], selected)]);

    for (let i = 0; i < bucketLength; i++) {
      if (i !== specialPickedIndex) {
        sets[1][i] = getIntFromArr([get_SUB_5_DIRECT(sets[0][i])]);
      }
      resultSet[i] = sets[0][i] - sets[1][i];
    }

    console.log('Index:\t', specialPickedIndex);
    console.log('Init:\t', JSON.stringify(sets));
    console.log('\t=', JSON.stringify(resultSet));

    for (let setIndex = 0; setIndex < extraSetNumber; setIndex++) {
      let shouldAdd = resultSet.every((val) => val === 0);
      let newSet = [];

      do {
        if (shouldAdd) {
          newSet = resultSet.map((value) => getIntFromArr([get_ADD_5_DIRECT(value)]));
          resultSet = resultSet.map((value, index) => value + newSet[index]);
        } else {
          newSet = resultSet.map((value) => getIntFromArr([get_SUB_5_DIRECT(value)]));
          resultSet = resultSet.map((value, index) => value - newSet[index]);
        }
      } while (newSet.every((val) => val === 0));

      sets.push({ op: shouldAdd ? '+' : '-', newSet });

      console.log(`${shouldAdd ? '\t+' : '\t-'}`, JSON.stringify(newSet));
      console.log(`(${setIndex + 1})\t=`, JSON.stringify(resultSet));
    }

    // Update the first two array elements to be objects
    sets[0] = { op: '', newSet: sets[0] as number[] };
    sets[1] = { op: '-', newSet: sets[1] as number[] };

    // Map each object in the array to a string
    const expression = sets
      .map(({ op, newSet }: ProblemSet) => {
        // Join the newSet array into a string, convert to a number to remove leading zeros, and back to string
        const number = parseInt(newSet.join(''), 10).toString();
        const space = op ? ' ' : '';
        return op + space + number;
      })
      .join(' ');

    // Calculate the result using eval()
    const result = eval(expression);

    console.log('sets', sets);
    console.log('expression', expression);
    console.log('result', result);

    problems.push({
      question: expression,
      correctAnswer: result,
    });
  }
  return problems;
}

export function _5sADD(bucketLength: number, extraSetNumber: number, selected: number[], problemCount: number): Problem[] {
  const problems = [];

  for (let problemIndex = 0; problemIndex < problemCount; problemIndex++) {
    let sets: (number[] | ProblemSet)[] = [new Array(bucketLength).fill(0), new Array(bucketLength).fill(0)];

    let resultSet = new Array(bucketLength).fill(0);
    let specialPickedIndex = Math.floor(Math.random() * bucketLength);

    // Initialize the first set and construct the question string
    sets[0][specialPickedIndex] = getIntFromArr([[1, 4]]);

    for (let i = 0; i < bucketLength; i++) {
      if (i !== specialPickedIndex) {
        sets[0][i] = getIntFromArr([[1, 9]]);
      }
    }

    // Initialize the second set and update the question string
    sets[1][specialPickedIndex] = getIntFromArr([get_ADD_5(sets[0][specialPickedIndex], selected)]);
    for (let i = 0; i < bucketLength; i++) {
      if (i !== specialPickedIndex) {
        sets[1][i] = getIntFromArr([get_ADD_5_DIRECT(sets[0][i])]);
      }

      resultSet[i] = sets[0][i] + sets[1][i];
    }

    console.log('Index:\t', specialPickedIndex);
    console.log('Init:\t', JSON.stringify(sets));
    console.log('\t=', JSON.stringify(resultSet));

    // Extra operations based on condition checks

    for (let setIndex = 0; setIndex < extraSetNumber; setIndex++) {
      let shouldSubtract = resultSet.every((val) => val === 9);
      let newSet;

      do {
        newSet = new Array(bucketLength).fill(0).map((_, i) => {
          return getIntFromArr([shouldSubtract ? get_SUB_5_DIRECT(resultSet[i]) : get_ADD_5_DIRECT(resultSet[i])]);
        });
      } while (newSet.every((val) => val === 0)); // Ensure newSet is not all zeros

      resultSet = resultSet.map((val, i) => {
        let newVal = shouldSubtract ? val - newSet[i] : val + newSet[i];
        return newVal >= 10 ? newVal - 10 : newVal < 0 ? newVal + 10 : newVal; // Wrap around for both addition and subtraction
      });

      sets.push({ op: shouldSubtract ? '-' : '+', newSet });

      console.log(`${shouldSubtract ? '\t-' : '\t+'}`, JSON.stringify(newSet));
      console.log(`(${setIndex + 1})\t=`, JSON.stringify(resultSet));
    }

    // Update the first two array elements to be objects
    sets[0] = { op: '', newSet: sets[0] as number[] };
    sets[1] = { op: '+', newSet: sets[1] as number[] };

    // Map each object in the array to a string
    const expression = sets
      .map(({ op, newSet }: ProblemSet) => {
        // Join the newSet array into a string, convert to a number to remove leading zeros, and back to string
        const number = parseInt(newSet.join(''), 10).toString();
        const space = op ? ' ' : '';
        return op + space + number;
      })
      .join(' ');

    // Calculate the result using eval()
    const result = eval(expression);

    console.log('sets', sets);
    console.log('expression', expression);
    console.log('result', result);

    problems.push({
      question: expression,
      correctAnswer: result,
    });
  }
  return problems;
}

//--------------------10S--------------------//

function get_ADD_10(xDigit, choices) {
  console.log('xDigit', xDigit);
  console.log('exclude choices', choices);
  const ranges = {
    1: [[9, 9]],
    2: [[8, 9]],
    3: [[7, 9]],
    4: [[6, 9]],
    5: [[5, 5]],
    6: [
      [9, 9],
      [4, 5],
    ],
    7: [
      [8, 9],
      [3, 5],
    ],
    8: [
      [7, 9],
      [2, 5],
    ],
    9: [[1, 9]],
  };

  return excludeRanges(ranges[xDigit], choices);
}

function get_SUB_10(xDigit, choices) {
  console.log('xDigit', xDigit);
  console.log('exclude choices', choices);
  const ranges = {
    0: [[1, 9]],
    1: [
      [2, 5],
      [7, 9],
    ],
    2: [
      [3, 5],
      [8, 9],
    ],
    3: [
      [4, 5],
      [9, 9],
    ],
    4: [[5, 5]],
    5: [[6, 9]],
    6: [[7, 9]],
    7: [[8, 9]],
    8: [[9, 9]],
  };
  return excludeRanges(ranges[xDigit], choices);
}

function get_ADD_10_DIRECT(xDigit) {
  const ranges = {
    0: [0, 8],
    1: [0, 7],
    2: [0, 6],
    3: [0, 5],
    4: [0, 4],
    5: [0, 3],
    6: [0, 2],
    7: [0, 1],
    8: [0, 0],
  };
  return ranges[xDigit] || [0, 0];
}

function get_SUB_10_DIRECT_INIT(xDigit) {
  const ranges = {
    1: [0, 0],
    2: [0, 1],
    3: [0, 2],
    4: [0, 3],
    5: [0, 4],
    6: [0, 5],
    7: [0, 6],
    8: [0, 7],
    9: [0, 8],
  };
  return ranges[xDigit] || [0, 0];
}

function get_SUB_10_DIRECT(xDigit) {
  const ranges = {
    0: [0, 0],
    1: [0, 1],
    2: [0, 2],
    3: [0, 3],
    4: [0, 4],
    5: [0, 5],
    6: [0, 6],
    7: [0, 7],
    8: [0, 8],
    9: [0, 9],
  };
  return ranges[xDigit] || [0, 0];
}

function _10sSUB(bucketLength: number, extraSetNumber: number, selected: number[], problemCount: number): Problem[] {
  const problems = [];

  for (let problemIndex = 0; problemIndex < problemCount; problemIndex++) {
    let sets: (number[] | ProblemSet)[] = [new Array(bucketLength).fill(0).map(() => getIntFromArr([[1, 8]])), new Array(bucketLength).fill(0)];

    // Ensuring specialPickedIndex is never 0
    let specialPickedIndex = Math.floor(Math.random() * (bucketLength - 1)) + 1;

    // Determining values for the second set
    sets[1][specialPickedIndex] = getIntFromArr([get_SUB_10(sets[0][specialPickedIndex], selected)]);
    for (let i = 0; i < bucketLength; i++) {
      if (i !== specialPickedIndex) {
        sets[1][i] = getIntFromArr([get_SUB_10_DIRECT_INIT(sets[0][i])]);
      }
    }

    // Calculating the initial result set with consideration for overflow at specialPickedIndex
    let resultSet = (sets[0] as number[]).map((num, i) => num - sets[1][i]);
    // Adjust for any value below 0 and carry over the excess
    if (resultSet[specialPickedIndex] < 0) {
      resultSet[specialPickedIndex] += 10;
      if (specialPickedIndex > 0) resultSet[specialPickedIndex - 1] -= 1;
    }

    console.log('Index:\t', specialPickedIndex);
    console.log('Init:\t', JSON.stringify(sets));
    console.log('\t=', JSON.stringify(resultSet));

    for (let setIndex = 0; setIndex < extraSetNumber; setIndex++) {
      let shouldAdd = resultSet.every((val) => val === 0);
      let newSet;

      do {
        newSet = new Array(bucketLength).fill(0).map((_, i) => {
          return getIntFromArr([shouldAdd ? get_ADD_10_DIRECT(resultSet[i]) : get_SUB_10_DIRECT(resultSet[i])]);
        });
      } while (newSet.every((val) => val === 0)); // Ensure newSet is not all zeros

      resultSet = resultSet.map((val, i) => {
        let newVal = shouldAdd ? val + newSet[i] : val - newSet[i];
        return newVal >= 10 ? newVal - 10 : newVal < 0 ? newVal + 10 : newVal; // Wrap around for both addition and subtraction
      });

      sets.push({ op: shouldAdd ? '+' : '-', newSet });

      console.log(`${shouldAdd ? '\t+' : '\t-'}`, JSON.stringify(newSet));
      console.log(`(${setIndex + 1})\t=`, JSON.stringify(resultSet));
    }

    // Update the first two array elements to be objects
    sets[0] = { op: '', newSet: sets[0] as number[] };
    sets[1] = { op: '-', newSet: sets[1] as number[] };

    // Map each object in the array to a string
    const expression = sets
      .map(({ op, newSet }: ProblemSet) => {
        // Join the newSet array into a string, convert to a number to remove leading zeros, and back to string
        const number = parseInt(newSet.join(''), 10).toString();
        const space = op ? ' ' : '';
        return op + space + number;
      })
      .join(' ');

    // Calculate the result using eval()
    const result = eval(expression);

    console.log('sets', sets);
    console.log('expression', expression);
    console.log('result', result);

    problems.push({
      question: expression,
      correctAnswer: result,
    });
  }
  return problems;
}

function _10sADD(bucketLength: number, extraSetNumber: number, selected: number[], problemCount: number): Problem[] {
  const problems = [];

  for (let problemIndex = 0; problemIndex < problemCount; problemIndex++) {
    let sets: (number[] | ProblemSet)[] = [new Array(bucketLength).fill(0).map(() => getIntFromArr([[1, 8]])), new Array(bucketLength).fill(0)];

    // Ensuring specialPickedIndex is never 0
    let specialPickedIndex = Math.floor(Math.random() * (bucketLength - 1)) + 1;

    // Determining values for the second set
    sets[1][specialPickedIndex] = getIntFromArr([get_ADD_10(sets[0][specialPickedIndex], selected)]);

    for (let i = 0; i < bucketLength; i++) {
      if (i !== specialPickedIndex) {
        sets[1][i] = getIntFromArr([get_ADD_10_DIRECT(sets[0][i])]);
      }
    }

    // Calculating the initial result set with consideration for overflow at specialPickedIndex
    let resultSet = (sets[0] as number[]).map((num, i) => num + sets[1][i]);

    // Adjust for any value exceeding 9 and carry over the excess
    if (resultSet[specialPickedIndex] > 9) {
      resultSet[specialPickedIndex] %= 10;
      if (specialPickedIndex > 0) resultSet[specialPickedIndex - 1] += 1;
    }

    console.log('Index:\t', specialPickedIndex);
    console.log('Init:\t', JSON.stringify(sets));
    console.log('\t=', JSON.stringify(resultSet));

    for (let setIndex = 0; setIndex < extraSetNumber; setIndex++) {
      let shouldSubtract = resultSet.every((val) => val === 9 || val === 8);
      let newSet;

      do {
        newSet = new Array(bucketLength).fill(0).map((_, i) => {
          return getIntFromArr([shouldSubtract ? get_SUB_10_DIRECT(resultSet[i]) : get_ADD_10_DIRECT(resultSet[i])]);
        });
      } while (newSet.every((val) => val === 0)); // Ensure newSet is not all zeros

      resultSet = resultSet.map((val, i) => {
        let newVal = shouldSubtract ? val - newSet[i] : val + newSet[i];
        return newVal >= 10 ? newVal - 10 : newVal < 0 ? newVal + 10 : newVal; // Wrap around for both addition and subtraction
      });

      sets.push({ op: shouldSubtract ? '-' : '+', newSet });

      console.log(`${shouldSubtract ? '\t-' : '\t+'}`, JSON.stringify(newSet));
      console.log(`(${setIndex + 1})\t=`, JSON.stringify(resultSet));
    }

    // Update the first two array elements to be objects
    sets[0] = { op: '', newSet: sets[0] as number[] };
    sets[1] = { op: '+', newSet: sets[1] as number[] };

    // Map each object in the array to a string
    const expression = sets
      .map(({ op, newSet }: ProblemSet) => {
        // Join the newSet array into a string, convert to a number to remove leading zeros, and back to string
        const number = parseInt(newSet.join(''), 10).toString();
        const space = op ? ' ' : '';
        return op + space + number;
      })
      .join(' ');

    // Calculate the result using eval()
    const result = eval(expression);

    console.log('sets', sets);
    console.log('expression', expression);
    console.log('result', result);

    problems.push({
      question: expression,
      correctAnswer: result,
    });
  }
  return problems;
}

//--------------------MIX--------------------//

const init_ADD_RANGE = [5, 8];
const init_SUB_RANGE = [1, 4];

function get_ADD_MIX(xDigit, choices) {
  console.log('xDigit', xDigit);
  console.log('exclude choices', choices);
  const ranges = {
    5: [[6, 9]],
    6: [[6, 8]],
    7: [[6, 7]],
    8: [[6, 6]],
  };

  return excludeRanges(ranges[xDigit], choices);
}

function get_SUB_MIX(xDigit, choices) {
  console.log('xDigit', xDigit);
  console.log('exclude choices', choices);
  const ranges = {
    1: [[6, 6]],
    2: [[6, 7]],
    3: [[6, 8]],
    4: [[6, 9]],
  };

  return excludeRanges(ranges[xDigit], choices);
}

function get_ADD_DIRECT(xDigit) {
  const ranges = {
    0: [0, 8],
    1: [0, 7],
    2: [0, 6],
    3: [0, 5],
    4: [0, 4],
    5: [0, 3],
    6: [0, 2],
    7: [0, 1],
    8: [0, 0],
  };
  return ranges[xDigit] || [0, 0];
}

function get_SUB_DIRECT(xDigit) {
  const ranges = {
    0: [0, 0],
    1: [0, 1],
    2: [0, 2],
    3: [0, 3],
    4: [0, 4],
    5: [0, 5],
    6: [0, 6],
    7: [0, 7],
    8: [0, 8],
    9: [0, 9],
  };
  return ranges[xDigit] || [0, 0];
}

function get_SUB_DIRECT_INIT(xDigit) {
  const ranges = {
    1: [0, 0],
    2: [0, 1],
    3: [0, 2],
    4: [0, 3],
    5: [0, 4],
    6: [0, 5],
    7: [0, 6],
    8: [0, 7],
    9: [0, 8],
  };
  return ranges[xDigit] || [0, 0];
}

function _MixSUB(bucketLength: number, extraSetNumber: number, selected: number[], problemCount: number): Problem[] {
  const problems = [];

  for (let problemIndex = 0; problemIndex < problemCount; problemIndex++) {
    let sets: (number[] | ProblemSet)[] = [new Array(bucketLength).fill(0).map(() => getIntFromArr([init_SUB_RANGE])), new Array(bucketLength).fill(0)];

    // Ensuring specialPickedIndex is never 0
    let specialPickedIndex = Math.floor(Math.random() * (bucketLength - 1)) + 1;

    // Determining values for the second set
    sets[1][specialPickedIndex] = getIntFromArr([get_SUB_MIX(sets[0][specialPickedIndex], selected)]);
    for (let i = 0; i < bucketLength; i++) {
      if (i !== specialPickedIndex) {
        sets[1][i] = getIntFromArr([get_SUB_DIRECT_INIT(sets[0][i])]);
      }
    }

    // Calculating the initial result set with consideration for overflow at specialPickedIndex
    let resultSet = (sets[0] as number[]).map((num, i) => num - sets[1][i]);
    // Adjust for any value below 0 and carry over the excess
    if (resultSet[specialPickedIndex] < 0) {
      resultSet[specialPickedIndex] += 10;
      if (specialPickedIndex > 0) resultSet[specialPickedIndex - 1] -= 1;
    }

    console.log('Index:\t', specialPickedIndex);
    console.log('Init:\t', JSON.stringify(sets));
    console.log('\t=', JSON.stringify(resultSet));

    for (let setIndex = 0; setIndex < extraSetNumber; setIndex++) {
      let shouldAdd = resultSet.every((val) => val === 0);
      let newSet = [];

      do {
        newSet = new Array(bucketLength).fill(0).map((_, i) => {
          return getIntFromArr([shouldAdd ? get_ADD_DIRECT(resultSet[i]) : get_SUB_DIRECT(resultSet[i])]);
        });
      } while (newSet.every((val) => val === 0));

      resultSet = resultSet.map((val, i) => {
        let newVal = shouldAdd ? val + newSet[i] : val - newSet[i];
        return newVal >= 10 ? newVal - 10 : newVal < 0 ? newVal + 10 : newVal; // Wrap around for both addition and subtraction
      });

      sets.push({ op: shouldAdd ? '+' : '-', newSet });
      console.log(`${shouldAdd ? '\t+' : '\t-'}`, JSON.stringify(newSet));
      console.log(`(${setIndex + 1})\t=`, JSON.stringify(resultSet));
    }

    // Update the first two array elements to be objects
    sets[0] = { op: '', newSet: sets[0] as number[] };
    sets[1] = { op: '-', newSet: sets[1] as number[] };

    // Map each object in the array to a string
    const expression = sets
      .map(({ op, newSet }: ProblemSet) => {
        // Join the newSet array into a string, convert to a number to remove leading zeros, and back to string
        const number = parseInt(newSet.join(''), 10).toString();
        const space = op ? ' ' : '';
        return op + space + number;
      })
      .join(' ');

    // Calculate the result using eval()
    const result = eval(expression);

    console.log('sets', sets);
    console.log('expression', expression);
    console.log('result', result);

    problems.push({
      question: expression,
      correctAnswer: result,
    });
  }

  return problems;
}

function _MixADD(bucketLength: number, extraSetNumber: number, selected: number[], problemCount: number): Problem[] {
  const problems = [];

  for (let problemIndex = 0; problemIndex < problemCount; problemIndex++) {
    let sets: (number[] | ProblemSet)[] = [new Array(bucketLength).fill(0).map(() => getIntFromArr([init_ADD_RANGE])), new Array(bucketLength).fill(0)];

    // Ensuring specialPickedIndex is never 0
    let specialPickedIndex = Math.floor(Math.random() * (bucketLength - 1)) + 1;

    // Determining values for the second set
    sets[1][specialPickedIndex] = getIntFromArr([get_ADD_MIX(sets[0][specialPickedIndex], selected)]);

    for (let i = 0; i < bucketLength; i++) {
      if (i !== specialPickedIndex) {
        sets[1][i] = getIntFromArr([get_ADD_DIRECT(sets[0][i])]);
      }
    }

    // Calculating the initial result set with consideration for overflow at specialPickedIndex
    let resultSet = (sets[0] as number[]).map((num, i) => num + sets[1][i]);

    // Adjust for any value exceeding 9 and carry over the excess
    if (resultSet[specialPickedIndex] > 9) {
      resultSet[specialPickedIndex] %= 10;
      if (specialPickedIndex > 0) resultSet[specialPickedIndex - 1] += 1;
    }

    console.log('Index:\t', specialPickedIndex);
    console.log('Init:\t', JSON.stringify(sets));
    console.log('\t=', JSON.stringify(resultSet));

    for (let setIndex = 0; setIndex < extraSetNumber; setIndex++) {
      let shouldSubtract = resultSet.every((val) => val === 9 || val === 8);
      let newSet;

      do {
        newSet = new Array(bucketLength).fill(0).map((_, i) => {
          return getIntFromArr([shouldSubtract ? get_SUB_DIRECT(resultSet[i]) : get_ADD_DIRECT(resultSet[i])]);
        });
      } while (newSet.every((val) => val === 0)); // Ensure newSet is not all zeros

      resultSet = resultSet.map((val, i) => {
        let newVal = shouldSubtract ? val - newSet[i] : val + newSet[i];
        return newVal >= 10 ? newVal - 10 : newVal < 0 ? newVal + 10 : newVal; // Wrap around for both addition and subtraction
      });

      sets.push({ op: shouldSubtract ? '-' : '+', newSet });

      console.log(`${shouldSubtract ? '\t-' : '\t+'}`, JSON.stringify(newSet));
      console.log(`(${setIndex + 1})\t=`, JSON.stringify(resultSet));
    }

    // Update the first two array elements to be objects
    sets[0] = { op: '', newSet: sets[0] as number[] };
    sets[1] = { op: '+', newSet: sets[1] as number[] };

    // Map each object in the array to a string
    const expression = sets
      .map(({ op, newSet }: ProblemSet) => {
        // Join the newSet array into a string, convert to a number to remove leading zeros, and back to string
        const number = parseInt(newSet.join(''), 10).toString();
        const space = op ? ' ' : '';
        return op + space + number;
      })
      .join(' ');

    // Calculate the result using eval()
    const result = eval(expression);

    console.log('sets', sets);
    console.log('expression', expression);
    console.log('result', result);

    problems.push({
      question: expression,
      correctAnswer: result,
    });
  }

  return problems;
}

const generateProblems = (gameCategory: GameCategory, gameDigits: number, gameChain: number, gameFormulas: number[], gameRounds: number) => {
  switch (gameCategory) {
    case GameCategory['DIRECT']:
      return generateArithmeticProblems(gameDigits, gameChain, gameFormulas, gameRounds);
    case GameCategory['DIRECT_STRICT']:
      return generateArithmeticProblemsStrict(gameDigits, gameChain, gameFormulas, gameRounds);
    case GameCategory['5S_ADD']:
      return _5sSUB(gameDigits, gameChain, gameFormulas, gameRounds);
    case GameCategory['5S_SUB']:
      return _5sADD(gameDigits, gameChain, gameFormulas, gameRounds);
    case GameCategory['10S_ADD']:
      return _10sADD(gameDigits, gameChain, gameFormulas, gameRounds);
    case GameCategory['10S_SUB']:
      return _10sSUB(gameDigits, gameChain, gameFormulas, gameRounds);
    case GameCategory['MIX_ADD']:
      return _MixADD(gameDigits, gameChain, gameFormulas, gameRounds);
    case GameCategory['MIX_SUB']:
      return _MixSUB(gameDigits, gameChain, gameFormulas, gameRounds);
    default:
      return []; // Return an empty array or a default set of options if necessary
  }
};

export default generateProblems;
