import Skeleton from 'react-loading-skeleton';
import styles from './style.module.scss';

export default function SidebarNormalSkeleton() {
  return (
    <>
      <div className={styles.sidebar}>
        <div className={styles.header}>
          <Skeleton width={246} height={50} className="skeleton" />
          <div className={styles.header__profileContentList}>
            <Skeleton width={246} height={20} className="skeleton" />
            <Skeleton width={246} height={20} className="skeleton" />
            <Skeleton width={246} height={20} className="skeleton" />
          </div>
          <div className={styles.buttons}>
            <Skeleton width={246} height={56} className="skeleton" />
            <Skeleton width={246} height={56} className="skeleton" />
            <Skeleton width={246} height={56} className="skeleton" />
          </div>
        </div>
        <div className={styles.footer}>
          <Skeleton width={246} height={136.5} className="skeleton" />
          <Skeleton width={246} height={25} className="skeleton" />
        </div>
      </div>
    </>
  );
}
