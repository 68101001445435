import axios from 'axios';

let baseURL;
switch (process.env.NODE_ENV) {
  case 'development':
    baseURL = 'http://localhost:5050';
    break;
  case 'production':
    baseURL = 'https://cubiq-abacus-backend.onrender.com';
    break;
  default:
    baseURL = 'http://localhost:5050';
    break;
}

const CUBIQ = axios.create({ baseURL });

export default CUBIQ;
