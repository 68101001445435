import styles from './styles.module.scss';

import { GameType, IClientStudentAssignment, IClientStudentCompletedAssignment } from 'cubiq-abacus-types';
import { useEffect, useState } from 'react';
import GameControlPanel from 'components/Teacher/AbacusPlayground/GameFlow/GameControlPanel';
import DisplayFlash from 'components/Teacher/AbacusPlayground/GameFlow/DisplayFlash';
import GameInputPanel from 'components/Teacher/AbacusPlayground/GameFlow/GameInputPanel';
import DisplayColumn from 'components/Teacher/AbacusPlayground/GameFlow/DisplayColumn';
import AnswerFeedback from 'components/Teacher/AbacusPlayground/GameFlow/AnswerFeedback';

import Button from 'components/Global/Button';
import AssignmentResults from '../Practice/GameFlow/AssignmentResults';
import { displayTimer } from 'components/utils/displayTimer';
import { IResults } from '../Practice';

export default function GamePortal({ foundAssignment }: { foundAssignment: IClientStudentAssignment }) {
  const [totalRounds, setTotalRounds] = useState<number>(foundAssignment.homeworkObjectId.gameSettings.rounds);
  const [appearInterval, setAppearInterval] = useState<number>(foundAssignment.homeworkObjectId.gameSettings.flashIn);
  const [gameStatus, setGameStatus] = useState('notStarted');
  const [currentRound, setCurrentRound] = useState(0);
  const [timer, setTimer] = useState(0);
  const [userAnswer, setUserAnswer] = useState('');
  const [currentQuestions, setCurrentQuestions] = useState([]);
  const [rounds, setRounds] = useState([]);
  const [results, setResults] = useState<IResults[]>([]);
  const [displayGetReady, setDisplayGetReady] = useState(false);
  const [countdown, setCountdown] = useState(3);
  const [nextQuestionPending, setNextQuestionPending] = useState(false);
  const [isLastAnswerCorrect, setIsLastAnswerCorrect] = useState(null);
  const [stopSequence, setStopSequence] = useState(true);
  const [countdownIntervalId, setCountdownIntervalId] = useState(null);
  const [gameMode, setGameMode] = useState<GameType>(foundAssignment.homeworkObjectId.gameSettings.mode);

  const startGame = () => {
    setCurrentRound(0);
    setGameMode(foundAssignment.homeworkObjectId.gameSettings.mode);
    setGameStatus(null);
    setIsLastAnswerCorrect(null);
    setNextQuestionPending(false);
    setStopSequence(true);
    setDisplayGetReady(true);
    setTimer(0);
    setUserAnswer('');
    setCountdown(3);
    setResults([]);
    setRounds(new Array(totalRounds).fill(null));

    // Choose the right function based on the gameCategory and initialize questions

    setCurrentQuestions(foundAssignment.bank);

    const countdownInterval = setInterval(() => {
      setCountdown((prevCountdown) => {
        if (prevCountdown > 1) {
          return prevCountdown - 1;
        } else {
          // Once countdown is finished, start the game
          clearInterval(countdownInterval);
          setDisplayGetReady(false);
          setCurrentRound(1);
          setGameStatus('inProgress');
          return prevCountdown;
        }
      });
    }, 1000);

    setCountdownIntervalId(countdownInterval); // Save the interval ID
  };

  const handleCheckAnswer = () => {
    const currentQuestion = currentQuestions[currentRound - 1];
    const correct = parseInt(userAnswer) === currentQuestion.correctAnswer;

    setRounds((prev) => prev.map((val, index) => (index === currentRound - 1 ? correct : val)));

    // Record the result
    setResults((prevResults) => [
      ...prevResults,
      {
        question: currentQuestion.question,
        userAnswer,
        correctAnswer: currentQuestion.correctAnswer,
        correct,
        _id: currentQuestion._id,
      },
    ]);

    setIsLastAnswerCorrect(correct);
    setNextQuestionPending(true);
  };

  const handleNextQuestion = () => {
    setUserAnswer('');

    if (currentRound < totalRounds) {
      if (gameMode === GameType.Column) {
        setCurrentRound((prev) => prev + 1); // Move to next question
        setNextQuestionPending(false);
      }
      if (gameMode === GameType.Flash) {
        setStopSequence(true);
        setDisplayGetReady(true);
        setCountdown(3); // Start countdown from 3
        const countdownInterval = setInterval(() => {
          setCountdown((prevCountdown) => {
            if (prevCountdown > 1) {
              return prevCountdown - 1;
            } else {
              clearInterval(countdownInterval); // Clear interval when countdown reaches 0
              setCurrentRound((prev) => prev + 1); // Move to next question
              setNextQuestionPending(false);
              setDisplayGetReady(false);
              return 0; // Reset countdown (optional, based on your UI logic)
            }
          });
        }, 1000); // Decrease countdown every second
      }
    } else {
      setGameStatus('finished');
    }
  };

  const exitGame = () => {
    if (countdownIntervalId) {
      clearInterval(countdownIntervalId);
      setCountdownIntervalId(null); // Reset the interval ID
    }

    // Reset all relevant states to their initial values
    setGameStatus('notStarted');
    setNextQuestionPending(false);
    setDisplayGetReady(false);
    setCurrentRound(0);
    setTimer(0);
    setUserAnswer('');
    setCountdown(3);
    setResults([]);
    setRounds(new Array(totalRounds).fill(null));
    setCurrentQuestions([]);
    setIsLastAnswerCorrect(null);
  };

  useEffect(() => {
    let interval;
    if (gameStatus === 'inProgress' && !nextQuestionPending && !stopSequence) {
      interval = setInterval(() => {
        setTimer((prev) => prev + 1); // Now, this is in milliseconds
      }, 1); // Update every millisecond
    } else {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [gameStatus, nextQuestionPending, stopSequence]);

  return (
    <>
      {gameStatus === 'notStarted' && !displayGetReady && (
        <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh', padding: 20, justifyContent: 'center' }}>
          <Button theme={gameMode === GameType.Column ? 'green' : 'orange'} onClick={() => startGame()}>
            Start {gameMode === GameType.Column ? 'Column' : 'Flash'}
          </Button>
        </div>
      )}

      {(gameStatus === 'inProgress' || gameStatus === 'finished' || displayGetReady) && (
        <div className={styles.gameWindow}>
          <GameControlPanel currentRound={currentRound} displayTimer={displayTimer(timer)} exitGame={exitGame} gameStatus={gameStatus} nextQuestionPending={nextQuestionPending} rounds={rounds} />
          {displayGetReady && !currentRound && (
            <div id={gameMode === GameType.Flash ? GameType.Flash.toLowerCase() : GameType.Column.toLowerCase()} className={styles.gameWindow__getReadyText}>
              Get ready! {countdown}
            </div>
          )}
          {displayGetReady && !!currentRound && <div className={styles.countdownCircle} />}
          {gameStatus === 'inProgress' && !nextQuestionPending && gameMode === GameType.Flash && <DisplayFlash currentQuestion={currentQuestions[currentRound - 1]} setStopSequence={setStopSequence} appearInterval={appearInterval} />}
          {gameStatus === 'inProgress' && !nextQuestionPending && gameMode === GameType.Column && <DisplayColumn currentQuestion={currentQuestions[currentRound - 1]} setStopSequence={setStopSequence} />}
          {nextQuestionPending && gameStatus !== 'finished' && !displayGetReady && <AnswerFeedback isCorrect={isLastAnswerCorrect} userAnswer={userAnswer} correctAnswer={currentQuestions[currentRound - 1].correctAnswer} />}
          {gameStatus !== 'finished' && (
            <GameInputPanel gameHasStarted={displayGetReady} roundHasChanged={nextQuestionPending} handleCheckAnswer={handleCheckAnswer} handleNextQuestion={handleNextQuestion} setUserAnswer={setUserAnswer} userAnswer={userAnswer} />
          )}
          {gameStatus === 'finished' && !displayGetReady && <AssignmentResults foundAssignment={foundAssignment} totalRounds={totalRounds} displayTimer={displayTimer(timer)} exitGame={exitGame} results={results} />}
        </div>
      )}
    </>
  );
}
