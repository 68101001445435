import React from 'react';
import styles from './Cards.module.scss';
import { IconGameSettingsChain, IconGameSettingsDigits, IconGameSettingsInterval, IconGameSettingsPlay, IconGameSettingsRounds, IconGamemodeFlash, IconMinus, IconPlus } from 'assets';
import Button from 'components/Global/Button';
import { GameCategory, GameType } from 'cubiq-abacus-types';
import CheckboxGroupNoFormik from 'components/Teacher/Form/CheckboxGroupNoFormik';
import { getFormulaOptions } from 'components/utils/getFormulas';

interface GameModeFlashProps {
  startGame: (gameType: GameType) => void;
  setTotalRounds: any;
  totalRounds: number;
  setChain: any;
  chain: number;
  setFormulas: any;
  formulas: number[];
  appearInterval: number;
  setAppearInterval: (value: number) => void;
  setGameCategory: (category: GameCategory) => void;
  gameCategory: GameCategory;
  setNumberOfDigits: (value: number) => void;
  numberOfDigits: number;
}

export default function GameModeFlash({
  setTotalRounds,
  totalRounds,
  setChain,
  chain,
  setFormulas,
  formulas,
  startGame,
  appearInterval,
  setAppearInterval,
  gameCategory,
  setGameCategory,
  setNumberOfDigits,
  numberOfDigits,
}: GameModeFlashProps) {
  const handleChainArrowKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'ArrowUp') {
      setChain((prev: number) => Math.max(2, prev + 1));
    } else if (e.key === 'ArrowDown') {
      setChain((prev: number) => Math.max(2, prev - 1));
    }
  };

  const handleChainBlur = () => {
    //@ts-ignore
    if (chain === '' || isNaN(chain)) {
      setChain(2);
    } else {
      setChain(Math.max(2, parseInt(chain as unknown as string, 10)));
    }
  };

  const handleChainChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (value === '') {
      setChain(''); // Temporarily allow an empty string
      return;
    }
    const numValue = Math.max(2, parseInt(value, 10));
    if (!isNaN(numValue)) {
      setChain(numValue);
    }
  };

  const updateChainOnClick = (valueChange: number) => {
    setChain((prev: number) => {
      const updatedValue = prev + valueChange;
      return Math.max(2, updatedValue);
    });
  };

  const handleRoundsArrowKeyDown = (e) => {
    if (e.key === 'ArrowUp') {
      setTotalRounds((prev) => Math.max(1, prev + 1));
    } else if (e.key === 'ArrowDown') {
      setTotalRounds((prev) => Math.max(1, prev - 1));
    }
  };

  const incrementAppearInterval = () => {
    //@ts-ignore
    setAppearInterval((prev) => {
      let currentValue = parseFloat(prev);
      if (isNaN(currentValue)) currentValue = 0.1; // Default to 0.1 if current value is not a number
      const newValue = Math.min(currentValue + 0.1, 3.0); // Cap at 3.0
      return newValue.toFixed(1); // Keep one decimal place
    });
  };

  const decrementAppearInterval = () => {
    //@ts-ignore
    setAppearInterval((prev) => {
      let currentValue = parseFloat(prev);
      if (isNaN(currentValue)) currentValue = 0.1;
      const newValue = Math.max(currentValue - 0.1, 0.1); // No less than 0.1
      return newValue.toFixed(1); // Keep one decimal place
    });
  };

  const handleRoundsBlur = () => {
    // @ts-ignore
    if (totalRounds === '' || isNaN(totalRounds)) {
      setTotalRounds(1);
    } else {
      // @ts-ignore
      setTotalRounds(Math.max(1, parseInt(totalRounds, 10)));
    }
  };

  const handleRoundsChange = (e) => {
    const value = e.target.value;

    if (value === '') {
      //@ts-ignore
      setTotalRounds(''); // Temporarily allow an empty string
      return;
    }

    // Convert the input value to a number and ensure it's at least 1
    const numValue = Math.max(1, parseInt(value, 10));

    if (!isNaN(numValue)) {
      setTotalRounds(numValue);
    }
  };

  const updateRoundsOnClick = (valueChange: number) => {
    setTotalRounds((prev: number) => {
      const updatedValue = prev + valueChange;
      return Math.max(1, updatedValue);
    });
  };

  const handleAppearIntervalChange = (e) => {
    const value = e.target.value.replace(/[^\d.]/g, '');
    // Allow numbers and single-decimal numbers only
    const regex = /^[0-9]*\.?[0-9]?$/;
    if (value === '' || regex.test(value)) {
      setAppearInterval(value);
    }
  };

  const handleAppearIntervalBlur = () => {
    // @ts-ignore
    let value = parseFloat(appearInterval);
    if (isNaN(value) || value === 0) {
      value = 0.1;
    } else if (value > 3) {
      value = 3; // Cap the value at 3
    } else {
      value = Math.round(value * 10) / 10; // Round to one decimal place
    } // @ts-ignore
    setAppearInterval(value.toFixed(1)); // Ensure one decimal place
  };

  const handleGameCategoryChange = (e) => {
    console.log(e.target.value);
    setGameCategory(e.target.value as GameCategory);
    setFormulas([]);
  };

  const handleNumberDigitChange = (e) => {
    setNumberOfDigits(e.target.value);
  };

  return (
    <div className={styles.gameCard} id="flash">
      <div className={styles.gameCard__headerContainer}>
        <div className={styles.gameCard__header}>
          <IconGamemodeFlash width="50" />
          <p>Flash Mode</p>
        </div>
        <div className={styles.gameCard__inputContainer}>
          <div className={styles.gameCard__inputGroup}>
            <div className={styles.gameCard__inputGroup__labelGroup}>
              <IconGameSettingsPlay width="30" fill="var(--gamemode-flash-text)" />
              <p className={styles.gameCard__inputGroup__label} id="flash">
                category
              </p>
            </div>
            <div className={styles.gameCard__inputGroup__row}>
              <select value={gameCategory} onChange={handleGameCategoryChange} className={styles.gameCard__selectFlash}>
                {Object.entries(GameCategory).map(([key, value]) => (
                  <option key={key} value={value}>
                    {value}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className={styles.gameCard__inputGroup}>
            <div className={styles.gameCard__inputGroup__labelGroup}>
              <IconGameSettingsDigits width="30" fill="var(--gamemode-flash-text)" />
              <p className={styles.gameCard__inputGroup__label} id="flash">
                digits
              </p>
            </div>
            <div className={styles.gameCard__inputGroup__row}>
              <select value={numberOfDigits} onChange={handleNumberDigitChange} className={styles.gameCard__selectFlash}>
                {gameCategory === GameCategory['5S_ADD'] || gameCategory === GameCategory['5S_SUB'] || gameCategory === GameCategory['DIRECT'] || gameCategory === GameCategory['DIRECT_STRICT'] ? (
                  <>
                    <option value={1}>{'1 Digits (ex. 1)'}</option>
                    <option value={2}>{'2 Digits (ex. 20)'}</option>
                    <option value={3}>{'3 Digits (ex. 300)'}</option>
                    <option value={4}>{'4 Digits (ex. 4000)'}</option>
                    <option value={5}>{'5 Digits (ex. 5000)'}</option>
                  </>
                ) : gameCategory === GameCategory['10S_ADD'] || gameCategory === GameCategory['10S_SUB'] || gameCategory === GameCategory['MIX_ADD'] || gameCategory === GameCategory['MIX_SUB'] ? (
                  <>
                    <option value={2}>{'2 Digits (ex. 20)'}</option>
                    <option value={3}>{'3 Digits (ex. 300)'}</option>
                    <option value={4}>{'4 Digits (ex. 4000)'}</option>
                    <option value={5}>{'5 Digits (ex. 50000)'}</option>
                  </>
                ) : null}
              </select>
            </div>
          </div>

          <div className={styles.gameCard__inputGroup}>
            <div className={styles.gameCard__inputGroup__labelGroup}>
              <IconGameSettingsChain width="30" fill="var(--gamemode-flash-text)" />
              <p className={styles.gameCard__inputGroup__label} id="flash">
                chain
              </p>
            </div>
            <div className={styles.gameCard__inputGroup__row}>
              <IconMinus width="20" onClick={() => updateChainOnClick(-1)} fill="var(--gamemode-flash-orange)" />
              <input id="flash" type="text" value={chain} onChange={handleChainChange} onBlur={handleChainBlur} onKeyDown={handleChainArrowKeyDown} pattern="\d*" />
              <IconPlus width="20" onClick={() => updateChainOnClick(1)} fill="var(--gamemode-flash-orange)" />
            </div>
          </div>

          {![GameCategory.DIRECT, GameCategory.DIRECT_STRICT].includes(gameCategory) && (
            <CheckboxGroupNoFormik gameType={GameType.Flash} options={getFormulaOptions(gameCategory)} label="formulas" setFormulas={setFormulas} formulas={formulas} />
          )}

          <div className={styles.gameCard__inputGroup}>
            <div className={styles.gameCard__inputGroup__labelGroup}>
              <IconGameSettingsRounds width="30" fill="var(--gamemode-flash-text)" />
              <p className={styles.gameCard__inputGroup__label} id="flash">
                rounds
              </p>
            </div>
            <div className={styles.gameCard__inputGroup__row}>
              <IconMinus width="20" onClick={() => updateRoundsOnClick(-1)} fill="var(--gamemode-flash-orange)" />
              <input id="flash" type="text" value={totalRounds} onChange={handleRoundsChange} onBlur={handleRoundsBlur} onKeyDown={handleRoundsArrowKeyDown} pattern="\d*" />
              <IconPlus width="20" onClick={() => updateRoundsOnClick(1)} fill="var(--gamemode-flash-orange)" />
            </div>
          </div>
          <div className={styles.gameCard__inputGroup}>
            <div className={styles.gameCard__inputGroup__labelGroup}>
              <IconGameSettingsInterval width="30" fill="var(--gamemode-flash-text)" />
              <p className={styles.gameCard__inputGroup__label} id="flash">
                speed
              </p>
            </div>
            <div className={styles.gameCard__inputGroup__row}>
              <IconMinus width="20" onClick={decrementAppearInterval} fill="var(--gamemode-flash-orange)" />
              <input id="flash" type="text" value={appearInterval + 's'} onChange={handleAppearIntervalChange} onBlur={handleAppearIntervalBlur} className={styles.gameCard__input} />
              <IconPlus width="20" onClick={incrementAppearInterval} fill="var(--gamemode-flash-orange)" />
            </div>
          </div>
        </div>
      </div>

      <div className={styles.gameCard__buttonContainer}>
        <Button theme={'orange'} onClick={startGame}>
          Start
        </Button>
      </div>
    </div>
  );
}
