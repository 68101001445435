export const displayTimer = (timer: number): ITimerDisplay => {
  const minutes = Math.floor(timer / 60000)
    .toString()
    .padStart(2, '0');
  const seconds = Math.floor((timer % 60000) / 1000)
    .toString()
    .padStart(2, '0');
  const milliseconds = (timer % 1000).toString().padStart(3, '0');
  const totalMilliseconds = timer;
  return { minutes, seconds, milliseconds, totalMilliseconds };
};

export interface ITimerDisplay {
  minutes: string;
  seconds: string;
  milliseconds: string;
  totalMilliseconds: number;
}
