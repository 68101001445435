import React, { useEffect, useState } from 'react';
import { Formik, Form, FormikProps } from 'formik';
import * as Yup from 'yup';
import Button from 'components/Global/Button';
import { IGroupDelete, IGroupGet, IGroupPut, IStudentGet } from 'cubiq-abacus-types';
import { useAppDispatch } from 'store/configureStore';
import { deleteGroup, updateGroup } from 'store/teacherSlice';
import { ErrorSummary, GenericField, StudentSelection } from 'components/Teacher/Form';
import { IconDelete } from 'assets';
import styles from './styles.module.scss';

interface EditGroupPopupProps {
  students: IStudentGet['response'][];
  selectedGroup: IGroupGet['response'];
  closePopup: () => void;
}

const validationSchema = Yup.object({
  groupName: Yup.string().required('Group name is required'),
});

const EditGroupPopup: React.FC<EditGroupPopupProps> = ({ students, selectedGroup, closePopup }) => {
  const dispatch = useAppDispatch();

  const initialStudentEmails = selectedGroup.studentIds.map((studentId) => studentId.email);

  const [currentStudentSelection, setCurrentStudentSelection] = useState<string[]>(initialStudentEmails);
  const [studentsToAdd, setStudentsToAdd] = useState<string[]>([]);
  const [studentsToRemove, setStudentsToRemove] = useState<string[]>([]);
  const [hasStudentListChanged, setHasStudentListChanged] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    // Check if there are any changes in added or removed students
    setHasStudentListChanged(studentsToAdd.length > 0 || studentsToRemove.length > 0);
  }, [studentsToAdd, studentsToRemove]);

  // Whenever students are added or removed, check against the initial list to decide if it should be in added or removed lists
  const handleStudentAdded = (email: string) => {
    if (!initialStudentEmails.includes(email)) {
      // If not originally part of the group, add to studentsToAdd
      setStudentsToAdd((prev) => [...prev, email].filter((e) => !studentsToRemove.includes(e))); // Ensure it's not in removed
    }
    setStudentsToRemove((prev) => prev.filter((e) => e !== email)); // Remove from studentsToRemove if it was there
  };

  const handleStudentRemoved = (email: string) => {
    if (initialStudentEmails.includes(email)) {
      // If originally part of the group, add to studentsToRemove
      setStudentsToRemove((prev) => [...prev, email].filter((e) => !studentsToAdd.includes(e))); // Ensure it's not in added
    }
    setStudentsToAdd((prev) => prev.filter((e) => e !== email)); // Remove from studentsToAdd if it was there
  };

  // Filter students based on search term and group assignment
  const filteredStudentsWhoAreUnassignedOrPartOfGroup = students.filter((student) => {
    const isInGroupOrUnassigned = student.groupObjectId.groupId === selectedGroup.groupId || student.groupObjectId.groupId === 'g-unassigned';
    const matchesSearchTerm = student.name.toLowerCase().includes(searchTerm.toLowerCase());
    const isCurrentlySelected = currentStudentSelection.includes(student.email);
    // Include the student if they are currently selected or if they match the search term
    return isInGroupOrUnassigned && (matchesSearchTerm || isCurrentlySelected);
  });

  const studentsWhoAreUnassignedOrPartOfGroup = students.filter((student) => {
    const isInGroupOrUnassigned = student.groupObjectId.groupId === selectedGroup.groupId || student.groupObjectId.groupId === 'g-unassigned';
    return isInGroupOrUnassigned;
  });

  const handleDeleteGroup = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    if (window.confirm('Are you sure you want to delete this group?')) {
      const payload: IGroupDelete['payload'] = { groupId: selectedGroup.groupId };
      // console.log('handleDeleteGroup payload:', payload);
      dispatch(deleteGroup(payload));
      closePopup();
    }
  };

  const initialValues: Pick<IGroupPut['payload'], 'groupName' | 'groupId'> = {
    groupName: selectedGroup.groupName,
    groupId: selectedGroup.groupId,
  };

  const handleEditGroup = (values: IGroupPut['payload']) => {
    if (window.confirm('Are you sure you want to change the info of this student?')) {
      // console.log('handleEditGroup payload:', payload);
      const payload: IGroupPut['payload'] = {
        ...values,
        addStudentEmails: studentsToAdd,
        removeStudentEmails: studentsToRemove,
      };
      dispatch(updateGroup(payload));
      closePopup();
    }
  };

  return (
    <>
      <div className={styles.overlay} onClick={closePopup}></div>

      <div className={styles.popup}>
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleEditGroup} enableReinitialize>
          {({ errors, touched, isValid, resetForm, dirty }: FormikProps<IGroupPut['payload']>) => (
            <Form>
              <div className="section-header">
                <label>Group Details</label>
                <Button className={styles['list-action-button']} onClick={(e) => handleDeleteGroup(e)}>
                  <p style={{ color: 'var(--red_text)' }}>Delete Group</p>
                  <IconDelete width="20" fill="var(--red_text)" />
                </Button>
              </div>

              <div className={styles['fields-stack']}>
                <GenericField name="groupName" label="Group Name" type="text" placeholder="Enter group name" error={errors.groupName && touched.groupName} />
              </div>
              <div className={styles.separator}></div>
              <div className="section-header" style={{ marginBottom: 10 }}>
                <label>Students</label>

                <p>
                  Added: {currentStudentSelection.length}/{filteredStudentsWhoAreUnassignedOrPartOfGroup.length}
                </p>
              </div>
              {/* <SearchAndFilter showSearch searchTerm={searchTerm} onSearchTermChange={setSearchTerm} totalItems={studentsWhoAreUnassignedOrPartOfGroup.length} filteredItemsCount={filteredStudentsWhoAreUnassignedOrPartOfGroup.length} /> */}

              <div className={styles['fields-stack']}>
                {filteredStudentsWhoAreUnassignedOrPartOfGroup.map((student) => (
                  <StudentSelection
                    key={student.email}
                    student={student}
                    currentStudentSelection={currentStudentSelection}
                    setCurrentStudentSelection={setCurrentStudentSelection}
                    onStudentAdded={handleStudentAdded}
                    onStudentRemoved={handleStudentRemoved}
                  />
                ))}
              </div>

              <ErrorSummary errors={errors} touched={touched} />

              <div className={styles['button-footer']}>
                <Button
                  theme={'secondary'}
                  onClick={() => {
                    closePopup();
                    resetForm();
                  }}
                >
                  Cancel
                </Button>
                <Button theme={'primary'} type="submit" disabled={(!hasStudentListChanged && !dirty) || !isValid}>
                  Save Changes
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default EditGroupPopup;
