import { Field } from 'formik';

interface DateFieldProps {
  name: string;
  label: string;
  error: boolean;
  required?: boolean;
  disabled?: boolean;
  min?: string;
  max?: string;
}

const DateField: React.FC<DateFieldProps> = ({ name, label, error, required = false, disabled = false, min, max }) => {
  return (
    <div className={`field-flex ${error && 'error'}`}>
      <label>
        {label}
        {required && <span className="asterisk">*</span>}:
      </label>
      <Field name={name} type={'date'} className={error && 'error'} disabled={disabled} {...(min && { min })} {...(max && { max })} />
    </div>
  );
};

export default DateField;
