import React, { useEffect, useState } from 'react';
import './styles.scss';
import { IClientTeacherStudents } from 'cubiq-abacus-types';
import LoadingSkeleton from './skeleton';
import SearchAndFilter from 'components/Teacher/SearchAndFilter';
import { useDispatchContext } from 'context/DispatchContext';
import { IconCardEdit } from 'assets';
import EmptyCard from 'components/Global/EmptyCard';
import { getStudentAvatar } from 'components/utils/getStudentAvatar';

interface StudentTableProps {
  students: IClientTeacherStudents[];
  handleStudentClick: (student: IClientTeacherStudents) => void;
}

const StudentTable: React.FC<StudentTableProps> = ({ students, handleStudentClick }) => {
  const [loading, setLoading] = useState(true);
  const { dispatched } = useDispatchContext();
  const [filteredStudents, setFilteredStudents] = useState<IClientTeacherStudents[]>(students);
  const [sortField, setSortField] = useState('name'); // default sort by name
  const [sortDirection, setSortDirection] = useState('asc'); // 'asc' or 'desc'

  // Function to determine the header class
  const getHeaderClass = (field) => {
    return sortField === field ? `sortable-header sorted-${sortDirection}` : 'sortable-header';
  };

  const sortStudents = (a, b) => {
    const getValue = (student, field) => {
      // Handle different fields and ensure the value is a string
      let value;
      switch (field) {
        case 'name':
        case 'email':
          value = student[field];
          break;
        case 'group':
          value = student.groupObjectId?.groupName;
          break;
        default:
          value = '';
      }
      return typeof value === 'string' ? value.toLowerCase() : '';
    };

    const fieldA = getValue(a, sortField);
    const fieldB = getValue(b, sortField);

    if (fieldA < fieldB) return sortDirection === 'asc' ? -1 : 1;
    if (fieldA > fieldB) return sortDirection === 'asc' ? 1 : -1;
    return 0;
  };

  // Shallow copy of filteredStudents before sorting
  const sortedStudents = [...filteredStudents].sort(sortStudents);

  const handleSort = (field) => {
    if (sortField === field) {
      // Toggle sort direction
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      // Change sort field and reset direction to ascending
      setSortField(field);
      setSortDirection('asc');
    }
  };

  useEffect(() => {
    if (dispatched) setLoading(false);

    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      {loading ? (
        <LoadingSkeleton />
      ) : students.length > 0 ? (
        <>
          <SearchAndFilter students={students} filteredStudents={filteredStudents} setFilteredStudents={setFilteredStudents} showFilter showSearch />
          <table className="user-table">
            <thead>
              <tr>
                <th className={getHeaderClass('name')} onClick={() => handleSort('name')}>
                  Name
                </th>
                <th className={getHeaderClass('email')} onClick={() => handleSort('email')}>
                  Email
                </th>
                <th className={getHeaderClass('group')} onClick={() => handleSort('group')}>
                  Group
                </th>
                <th>Edit</th>
              </tr>
            </thead>
            <tbody>
              {sortedStudents.map((student) => (
                <tr key={student.email} onClick={() => handleStudentClick(student)} className="table-row">
                  <td className="td-name-avatar">
                    <img src={getStudentAvatar(student.avatar)} alt="" />
                    {student.name}
                  </td>
                  <td>{student.email}</td>
                  <td>{student.groupObjectId.groupName}</td>
                  <td>
                    <IconCardEdit width="30" fill="var(--primary)" />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      ) : (
        <EmptyCard showIcon placeholderText='Click the "Add New Student" to Create a New Student' />
      )}
    </>
  );
};

export default StudentTable;
