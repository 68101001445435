export function formatMilliseconds(milliseconds) {
  // Calculate minutes
  const minutes = Math.floor(milliseconds / 60000);
  // Calculate seconds
  const seconds = Math.floor((milliseconds % 60000) / 1000);
  // Calculate remaining milliseconds (after converting to seconds, take the remainder)
  const remainingMilliseconds = Math.floor((milliseconds % 1000) / 10); // Dividing by 10 to get a two-digit representation

  // Pad with zeros to ensure two digits for minutes and seconds, and two digits for milliseconds
  const formattedMinutes = minutes.toString().padStart(2, '0');
  const formattedSeconds = seconds.toString().padStart(2, '0');
  const formattedMilliseconds = remainingMilliseconds.toString().padStart(2, '0');

  return `${formattedMinutes}:${formattedSeconds}:${formattedMilliseconds}`;
}

export function convertMillisecondsToSecondsDecimal(milliseconds: number) {
  return (milliseconds / 1000).toFixed(1);
}
