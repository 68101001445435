import React from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import Button from 'components/Global/Button';
import { postStudent } from 'store/teacherSlice';
import { IClientTeacherGroups, IStudentPost } from 'cubiq-abacus-types';
import { useAppDispatch } from 'store/configureStore';
import { AvatarField, ErrorSummary, GenericField, PasswordField, SelectField } from 'components/Teacher/Form';
import styles from './styles.module.scss';

interface CreateNewStudentPopupProps {
  groups: IClientTeacherGroups[];
  closePopup: () => void;
}

const CreateNewStudentPopup: React.FC<CreateNewStudentPopupProps> = ({ groups, closePopup }) => {
  const groupIds = groups.map((group) => group.groupId);
  const acceptableGroupIds = [...groupIds, 'g-unassigned'];

  const validationSchema = Yup.object({
    name: Yup.string().required('Name is required'),
    email: Yup.string().email('Please enter a valid email address').required('Email is required'),
    groupId: Yup.string().oneOf(acceptableGroupIds, 'Invalid group').required('Group is required'),
    password: Yup.string().required('Password is required'),
  });

  const dispatch = useAppDispatch();

  const initialValues: IStudentPost['payload'] = {
    name: '',
    email: '',
    groupId: 'g-unassigned',
    password: '',
    avatar: 'default-avatar-00',
  };

  const handleCreateNewStudent = (payload: IStudentPost['payload']) => {
    console.log('create new student', payload);
    dispatch(postStudent(payload));
    closePopup();
  };

  return (
    <>
      <div className={styles.overlay} onClick={closePopup}></div>

      <div className={styles.popup}>
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleCreateNewStudent}>
          {({ errors, touched, isValid, resetForm }) => (
            <Form>
              <div className="section-header">
                <label>Add New Student</label>
              </div>
              <div className={styles['fields-stack']}>
                <GenericField name="name" label="Name" type="text" placeholder="Enter name" error={errors.name && touched.name} required />
                <GenericField name="email" label="Email" type="email" placeholder="Enter email" error={errors.email && touched.email} required />
                <PasswordField name="password" label="Password" placeholder="Enter password" error={errors.password && touched.password} required />
                <SelectField
                  name="groupId"
                  label="Group"
                  options={groups.map((group) => ({ value: group.groupId, label: group.groupName }))}
                  error={errors.groupId && touched.groupId}
                  defaultOption={{ value: 'g-unassigned', label: 'Unassigned' }}
                />
                {/* <AvatarField setCustomFile={setFile} name="avatar" label="Avatar" avatars={Array.from({ length: 5 }, (_, i) => `default-avatar-0${i}`)} error={errors.avatar && touched.avatar} /> */}
              </div>

              <ErrorSummary errors={errors} touched={touched} />

              <div className={styles['button-footer']}>
                <Button
                  theme={'secondary'}
                  onClick={() => {
                    closePopup();
                    resetForm();
                  }}
                >
                  Cancel
                </Button>
                <Button theme={'primary'} type="submit" disabled={!isValid}>
                  Add New Student
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default CreateNewStudentPopup;
