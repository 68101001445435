import React from 'react';
import styles from './Button.module.scss';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  theme?: 'light' | 'primary' | 'secondary' | 'green' | 'orange';
  onClick?: any;
  children: React.ReactNode;
}

const Button: React.FC<ButtonProps> = ({ theme, onClick, children, ...restProps }) => {
  return (
    <button className={`${styles.button} ${styles[theme || '']}`} onClick={onClick} {...restProps}>
      {children}
    </button>
  );
};

export default Button;
