import { Field } from 'formik';

interface GenericFieldProps {
  name: string;
  label: string;
  type: string;
  placeholder: string;
  error: boolean;
  required?: boolean;
  disabled?: boolean;
}

const GenericField: React.FC<GenericFieldProps> = ({ name, label, type, placeholder, error, required = false, disabled = false }) => (
  <div className={`field-flex ${error && 'error'}`}>
    <label>
      {label}
      {required && <span className="asterisk">*</span>}:
    </label>
    <Field name={name} type={type} placeholder={placeholder} className={error && 'error'} disabled={disabled} />
  </div>
);

export default GenericField;
