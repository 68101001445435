import React from 'react';
import { Link } from 'react-router-dom';
import styles from './PageHome.module.scss';

const PageHome = () => {
  return (
    <div className={styles.homeContainer}>
      <h1>Welcome to CubIQ</h1>
      <div className={styles.cardContainer}>
        <Link to="/student" className={styles.styledLink}>
          <div className={styles.card}>
            <h2>Student</h2>
            <p>Access your assignments and practice</p>
          </div>
        </Link>
        <Link to="/teacher" className={styles.styledLink}>
          <div className={styles.card}>
            <h2>Teacher</h2>
            <p>Manage classes and assignments</p>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default PageHome;
