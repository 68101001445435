import React from 'react';
import { Field, useField, useFormikContext } from 'formik';

interface SelectFieldProps {
  name: string;
  label: string;
  options: { value: string | number; label: string }[];
  error: boolean;
  required?: boolean;
  defaultOption?: { value: string | number; label: string };
}

const SelectField: React.FC<SelectFieldProps> = ({ name, label, options, error, required, defaultOption }) => {
  const { setFieldValue } = useFormikContext();
  const [field] = useField(name);

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = parseInt(e.target.value, 10);
    const finalValue = isNaN(value) ? e.target.value : value;
    setFieldValue(name, finalValue);
  };

  return (
    <div className={`field-flex ${error && 'error'}`}>
      <label>
        {label}
        {required && <span className="asterisk">*</span>}:
      </label>
      <Field as="select" {...field} onChange={handleChange}>
        {defaultOption && (
          <option key={defaultOption.value} value={defaultOption.value}>
            {defaultOption.label}
          </option>
        )}
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </Field>
    </div>
  );
};

export default SelectField;
