import React, { useEffect, useState } from 'react';
import './styles.scss';
import Button from 'components/Global/Button';
import { IGroupGet } from 'cubiq-abacus-types';
import LoadingSkeleton from './skeleton';
import { useDispatchContext } from 'context/DispatchContext';
import { IconCardEdit } from 'assets';
import EmptyCard from 'components/Global/EmptyCard';

interface GroupStackProps {
  groups: IGroupGet['response'][];
  handleGroupClick: (group: any) => void;
}

const GroupStack: React.FC<GroupStackProps> = ({ groups, handleGroupClick }) => {
  const [loading, setLoading] = useState(true);
  const { dispatched } = useDispatchContext();

  useEffect(() => {
    if (dispatched) setLoading(false);

    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="card-stack">
      {loading ? (
        <LoadingSkeleton />
      ) : groups.length > 0 ? (
        groups.map(group => (
          <Button key={group.groupId} onClick={() => handleGroupClick(group)}>
            <div className="card-item" key={group.groupId}>
              <div className="card-body">
                <label>{group.groupName}</label>
                <p>Students: {group.studentIds.length}</p>
              </div>
              <IconCardEdit width="30" fill="var(--primary)" />
            </div>
          </Button>
        ))
      ) : (
        <EmptyCard showIcon placeholderText='Click the "Create New Button" to Create a New Group' />
      )}
    </div>
  );
};

export default GroupStack;
