import Skeleton from 'react-loading-skeleton';

function GroupStackSkeleton() {
  return (
    <>
      {Array(3)
        .fill(0)
        .map((_, index) => (
          <Skeleton className="skeleton" key={index} height={100} style={{ borderRadius: '10px' }} />
        ))}
    </>
  );
}

export default GroupStackSkeleton;
