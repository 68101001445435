import React, { useEffect, useRef, useState } from 'react';
import { Field, useField, useFormikContext } from 'formik';
import { IconClose } from 'assets';

interface CheckboxGroupProps {
  name: string;
  options: { value: number; label: string }[];
  label: string;
  error: boolean;
  required?: boolean;
}

const CheckboxGroup = ({ name, options, label, error, required }: CheckboxGroupProps) => {
  const { setFieldValue, values } = useFormikContext();
  const [isVisible, setIsVisible] = useState(false);
  const wrapperRef = useRef(null);

  const handleChange = (value) => {
    const currentIndex = values[name].indexOf(value);
    const newChecked = [...values[name]];

    if (currentIndex === -1) {
      newChecked.push(value); // add value if checkbox is unchecked
    } else {
      newChecked.splice(currentIndex, 1); // remove value if checkbox is checked
    }
    setFieldValue(name, newChecked);
  };

  const toggleDropdown = () => setIsVisible(!isVisible); // Function to toggle dropdown visibility

  // Function to handle outside click
  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setIsVisible(false); // Close the dropdown if click is outside the component
    }
  };

  const handleClickInside = (event) => {
    event.stopPropagation(); // Prevent click from propagating to parent elements
  };

  // Function to get labels of unchecked options
  const getCheckedLabels = () => {
    return options
      .filter((option) => !values[name].includes(option.value))
      .map((option) => (
        <div key={option.value} className="unchecked-label">
          {option.label}
        </div>
      ));
  };

  useEffect(() => {
    // Add event listener when the component mounts
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Cleanup the event listener when the component unmounts
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className={`field-flex ${error && 'error'}`}>
      <label>
        {label}:{required && <span className="asterisk">*</span>}:
      </label>
      <div onClick={toggleDropdown} className="input-checkbox">
        {getCheckedLabels() ? getCheckedLabels() : 'Click to select'}
        {isVisible && (
          <div ref={wrapperRef} className="input-checkbox-dropdown" onClick={handleClickInside}>
            {options.map((option) => (
              <label key={option.value} className="input-checkbox-dropdown-item">
                <Field type="checkbox" name={name} value={option.value} checked={values[name].indexOf(option.value) === -1} onChange={() => handleChange(option.value)} />
                {option.label}
              </label>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default CheckboxGroup;
