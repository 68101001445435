import { Link, NavLink, Outlet } from 'react-router-dom';
import styles from './Nav.module.scss';
import * as ROUTES from 'helpers/routes';
import { useAuth0 } from '@auth0/auth0-react';
import { IconLogo } from 'assets';

export default function TeacherNav() {
  const { logout, user } = useAuth0();

  const handleLogout = () => {
    if (window.confirm('Are you sure you want to log out?')) {
      localStorage.removeItem('tokenAdmin');
      logout({ logoutParams: { returnTo: `${window.location.origin}${ROUTES.TEACHER_LOGIN}` } });
    }
  };

  return (
    <div className={styles.viewport}>
      <div className={styles.nav}>
        <Link to={ROUTES.HOME} className={styles.logo}>
          <IconLogo height="40" />
        </Link>
        <div className={styles.links}>
          <NavLink to={ROUTES.TEACHER_STUDENTS} className={({ isActive }) => (isActive ? styles.activeLink : '')}>
            Students
          </NavLink>
          <NavLink to={ROUTES.TEACHER_HOMEWORK} className={({ isActive }) => (isActive ? styles.activeLink : '')}>
            Homework
          </NavLink>
          <NavLink to={ROUTES.TEACHER_ABACUS} className={({ isActive }) => (isActive ? styles.activeLink : '')}>
            Abacus
          </NavLink>
        </div>
        <img className={styles.navbarProfile} src={user.picture} alt="profile picture" onClick={handleLogout} />
      </div>
      <Outlet />
    </div>
  );
}
