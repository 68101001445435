import React, { useEffect } from 'react';
import styles from './DisplayColumn.module.scss';

interface DisplayQuestionProps {
  currentQuestion: any;
  setStopSequence: (stopSequence: boolean) => void;
}

function parseInput(input) {
  // Split the string by spaces to separate numbers and operators
  const parts = input.split(' ');
  const result = [];
  let pendingOperator = ''; // Initialize an empty pending operator

  parts.forEach((part) => {
    if (part === '-' || part === '+') {
      // If the part is an operator, set it as pending to add to the next number
      // "+" operator is treated by not appending it, "-" is directly appended
      pendingOperator = part;
    } else {
      // If the part is a number and there's a pending "-" operator, prepend it to the number
      const numberWithOperator = pendingOperator === '-' ? `${pendingOperator}${part}` : part;
      result.push(numberWithOperator);
      pendingOperator = ''; // Reset the pending operator after using it
    }
  });

  return result;
}

export default function DisplayQuestion({ currentQuestion, setStopSequence }: DisplayQuestionProps) {
  const parsedInput = parseInput(currentQuestion.question);

  useEffect(() => {
    setStopSequence(false);
  }, [currentQuestion]);
  return (
    <div className={styles.questionArea}>
      {/* <div className={styles.questionArea__question}>{props.currentQuestion.question}</div> */}
      {/* <div>{JSON.stringify(props.currentQuestion.question)}</div> */}

      {parsedInput.map((el) => (
        <div className={styles.questionArea__question}>{el}</div>
      ))}
    </div>
  );
}
