import Button from 'components/Global/Button';
import HomeworkTable from 'components/Teacher/HomeworkTable';
import { CreateHomeworkPopup } from 'components/Teacher/Popups';
import OpenHomeworkDetailsPopup from 'components/Teacher/Popups/OpenHomeworkDetailsPopup';
import { IClientTeacherHomeworks } from 'cubiq-abacus-types';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useAppSelector } from 'store/combinedReducer';
import { ITeacherState } from 'store/teacherSlice';

const PageTeacherHomeworks: React.FC = () => {
  const location = useLocation();

  const teacher: ITeacherState = useAppSelector((state) => state.teacher);

  const [showNewHomeworkPopup, setShowNewHomeworkPopup] = useState(false);
  const [selectedHomework, setSelectedHomework] = useState<IClientTeacherHomeworks>(null);

  const handleShowNewHomeworkPopup = () => {
    setShowNewHomeworkPopup(true);
  };

  const handleOpenHomeworkDetails = (homework: IClientTeacherHomeworks) => {
    setSelectedHomework(homework);
  };

  const closePopup = () => {
    setSelectedHomework(null);
    setShowNewHomeworkPopup(false);
  };

  useEffect(() => {
    document.title = 'Manage Homeworks';
  }, []);

  useEffect(() => {
    localStorage.setItem('lastVisitedTeacherRoute', location.pathname);
  }, [location]);

  return (
    <div className="App">
      {/* {JSON.stringify(teacher.homeworks)} */}
      <div className="section-group">
        <div className="section-header">
          <label>Homework</label>
          <Button theme={'light'} onClick={handleShowNewHomeworkPopup}>
            Create New Homework
          </Button>
        </div>
        {showNewHomeworkPopup && <CreateHomeworkPopup closePopup={closePopup} groups={teacher.groups} />}

        <HomeworkTable homeworks={teacher.homeworks} handleOpenHomeworkDetails={handleOpenHomeworkDetails} />
        {selectedHomework && <OpenHomeworkDetailsPopup selectedHomework={selectedHomework} closePopup={closePopup} />}
      </div>
    </div>
  );
};

export default PageTeacherHomeworks;
