import './App.scss';

import { Route, Routes, BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import configureStore from 'store/configureStore';
import 'react-loading-skeleton/dist/skeleton.css';

import CustomizedSnackbar, { SnackProvider } from 'components/Global/Snackbar';

import * as ROUTES from 'helpers/routes';
import PageTeacherStudents from 'components/Pages/PageTeacherStudents';
import RouteTeacher from 'components/Routes/RouteTeacher';
import PageTeacherHomeworks from 'components/Pages/PageTeacherHomeworks';
import PageTeacherAbacus from 'components/Pages/PageTeacherAbacus';
import TeacherNav from 'components/Teacher/Layouts/Nav';
import PageStudentLogin from 'components/Pages/PageStudentLogin';
import PageStudentDashboard from 'components/Pages/PageStudentDashboard';
import Auth0Wrapper from 'components/Routes/Auth0Wrapper';
import PageTeacherLogin from 'components/Pages/PageTeacherLogin';
import ProtectedTeacherRoute from 'components/Routes/ProtectedTeacherRoute';
import PageHome from 'components/Pages/PageHome';
import { DispatchProvider } from 'context/DispatchContext';

const store = configureStore;

function App() {
  return (
    <BrowserRouter>
      <Provider store={store}>
        <SnackProvider>
          <CustomizedSnackbar />
          <Routes>
            <Route path={ROUTES.HOME} element={<PageHome />} />
            <Route path={ROUTES.STUDENT_LOGIN} element={<PageStudentLogin />} />
            <Route path={ROUTES.STUDENT} element={<PageStudentDashboard />} />

            <Route element={<Auth0Wrapper />}>
              <Route path={ROUTES.TEACHER_LOGIN} element={<PageTeacherLogin />} />
              <Route element={<DispatchProvider />}>
                <Route element={<ProtectedTeacherRoute />}>
                  <Route element={<TeacherNav />}>
                    <Route path={ROUTES.TEACHER} element={<RouteTeacher />} />
                    <Route path={ROUTES.TEACHER_STUDENTS} element={<PageTeacherStudents />} />
                    <Route path={ROUTES.TEACHER_HOMEWORK} element={<PageTeacherHomeworks />} />
                    <Route path={ROUTES.TEACHER_ABACUS} element={<PageTeacherAbacus />} />
                  </Route>
                </Route>
              </Route>
            </Route>
          </Routes>
        </SnackProvider>
      </Provider>
    </BrowserRouter>
  );
}

export default App;
