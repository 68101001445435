import { GameStatus, HomeworkStatus, IClientTeacherHomeworks, IHomeworkGet } from 'cubiq-abacus-types';
import moment from 'moment';
import { formatToYYYYMMDD } from 'store/utils/date';
import { formatMilliseconds } from 'store/utils/time';

// Common function to format homework
export const formatHomework = (homework: IHomeworkGet['response']): IClientTeacherHomeworks => {
  const startDate = new Date(homework.dateStart);
  const endDate = new Date(homework.dateEnd);
  const today = new Date();
  const timeDiff = endDate.getTime() - today.getTime();
  const daysDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));

  let expiresIn: string;
  if (today < startDate) {
    expiresIn = '';
  } else if (today > startDate && today < endDate) {
    if (daysDiff === 0) expiresIn = 'Today';
    if (daysDiff === 1) expiresIn = 'Tomorrow';
    if (daysDiff > 1) expiresIn = `In ${daysDiff} days`;
  } else if (today > endDate) {
    if (daysDiff === 0) expiresIn = `Expired Today`;
    if (daysDiff === -1) expiresIn = `Expired Yesterday`;
    if (daysDiff < -1) expiresIn = `Expired ${-daysDiff} days ago`;
  }

  let status: HomeworkStatus;
  if (homework.reviewed) {
    status = HomeworkStatus.Reviewed;
  } else if (today > endDate) {
    status = HomeworkStatus.NeedsReview;
  } else if (today > startDate && today < endDate) {
    status = HomeworkStatus.InProgress;
  } else if (today < startDate) {
    status = HomeworkStatus.NotStarted;
  }

  // Group assignments by their startOn date
  const initiallyGroupedAssignments = (homework.assignmentIds || []).reduce((grouped, assignment) => {
    const startDate = assignment.startOn ? assignment.startOn.toString().split('T')[0] : 'unknown';
    if (!grouped[startDate]) {
      grouped[startDate] = [];
    }
    grouped[startDate].push(assignment);
    return grouped;
  }, {});

  const totalDays = Object.keys(initiallyGroupedAssignments).length;

  const groupedAssignments = Object.entries(initiallyGroupedAssignments).reduce((reformatted, [date, assignments]: [string, IHomeworkGet['response']['assignmentIds']], index) => {
    const assignmentsForDay = assignments.map((assignment) => {
      const bank = assignment.bank || [];
      const correctAnswersCount = bank.reduce((acc, curr) => acc + (curr.correctAnswer === curr.userAnswer ? 1 : 0), 0);
      const totalQuestions = bank.length;

      return {
        status: assignment.status as GameStatus,
        homeworkId: assignment.homeworkObjectId,
        student: {
          name: assignment.studentObjectId?.name || '',
          email: assignment.studentObjectId?.email || '',
          avatar: assignment.studentObjectId?.avatar || '',
          correctAnswers: `${correctAnswersCount}/${totalQuestions}`,
        },
        dates: {
          startOn: assignment.startOn || '',
          endOn: assignment.endOn || '',
          createdOn: assignment.createdOn || '',
          completedOn: assignment.completedOn || null,
        },
        completedTime: assignment.completedTime ? formatMilliseconds(assignment.completedTime) : null,
        bank: bank.map((q) => (q.userAnswer ? { question: q.question + ' = ' + q.userAnswer, isCorrect: q.correctAnswer === q.userAnswer } : { question: q.question })),
      };
    });

    const totalSubmissionsCount = assignmentsForDay.filter((assignment) => assignment.status === GameStatus.Completed).length;

    const newKey = `Day ${index + 1}/${totalDays}`;
    reformatted[newKey] = {
      items: assignmentsForDay,
      submissions: `${totalSubmissionsCount}/${assignmentsForDay.length}`,
      date: date === 'unknown' ? 'Unknown Date' : moment(date).format('MMM D, YYYY'),
    };

    return reformatted;
  }, {});

  return {
    id: homework._id,
    groupName: homework.groupObjectId?.groupName || '',
    groupId: homework.groupObjectId?.groupId || '',
    gameSettings: homework.gameSettings,
    isEndDatePast: today > endDate,
    startDateRaw: homework.dateStart,
    startDate: moment(homework.dateStart).format('MMM D, YYYY'),
    startDateYYYYMMDD: formatToYYYYMMDD(startDate),
    createdDate: moment(homework.dateCreated).format('MM/DD/YY HH:mm'),
    endDateRaw: homework.dateEnd,
    endDate: moment(homework.dateEnd).format('MMM D, YYYY'),
    endDateYYYYMMDD: formatToYYYYMMDD(endDate),
    expiresIn,
    status,
    assignments: groupedAssignments,
  };
};
