import { FormikErrors, FormikTouched } from 'formik';

interface ErrorSummaryProps<T> {
  errors: FormikErrors<T>;
  touched: FormikTouched<T>;
}

function getCombinedErrors<T>(errors: FormikErrors<T>, touched: FormikTouched<T>) {
  return Object.keys(errors)
    .filter(key => touched[key as keyof T])
    .map(key => errors[key as keyof T])
    .join(', ');
}

const ErrorSummary = <T,>({ errors, touched }: ErrorSummaryProps<T>) => {
  const combinedErrors = getCombinedErrors(errors, touched);

  if (!combinedErrors) {
    return null;
  }

  return <div className="error-summary">{combinedErrors}</div>;
};

export default ErrorSummary;
