import React, { useState } from 'react';
import './styles.scss';
import LoadingSkeleton from './skeleton';
import EmptyCard from 'components/Global/EmptyCard';
import SearchAndFilterHomework from '../SearchAndFilterHomework';
import { IClientTeacherHomeworks } from 'cubiq-abacus-types';
import { ITeacherState } from 'store/teacherSlice';
import { useAppSelector } from 'store/combinedReducer';
import HomeworkTableSingleRowSkeleton from './singleRowSkeleton';

interface HomeworkTableProps {
  homeworks: IClientTeacherHomeworks[];
  handleOpenHomeworkDetails: (homework: IClientTeacherHomeworks) => void;
}

const HomeworkTable: React.FC<HomeworkTableProps> = ({ homeworks, handleOpenHomeworkDetails }) => {
  const [filteredHomeworks, setFilteredHomeworks] = useState<IClientTeacherHomeworks[]>(homeworks);
  const [sortField, setSortField] = useState('name'); // default sort by name
  const [sortDirection, setSortDirection] = useState('asc'); // 'asc' or 'desc'

  const teacher: ITeacherState = useAppSelector((state) => state.teacher);
  // Function to determine the header class
  const getHeaderClass = (field) => {
    return sortField === field ? `sortable-header sorted-${sortDirection}` : 'sortable-header';
  };

  const sortHomeworks = (a, b) => {
    const getValue = (homework: IClientTeacherHomeworks, field) => {
      // Handle different fields and ensure the value is a string
      let value;
      switch (field) {
        case 'assignedTo':
          value = homework.groupName;
          break;
        case 'startDate':
          value = homework.startDateYYYYMMDD;
          break;
        case 'expires':
          value = homework.endDateYYYYMMDD;
          break;
        case 'status':
          value = homework.status;
          break;
        case 'createdDate':
          value = homework.createdDate;
          break;
        default:
          value = '';
      }
      return typeof value === 'string' ? value.toLowerCase() : '';
    };

    const fieldA = getValue(a, sortField);
    const fieldB = getValue(b, sortField);

    if (fieldA < fieldB) return sortDirection === 'asc' ? -1 : 1;
    if (fieldA > fieldB) return sortDirection === 'asc' ? 1 : -1;
    return 0;
  };

  // Shallow copy of filteredHomeworks before sorting
  const sortedHomeworks = [...filteredHomeworks].sort(sortHomeworks);

  const handleSort = (field) => {
    if (sortField === field) {
      // Toggle sort direction
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      // Change sort field and reset direction to ascending
      setSortField(field);
      setSortDirection('asc');
    }
  };

  return (
    <>
      {teacher.allHomeworkLoading ? (
        <LoadingSkeleton />
      ) : homeworks.length > 0 ? (
        <>
          <SearchAndFilterHomework item={homeworks} filteredItems={filteredHomeworks} setFilteredItems={setFilteredHomeworks} showFilter showSearch />
          <table className="homework-table">
            <thead>
              <tr>
                <th className={getHeaderClass('createdDate')} onClick={() => handleSort('createdDate')}>
                  Created
                </th>
                <th className={getHeaderClass('assignedTo')} onClick={() => handleSort('assignedTo')}>
                  Assigned to
                </th>
                <th className={getHeaderClass('startDate')} onClick={() => handleSort('startDate')}>
                  Start Date
                </th>
                <th className={getHeaderClass('expires')} onClick={() => handleSort('expires')}>
                  Expires
                </th>
                <th className={getHeaderClass('status')} onClick={() => handleSort('status')}>
                  Status
                </th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {sortedHomeworks.map((homework) => (
                <tr key={homework.id} onClick={() => handleOpenHomeworkDetails(homework)} className="table-row">
                  <td className="date-td">
                    <span>{homework.createdDate}</span>
                  </td>
                  <td>{homework.groupName}</td>
                  <td>{homework.startDate}</td>
                  <td className={`expiresIn ${homework.status}`}>{homework.expiresIn}</td>
                  <td className={`status ${homework.status}`}>{homework.status}</td>
                  <td className="Details">Details</td>
                </tr>
              ))}
              {teacher.singleHomeworkLoading && <HomeworkTableSingleRowSkeleton />}
            </tbody>
          </table>
        </>
      ) : teacher.singleHomeworkLoading ? (
        <>
          <SearchAndFilterHomework item={homeworks} filteredItems={filteredHomeworks} setFilteredItems={setFilteredHomeworks} showFilter showSearch />
          <table className="homework-table">
            <thead>
              <tr>
                <th className={getHeaderClass('createdDate')} onClick={() => handleSort('createdDate')}>
                  Created
                </th>
                <th className={getHeaderClass('assignedTo')} onClick={() => handleSort('assignedTo')}>
                  Assigned to
                </th>
                <th className={getHeaderClass('startDate')} onClick={() => handleSort('startDate')}>
                  Start Date
                </th>
                <th className={getHeaderClass('expires')} onClick={() => handleSort('expires')}>
                  Expires
                </th>
                <th className={getHeaderClass('status')} onClick={() => handleSort('status')}>
                  Status
                </th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              <HomeworkTableSingleRowSkeleton />
            </tbody>
          </table>
        </>
      ) : (
        <EmptyCard showIcon placeholderText='Click the "Create New Homework" to Create a New Homework' />
      )}
    </>
  );
};

export default HomeworkTable;
