import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { AxiosError, AxiosResponse } from 'axios';
import {
  ErrorResponse,
  IClientTeacherGroups,
  IClientTeacherHomeworks,
  IClientTeacherStudents,
  IGroupDelete,
  IGroupGet,
  IGroupPost,
  IGroupPut,
  IHomeworkDelete,
  IHomeworkGet,
  IHomeworkPost,
  IHomeworkPut,
  IStudentDelete,
  IStudentGet,
  IStudentPost,
  IStudentPut,
  IStudentUploadAvatar,
} from 'cubiq-abacus-types';
import { enqueueSnackbar } from 'store/snackbarSlice';
import CUBIQ from 'helpers/api';
import * as helper from './helpers';
import { checkToken, handleError } from 'store/utils/helpers';

export interface ITeacherState {
  students: IClientTeacherStudents[];
  studentsLoading: boolean;
  groups: IClientTeacherGroups[];
  groupsLoading: boolean;
  homeworks: IClientTeacherHomeworks[];
  allHomeworkLoading: boolean;
  singleHomeworkLoading: boolean;
  errors: AxiosResponse<ErrorResponse, any>[];
}

const initialState: ITeacherState = {
  students: [],
  studentsLoading: false,
  groups: [],
  groupsLoading: false,
  homeworks: [],
  allHomeworkLoading: false,
  singleHomeworkLoading: false,
  errors: [],
};

const slice = createSlice({
  name: 'teacher',
  initialState,
  reducers: {
    checkingToken: (_) => {},
    noTokenFound: (_) => {},
    tokenFound: (_) => {},
    studentsRequested: (_) => {
      _.studentsLoading = true;
    },
    studentsReceived: (_, { payload: response }: PayloadAction<IStudentGet['response'][]>) => {
      _.students = response;
      _.studentsLoading = false;
    },
    studentsFailed: (_, { payload: response }: PayloadAction<AxiosResponse<ErrorResponse, any>>) => {
      _.studentsLoading = false;
      _.errors.push(response);
    },
    studentAdded: (_, { payload: response }: PayloadAction<IStudentPost['response']>) => {
      _.students.push(response.student);
      _.groups = helper.addStudentToGroup(_.groups, response.student);
    },
    studentUpdated: (_, { payload: response }: PayloadAction<IStudentPut['response']>) => {
      const { updatedStudents, groups } = helper.updateStudent(_.students, response.student, _.groups);

      _.students = updatedStudents;
      _.groups = groups;
    },
    studentDeleted: (_, { payload: response }: PayloadAction<IStudentDelete['response']>) => {
      _.students = _.students.filter((student) => student.email !== response.student.email);
      _.groups = helper.removeStudentEmailFromGroup(_.groups, response.student);
      _.homeworks = helper.removeAssignmentsOfDeletedStudent(_.homeworks, response.student.email);
    },
    studentAvatarUpdating: (_) => {},
    studentAvatarUpdated: (_, { payload: response }: PayloadAction<{ email: string; avatarUrl: string }>) => {
      const studentIndex = _.students.findIndex((student) => student.email === response.email);
      if (studentIndex !== -1) {
        _.students[studentIndex].avatar = response.avatarUrl;
      }
    },
    studentAvatarUpdateFailed: (_) => {},
    groupsRequested: (_) => {
      _.groupsLoading = true;
    },
    groupsReceived: (_, { payload: response }: PayloadAction<IGroupGet['response'][]>) => {
      _.groups = response;
      _.groupsLoading = false;
    },
    groupsFailed: (_, { payload: response }) => {
      _.groupsLoading = false;
      _.errors.push(response);
    },
    groupAdded: (_, { payload: response }: PayloadAction<IGroupPost['response']>) => {
      _.groups.push(response.group);
      _.students = helper.updateStudentGroupInfo(_.students, response.group);
    },
    groupUpdated: (_, { payload: response }: PayloadAction<IGroupPut['response']>) => {
      _.groups = helper.replaceGroupDetails(_.groups, response.group);
      _.students = helper.reassignStudentGroups(_.students, response.group);
    },
    groupDeleted: (_, { payload: response }: PayloadAction<IGroupDelete['response']>) => {
      _.groups = _.groups.filter((group) => group.groupId !== response.group.groupId);
      _.students = helper.removeGroupFromStudents(_.students, response.group);
      _.homeworks = _.homeworks.filter((homework) => homework.groupId !== response.group.groupId);
    },
    allHomeworkRequested: (_) => {
      _.allHomeworkLoading = true;
    },
    singleHomeworkRequested: (_) => {
      _.singleHomeworkLoading = true;
    },
    homeworksReceived: (_, { payload: response }: PayloadAction<IHomeworkGet['response'][]>) => {
      _.homeworks = helper.getHomeworks(response);
      _.allHomeworkLoading = false;
    },
    homeworkAdded: (_, { payload: response }: PayloadAction<IHomeworkPost['response']>) => {
      _.homeworks = helper.addNewHomework(_.homeworks, response.homework);
    },
    homeworkDeleted: (_, { payload: response }: PayloadAction<IHomeworkDelete['response']>) => {
      _.homeworks = _.homeworks.filter((homework) => homework.id !== response.homework.homeworkId);
    },
    homeworksFailed: (_, { payload: response }) => {
      _.singleHomeworkLoading = false;
      _.allHomeworkLoading = false;
      _.errors.push(response);
    },
    setHomeworksLoading: (_, { payload: isLoading }: PayloadAction<boolean>) => {
      _.allHomeworkLoading = isLoading;
    },
    setSingleHomeworkLoading: (_, { payload: isLoading }: PayloadAction<boolean>) => {
      _.singleHomeworkLoading = isLoading;
    },
  },
});

const reducer = slice.actions;
export type TeacherReducer = typeof slice.actions;

export default slice.reducer;

// Action Creators

export const getStudents = () => (dispatch) => {
  const authHeader = checkToken(dispatch, reducer);
  if (!authHeader) return;

  dispatch(reducer.studentsRequested());
  CUBIQ('/api/admin/students', {
    method: 'GET',
    headers: authHeader,
  })
    .then((res: AxiosResponse<IStudentGet['response'][]>) => {
      // dispatch(enqueueSnackbar('Got students', { variant: 'success' }));
      dispatch(reducer.studentsReceived(res.data));
    })
    .catch((err: AxiosError<ErrorResponse>) => {
      handleError(dispatch, err, reducer.studentsFailed);
    });
};

export const postStudent = (payload: IStudentPost['payload']) => (dispatch) => {
  const authHeader = checkToken(dispatch, reducer);
  if (!authHeader) return;

  CUBIQ('/api/admin/students', {
    method: 'POST',
    data: payload,
    headers: authHeader,
  })
    .then((res: AxiosResponse<IStudentPost['response']>) => {
      dispatch(enqueueSnackbar('New student added', { variant: 'success' }));
      dispatch(reducer.studentAdded(res.data));
    })
    .catch((err: AxiosError<ErrorResponse>) => {
      handleError(dispatch, err, reducer.studentsFailed);
    });
};

// Add this to your action creators
export const updateStudent = (payload: IStudentPut['payload']) => (dispatch) => {
  const authHeader = checkToken(dispatch, reducer);
  if (!authHeader) return;

  CUBIQ(`/api/admin/students`, {
    method: 'PUT',
    data: payload,
    headers: authHeader,
  })
    .then((res: AxiosResponse<IStudentPut['response']>) => {
      dispatch(enqueueSnackbar('Student updated', { variant: 'success' }));
      dispatch(reducer.studentUpdated(res.data));
    })
    .catch((err: AxiosError<ErrorResponse>) => {
      handleError(dispatch, err, reducer.studentsFailed);
    });
};

export const uploadStudentProfileAndUpdate = (formData, payload: IStudentPut['payload']) => (dispatch) => {
  const authHeader = checkToken(dispatch, reducer);
  if (!authHeader) return;

  const url = `/api/admin/students/profile?email=${encodeURIComponent(payload.email)}`;

  dispatch(reducer.tokenFound());
  dispatch(reducer.studentAvatarUpdating());
  CUBIQ(url, {
    method: 'POST',
    data: formData,
    headers: authHeader,
  })
    .then((res: AxiosResponse<IStudentUploadAvatar['response']>) => {
      dispatch(enqueueSnackbar('Profile sent', { variant: 'success' }));
      dispatch(reducer.studentAvatarUpdated(res.data));

      dispatch(updateStudent({ ...payload, avatar: res.data.avatarUrl }));
    })
    .catch((err: AxiosError<ErrorResponse>) => {
      handleError(dispatch, err, reducer.studentAvatarUpdateFailed);
    });
};

export const deleteStudent = (payload: IStudentDelete['payload']) => (dispatch) => {
  const authHeader = checkToken(dispatch, reducer);
  if (!authHeader) return;

  CUBIQ(`/api/admin/students`, {
    method: 'DELETE',
    data: payload,
    headers: authHeader,
  })
    .then((res: AxiosResponse<IStudentDelete['response']>) => {
      dispatch(enqueueSnackbar('Student removed', { variant: 'success' }));
      dispatch(reducer.studentDeleted(res.data));
    })
    .catch((err: AxiosError<ErrorResponse>) => {
      handleError(dispatch, err, reducer.studentsFailed);
    });
};

export const getGroups = () => (dispatch) => {
  const authHeader = checkToken(dispatch, reducer);
  if (!authHeader) return;

  dispatch(reducer.groupsRequested());
  CUBIQ('/api/admin/groups', {
    method: 'GET',
    headers: authHeader,
  })
    .then((res: AxiosResponse<IGroupGet['response'][]>) => {
      // dispatch(enqueueSnackbar('Got groups', { variant: 'success' }));
      dispatch(reducer.groupsReceived(res.data));
    })
    .catch((err: AxiosError<ErrorResponse>) => {
      handleError(dispatch, err, reducer.groupsFailed);
    });
};

export const postGroup = (payload: IGroupPost['payload']) => (dispatch) => {
  const authHeader = checkToken(dispatch, reducer);
  if (!authHeader) return;

  CUBIQ('/api/admin/groups', {
    method: 'POST',
    data: payload,
    headers: authHeader,
  })
    .then((res: AxiosResponse<IGroupPost['response']>) => {
      dispatch(enqueueSnackbar('New group created', { variant: 'success' }));
      dispatch(reducer.groupAdded(res.data));
    })
    .catch((err: AxiosError<ErrorResponse>) => {
      handleError(dispatch, err, reducer.groupsFailed);
    });
};

export const updateGroup = (payload: IGroupPut['payload']) => (dispatch) => {
  const authHeader = checkToken(dispatch, reducer);
  if (!authHeader) return;

  CUBIQ('/api/admin/groups', {
    method: 'PUT',
    data: payload,
    headers: authHeader,
  })
    .then((res: AxiosResponse<IGroupPut['response']>) => {
      dispatch(enqueueSnackbar('Group updated', { variant: 'success' }));
      dispatch(reducer.groupUpdated(res.data));
    })
    .catch((err: AxiosError<ErrorResponse>) => {
      handleError(dispatch, err, reducer.groupsFailed);
    });
};

export const deleteGroup = (payload: IGroupDelete['payload']) => (dispatch) => {
  const authHeader = checkToken(dispatch, reducer);
  if (!authHeader) return;

  CUBIQ(`/api/admin/groups`, {
    method: 'DELETE',
    data: payload,
    headers: authHeader,
  })
    .then((res: AxiosResponse<IGroupDelete['response']>) => {
      dispatch(enqueueSnackbar('Group deleted', { variant: 'success' }));
      dispatch(reducer.groupDeleted(res.data));
    })
    .catch((err: AxiosError<ErrorResponse>) => {
      handleError(dispatch, err, reducer.groupsFailed);
    });
};

export const getHomeworks = () => (dispatch) => {
  const authHeader = checkToken(dispatch, reducer);
  if (!authHeader) return;

  dispatch(reducer.allHomeworkRequested());
  CUBIQ('/api/admin/homeworks', {
    method: 'GET',
    headers: authHeader,
  })
    .then((res: AxiosResponse<IHomeworkGet['response'][]>) => {
      setTimeout(() => {
        dispatch(reducer.setHomeworksLoading(false));
        dispatch(reducer.homeworksReceived(res.data));
      }, 2000);
    })
    .catch((err: AxiosError<ErrorResponse>) => {
      handleError(dispatch, err, reducer.homeworksFailed);
    });
};

export const postHomework = (payload: IHomeworkPost['payload']) => (dispatch) => {
  const authHeader = checkToken(dispatch, reducer);
  if (!authHeader) return;

  dispatch(reducer.singleHomeworkRequested());
  CUBIQ('/api/admin/homeworks', {
    method: 'POST',
    data: payload,
    headers: authHeader,
  })
    .then((res: AxiosResponse<IHomeworkPost['response']>) => {
      dispatch(enqueueSnackbar('Creating homework', { variant: 'info' }));
      setTimeout(() => {
        dispatch(reducer.setSingleHomeworkLoading(false));
        dispatch(reducer.homeworkAdded(res.data));
        dispatch(enqueueSnackbar('Homework Created', { variant: 'success' }));
      }, 2000);
    })
    .catch((err: AxiosError<ErrorResponse>) => {
      handleError(dispatch, err, reducer.homeworksFailed);
    });
};

export const deleteHomework = (payload: IHomeworkDelete['payload']) => (dispatch) => {
  const authHeader = checkToken(dispatch, reducer);
  if (!authHeader) return;

  CUBIQ('/api/admin/homeworks', {
    method: 'DELETE',
    data: payload,
    headers: authHeader,
  })
    .then((res: AxiosResponse<IHomeworkDelete['response']>) => {
      dispatch(enqueueSnackbar('Homework deleted', { variant: 'success' }));
      dispatch(reducer.homeworkDeleted(res.data));
    })
    .catch((err: AxiosError<ErrorResponse>) => {
      handleError(dispatch, err, reducer.homeworksFailed);
    });
};

export const putHomework = (payload: IHomeworkPut['payload']) => (dispatch) => {
  const authHeader = checkToken(dispatch, reducer);
  if (!authHeader) return;

  CUBIQ('/api/admin/homeworks', {
    method: 'PUT',
    data: payload,
    headers: authHeader,
  })
    .then((res: AxiosResponse<IHomeworkDelete['response'][]>) => {
      dispatch(enqueueSnackbar('Homework marked as reviewed', { variant: 'success' }));
      dispatch(getHomeworks()); // TODO: This is a temporary fix, we should be able to add the homework to the state without fetching all the homeworks again
    })
    .catch((err: AxiosError<ErrorResponse>) => {
      handleError(dispatch, err, reducer.homeworksFailed);
    });
};
