import Button from 'components/Global/Button';
import { GameStatus, GameType, HomeworkStatus, IClientTeacherHomeworks, IHomeworkDelete, IHomeworkPut } from 'cubiq-abacus-types';
import React, { useState } from 'react';
import styles from './styles.module.scss';
import { IconCorrectAnswer, IconDelete, IconEyeFill, IconEyeSlashFill, IconGamemodeColumn, IconGamemodeFlash, IconIncorrectAnswer, IconInfo, IconStopwatch, IconTableSearch } from 'assets';
import { deleteHomework, putHomework } from 'store/teacherSlice';
import { useAppDispatch } from 'store/configureStore';
import { getStudentAvatar } from 'components/utils/getStudentAvatar';

interface OpenHomeworkDetailsPopupProps {
  selectedHomework: IClientTeacherHomeworks;
  closePopup: () => void;
}

const OpenHomeworkDetailsPopup: React.FC<OpenHomeworkDetailsPopupProps> = ({ selectedHomework, closePopup }) => {
  const dispatch = useAppDispatch();

  const [showAssignments, setShowAssignments] = useState<{ [key: string]: boolean }>({});
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredAssignmentCount, setFilteredAssignmentCount] = useState(0);
  const totalAssignmentCount = Object.values(selectedHomework.assignments).reduce((acc, assignmentList) => acc + assignmentList.items.length, 0);

  const toggleAssignment = (dayKey: string) => {
    setShowAssignments((prev) => ({ ...prev, [dayKey]: !prev[dayKey] }));
  };

  // Add this function at the top of your file, after the imports
  const getCategoryAcronym = (category: string): string => {
    return category
      .split(' ')
      .map((word) => word[0])
      .join('');
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const query = event.target.value;
    setSearchQuery(query);

    const count = Object.values(selectedHomework.assignments).reduce((acc, assignmentList) => {
      return acc + assignmentList.items.filter((assignment) => assignment.student.name.toLowerCase().includes(query)).length;
    }, 0);

    setFilteredAssignmentCount(count);
  };

  const filteredAssignments = Object.entries(selectedHomework.assignments)
    .map(([dayKey, assignmentList]) => {
      // Filter the items based on the search query
      const filteredItems = assignmentList.items.filter((assignment) => assignment.student.name.toLowerCase().includes(searchQuery.toLowerCase()));

      return { dayKey, assignmentList: { ...assignmentList, items: filteredItems } };
    })
    .reverse(); // Reverse for display in the correct order

  React.useEffect(() => {
    setFilteredAssignmentCount(totalAssignmentCount);
  }, [totalAssignmentCount]);

  const handleDeleteHomework = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    if (window.confirm('Are you sure you want to delete this homework? Keep in mind that the history of this homework will be lost.')) {
      const payload: IHomeworkDelete['payload'] = { homeworkId: selectedHomework.id };
      dispatch(deleteHomework(payload));
      closePopup();
    }
  };

  const handleMarkAsReviewed = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    // Assuming you have the homework ID and the action expects an object with an ID and the new status
    const payload: IHomeworkPut['payload'] = { homeworkId: selectedHomework.id };
    dispatch(putHomework(payload));
    closePopup();
  };

  return (
    <>
      <div className={styles.overlay} onClick={closePopup}></div>

      <div className={styles.popup}>
        <div className={styles.sectionHeader}>
          <label>Homework Details</label>
          <Button className={styles['list-action-button']} onClick={(e) => handleDeleteHomework(e)}>
            <p style={{ color: 'var(--red_text)' }}>Delete</p>
            <IconDelete width="20" fill="var(--red_text)" />
          </Button>
        </div>
        <div className={styles.settingsList}>
          <div className={styles.settingsList__item}>
            <label>Status</label>
            <label className={styles.settingsList__item__right} id={selectedHomework.status}>
              {selectedHomework.status}
            </label>
          </div>
          <div className={styles.settingsList__item}>
            <label>Class</label>
            <label>{selectedHomework.groupName}</label>
          </div>
          <div className={styles.settingsList__item}>
            <label>Created Date</label>
            <label>{selectedHomework.createdDate}</label>
          </div>
          <div className={styles.settingsList__item}>
            <label>Start Date</label>
            <label>{selectedHomework.startDate}</label>
          </div>
          <div className={styles.settingsList__item}>
            <label>End Date</label>
            <label>{selectedHomework.endDate}</label>
          </div>
          <div
            className={styles.settingsList__item}
            title={`Game Mode: ${selectedHomework.gameSettings.mode}
Category: ${selectedHomework.gameSettings.category}
Flash Speed: ${selectedHomework.gameSettings.flashIn ? (selectedHomework.gameSettings.flashIn / 1000).toFixed(1) + 's' : 'N/A'}
Rounds: ${selectedHomework.gameSettings.rounds}
Chain: ${selectedHomework.gameSettings.chain + 2}`}
          >
            <label>Game Settings</label>
            <div className={styles.settingsList__item__iconGroup}>
              {selectedHomework.gameSettings.mode === GameType.Column ? <IconGamemodeColumn width="20" /> : <IconGamemodeFlash width="20" />}
              <label>
                {`${getCategoryAcronym(selectedHomework.gameSettings.category)}, ${selectedHomework.gameSettings.flashIn ? (selectedHomework.gameSettings.flashIn / 1000).toFixed(1) + 's, ' : ''}${selectedHomework.gameSettings.rounds}r`}
              </label>
              <IconInfo width="20" fill="var(--primary)" />
            </div>
          </div>

          {/* <div className={styles.settingsList__item}>
            <label>Game Mode</label>
            <label>{selectedHomework.gameSettings.mode}</label>
          </div>
          <div className={styles.settingsList__item}>
            <label>Game Category</label>
            <label>{selectedHomework.gameSettings.category}</label>
          </div>
          <div className={styles.settingsList__item}>
            <label>GameChain</label>
            <label>{selectedHomework.gameSettings.chain + 2}</label>
          </div>
          <div className={styles.settingsList__item}>
            <label>Game Rounds</label>
            <label>{selectedHomework.gameSettings.rounds}</label>
          </div> */}
          {/* {selectedHomework.gameSettings.mode === GameType.Flash && (
            <div className={styles.settingsList__item}>
              <label>Flash Speed</label>
              <label>{(selectedHomework.gameSettings.flashIn / 1000).toFixed(1)} sec</label>
            </div>
          )} */}
        </div>
        <div className={styles.separator} />
        <div className={styles.sectionHeader}>
          <label>Daily Assignments</label>
        </div>
        {/* Search Bar */}
        <div className={styles.searchBar}>
          <div className={styles.searchBar__row}>
            <IconTableSearch width="30" height="30" style={{ flexShrink: 0 }} />
            <input type="text" placeholder="Search by name..." value={searchQuery} onChange={handleSearch} />
          </div>
          <div className={styles.searchBar__indicator}>
            Results: {filteredAssignmentCount}/{totalAssignmentCount}
          </div>
        </div>
        <div className={styles.assignmentsList}>
          {filteredAssignments.map(({ dayKey, assignmentList }) => {
            const totalItems = selectedHomework.assignments[dayKey].items.length;
            const filteredItemsCount = assignmentList.items.length;

            return (
              <>
                <div className={styles.assignmentsList__wrapper} onClick={() => toggleAssignment(dayKey)}>
                  <div className={styles.assignmentsList__item}>
                    <label>{dayKey}</label>
                    <div className={styles.btnShowHide}>
                      {showAssignments[dayKey] ? 'Hide' : 'Show'}
                      {showAssignments[dayKey] ? <IconEyeSlashFill height="28" fill="var(--primary)" /> : <IconEyeFill height="28" fill="var(--primary)" />}
                    </div>
                  </div>

                  <div className={styles.assignmentsList__item}>
                    <label className={styles.smallGreyText}>{assignmentList.date}</label>
                    <label className={styles.smallGreyText}>submissions: {assignmentList.submissions}</label>
                  </div>
                </div>
                {showAssignments[dayKey] && (
                  <div className={styles.studentAssignmentList}>
                    {assignmentList.items.map((assignment) => (
                      <div className={styles.studentAssignmentList__item}>
                        <div className={styles.studentAssignmentList__item__header}>
                          <img className={styles.avatarImage} src={getStudentAvatar(assignment.student.avatar)} alt="" />

                          <div className={styles.studentAssignmentList__item__details}>
                            <label>{assignment.student.name}</label>
                            {assignment.status === GameStatus.Completed && (
                              <div className={styles.studentAssignmentList__item__details__meta}>
                                <label className={styles.smallGreyText}>{`Correct ${assignment.student.correctAnswers} | ${assignmentList.date}`}</label>
                                <label className={styles.studentAssignmentList__item__details__meta__right}>
                                  <IconStopwatch width="18" fill="var(--grey)" />
                                  <label className={styles.smallGreyText}>{assignment.completedTime}</label>
                                </label>
                              </div>
                            )}
                            {assignment.status === GameStatus.Failed && <label className={styles.smallGreyText}>Failed to submit</label>}
                            {assignment.status === GameStatus.Pending && <label className={styles.smallGreyText}>Waiting for submission...</label>}
                          </div>
                        </div>
                        <div className={styles.studentAssignmentList__item__bank}>
                          {assignment.bank.map((question) => (
                            <div className={styles.studentAssignmentList__item__bank__item}>
                              {assignment.status === GameStatus.Completed && <label>{question.isCorrect ? <IconCorrectAnswer width="15" /> : <IconIncorrectAnswer width="15" />}</label>}
                              <label>{question.question}</label>
                            </div>
                          ))}
                        </div>
                      </div>
                    ))}
                    {filteredItemsCount < totalItems && (
                      <div className={styles.hiddenAssignmentsMessage}>
                        Some assignments are hidden
                        <br />
                        Showing {filteredItemsCount}/{totalItems} assignments
                      </div>
                    )}
                  </div>
                )}
              </>
            );
          })}
        </div>
        <div className={styles['button-footer']}>
          <Button
            theme={'secondary'}
            onClick={() => {
              closePopup();
            }}
          >
            Cancel
          </Button>

          {selectedHomework.status !== HomeworkStatus.Reviewed && (
            <Button theme={'primary'} onClick={handleMarkAsReviewed} disabled={!selectedHomework.isEndDatePast} title={!selectedHomework.isEndDatePast ? 'The end date should be passed in order to review' : ''}>
              Mark as Reviewed
            </Button>
          )}
        </div>
      </div>
    </>
  );
};

export default OpenHomeworkDetailsPopup;
