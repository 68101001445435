import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import * as ROUTES from 'helpers/routes';

function RouteTeacher() {
  const navigate = useNavigate();

  useEffect(() => {
    const lastVisitedTeacherRoute = localStorage.getItem('lastVisitedTeacherRoute');
    navigate(lastVisitedTeacherRoute || ROUTES.TEACHER_STUDENTS);
  }, [navigate]);

  return null;
}

export default RouteTeacher;
