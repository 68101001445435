import styles from '../style.module.scss';
import { IconGameSettingsChain, IconGameSettingsDigits, IconGameSettingsInterval, IconGameSettingsRounds, IconGamemodeColumn, IconGamemodeFlash, IconPillTriangle } from 'assets';
import { IStudentState } from 'store/studentSlice';
import Button from 'components/Global/Button';
import { GameType } from 'cubiq-abacus-types';
import Pill from 'components/Global/Pill';
import { useAppSelector } from 'store/combinedReducer';
import { useNavigate } from 'react-router-dom';
import * as ROUTES from 'helpers/routes';

export default function StudentHomeworkTab__SectionPendingAssignments() {
  const student: IStudentState = useAppSelector((state) => state.student);
  const navigate = useNavigate();

  const handleStartButtonClick = (assignmentId) => {
    navigate(`${ROUTES.STUDENT}?asId=${assignmentId}`);
  };

  return (
    <div className={styles.section}>
      <h1 className={styles.section__title}>Homework Assignments: {student.assignments.length}</h1>
      {student.assignments.length === 0 && (
        <div className={styles.empty}>
          <img src="/illustrations/hedgehog-correct.png" className={styles.empty__img} alt="" />
          <p className={styles.empty__text}>
            <span>Great job! All caught up!</span>
          </p>
        </div>
      )}
      {student.assignments.length > 0 && (
        <div className={styles.cards}>
          {student.assignments.map((assignment, index) => {
            return (
              <div className={styles.cards__item} key={index}>
                <div className={styles.cards__item__top}>
                  <div className={styles.cards__item__header}>
                    {assignment.homeworkObjectId.gameSettings.mode === GameType.Column && <IconGamemodeColumn width="50" />}
                    {assignment.homeworkObjectId.gameSettings.mode === GameType.Flash && <IconGamemodeFlash width="50" />}
                    <div className={styles.cards__item__header__text}>
                      <h1>{assignment.homeworkObjectId.gameSettings.mode} Mode</h1>
                      {assignment.daysDiff === 0 && (
                        <Pill theme={'warn'}>
                          <IconPillTriangle width="12" fill={'var(--yellow)'} />
                          {assignment.due}
                        </Pill>
                      )}
                      {assignment.daysDiff < 0 && (
                        <Pill theme={'danger'}>
                          <IconPillTriangle width="12" fill={'var(--red)'} />
                          {assignment.due}
                        </Pill>
                      )}
                    </div>
                  </div>
                  <div className={styles.settings}>
                    <div className={styles.settings__row}>
                      <div className={styles.settings__row__group}>
                        <IconGameSettingsDigits width="25" fill="var(--black)" />
                        <p>Mode</p>
                      </div>
                      <p>{assignment.homeworkObjectId.gameSettings.category}</p>
                    </div>
                    <div className={styles.settings__row}>
                      <div className={styles.settings__row__group}>
                        <IconGameSettingsRounds width="25" fill="var(--black)" />
                        <p>Rounds</p>
                      </div>
                      <p>{assignment.homeworkObjectId.gameSettings.rounds}</p>
                    </div>
                    <div className={styles.settings__row}>
                      <div className={styles.settings__row__group}>
                        <IconGameSettingsChain width="25" fill="var(--black)" />
                        <p>Chain</p>
                      </div>
                      <p>{assignment.homeworkObjectId.gameSettings.chain}</p>
                    </div>
                    {assignment.homeworkObjectId.gameSettings.mode === GameType.Flash && (
                      <>
                        <div className={styles.settings__row}>
                          <div className={styles.settings__row__group}>
                            <IconGameSettingsInterval width="25" fill="var(--black)" />
                            <p>Flash In Speed</p>
                          </div>
                          <p>{assignment.homeworkObjectId.gameSettings.flashInSeconds}s</p>
                        </div>
                      </>
                    )}
                  </div>
                </div>
                <Button theme={'green'} onClick={() => handleStartButtonClick(assignment._id)}>
                  Start
                </Button>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}
