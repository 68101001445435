import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import * as ROUTES from 'helpers/routes';
import TeacherLogin from 'components/Teacher/Login';

const PageTeacherLogin = () => {
  const { isAuthenticated, isLoading } = useAuth0();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoading && isAuthenticated) {
      // Redirect the user to the teacher's default route or a saved last location
      navigate(ROUTES.TEACHER_STUDENTS);
    }
  }, [isAuthenticated, isLoading, navigate]);

  return <TeacherLogin />;
};

export default PageTeacherLogin;
