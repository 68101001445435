import { IconResultsAgain, IconResultsExit } from 'assets';
import styles from './GameResults.module.scss';
import { ITimerDisplay } from 'components/utils/displayTimer';
import { IResults } from '..';

interface GameResultsProps {
  displayTimer: ITimerDisplay;
  results: IResults[];
  exitGame: () => void;
  playAgain: () => void;
}

function checkResults(results: any[]): string {
  const totalResults = results.length;
  const correctResults = results.filter((result) => result.correct).length;
  const incorrectResults = results.filter((result) => !result.correct).length;

  if (incorrectResults === totalResults) {
    return 'Better Luck Next Time';
  } else if (correctResults === totalResults) {
    return 'Congrats! 🎉';
  } else if (correctResults / totalResults >= 0.5) {
    return 'Almost There!';
  } else {
    return '';
  }
}

export default function GameResults({ displayTimer, exitGame, playAgain, results }: GameResultsProps) {
  const timer = displayTimer;
  return (
    <>
      <div className={styles.gameResults__content}>
        <div className={styles.gameResults__content__title}>{checkResults(results)}</div>
        <p className={styles.gameResults__content__score}>
          Your time:{' '}
          <span>
            {timer.minutes}:{timer.seconds}:{timer.milliseconds}
          </span>
        </p>
        <p className={styles.gameResults__content__score}>
          Your score:
          <span>
            {results.filter((result) => result.correct).length}/{results.length}
          </span>
        </p>
      </div>

      <div className={styles.gameResults__buttonContainer}>
        <button onClick={exitGame} className={`${styles.gameResults__button} ${styles.gameResults__button__exit}`}>
          <IconResultsExit width="40" fill="var(--primary)" />
          <p>Exit</p>
        </button>
        <button onClick={playAgain} className={`${styles.gameResults__button} ${styles.gameResults__button__play}`}>
          <p>Play Again</p>
          <IconResultsAgain width="40" />
        </button>
      </div>
    </>
  );
}
