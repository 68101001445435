import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from 'store/combinedReducer';
import { useAppDispatch } from 'store/configureStore';
import { IStudentState, self } from 'store/studentSlice';
import * as ROUTES from 'helpers/routes';
import StudentLogin from 'components/Student/StudentLogin';
import { useDispatchContext } from 'context/DispatchContext';

const PageStudentLogin: React.FC = () => {
  const student: IStudentState = useAppSelector((state) => state.student);
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const { dispatched, setDispatched } = useDispatchContext();

  useEffect(() => {
    document.title = 'Student Login';
  }, []);

  useEffect(() => {
    if (!dispatched) {
      dispatch(self());
      setDispatched(true);
    }
  }, [dispatch]);

  useEffect(() => {
    if (student?.self?.email) {
      navigate(ROUTES.STUDENT);
    }
  }, [student.self, navigate]);

  return <StudentLogin />;
};

export default PageStudentLogin;
