import React from 'react';
import styles from './EmptyCard.module.scss';
import { IconSquiggleArrow } from 'assets';

interface EmptyCardProps {
  placeholderText: string;
  showIcon?: boolean;
}

const EmptyCard: React.FC<EmptyCardProps> = ({ placeholderText, showIcon = false }) => {
  return (
    <div className={styles.emptyCard}>
      <p>{placeholderText}</p>
      {showIcon && <IconSquiggleArrow fill="var(--primary)" width="80" style={{ position: 'absolute', right: 100, top: -10, marginLeft: 20, marginBottom: 60 }} />}
    </div>
  );
};

export default EmptyCard;
