import { IClientTeacherGroups, IClientTeacherHomeworks, IClientTeacherStudents, IGroupDelete, IGroupGet, IGroupPut, IHomeworkGet, IStudentDelete, IStudentGet, IStudentPost } from 'cubiq-abacus-types';

import { formatHomework } from './utility';

// Helper function to add a student to a group
export const addStudentToGroup = (groups: IClientTeacherGroups[], student: IStudentPost['response']['student']): IClientTeacherGroups[] => {
  // Destructure the student object to exclude the groupObjectId property
  const { groupObjectId, ...studentWithoutGroupObjectId } = student;

  return groups.map((group) => {
    if (group.groupId === groupObjectId.groupId) {
      return {
        ...group,
        studentIds: [...group.studentIds, studentWithoutGroupObjectId],
      };
    }
    return group;
  });
};

// Helper function to remove a student from a group
export const removeStudentEmailFromGroup = (groups: IClientTeacherGroups[], student: IStudentDelete['response']['student']): IClientTeacherGroups[] => {
  return groups.map((group) => {
    return {
      ...group,
      studentIds: group.studentIds.filter((s) => s.email !== student.email),
    };
  });
};

// Helper function to update a student's group information
export const updateStudentGroupInfo = (students: IClientTeacherStudents[], group: IGroupGet['response']): IClientTeacherStudents[] => {
  return students.map((student) => {
    let updatedStudent = { ...student };
    group.studentIds.forEach((groupStudent) => {
      if (student.email === groupStudent.email) {
        updatedStudent = {
          ...updatedStudent,
          groupObjectId: {
            ...updatedStudent.groupObjectId,
            groupId: group.groupId,
            groupName: group.groupName,
          },
        };
      }
    });
    return updatedStudent;
  });
};

// Helper function to update a student
export const updateStudent = (students: IClientTeacherStudents[], updatedStudent: IStudentPost['response']['student'], groups: IGroupGet['response'][]): { updatedStudents: IClientTeacherStudents[]; groups: IClientTeacherGroups[] } => {
  // Helper function to remove a student from a group
  const removeStudentFromGroup = (groups: IGroupGet['response'][], student: IStudentGet['response']) => {
    return groups.map((group) => {
      if (group.groupId === student.groupObjectId.groupId) {
        return {
          ...group,
          studentIds: group.studentIds.filter((s) => s.email !== student.email),
        };
      }
      return group;
    });
  };

  const updatedStudents = students.map((student) => {
    if (student.email === updatedStudent.email) {
      // if the group id has changed make sure to update the group, add to the new group and remove from the old group
      if (student.groupObjectId.groupId !== updatedStudent.groupObjectId.groupId) {
        // add to the new group
        const newGroups = addStudentToGroup(groups, updatedStudent);
        // remove from the old group
        groups = removeStudentFromGroup(newGroups, student);
      }
      return updatedStudent;
    }
    return student;
  });

  return { updatedStudents, groups };
};

export const removeGroupFromStudents = (students: IClientTeacherStudents[], group: IGroupDelete['response']['group']): IClientTeacherStudents[] => {
  return students.map((student) => {
    if (student.groupObjectId.groupId === group.groupId) {
      return {
        ...student,
        groupObjectId: {
          ...student.groupObjectId,
          groupId: 'g-unassigned',
          groupName: 'Unassigned',
        },
      };
    }
    return student;
  });
};

export const reassignStudentGroups = (students: IClientTeacherStudents[], group: IGroupPut['response']['group']): IClientTeacherStudents[] => {
  return students.map((student) => {
    const isInUpdatedGroup = group.studentIds.some((studentId) => studentId.email === student.email);
    if (isInUpdatedGroup) {
      return {
        ...student,
        groupObjectId: {
          groupName: group.groupName,
          groupId: group.groupId,
        },
      };
    } else if (student.groupObjectId.groupId === group.groupId) {
      return {
        ...student,
        groupObjectId: {
          groupName: 'Unassigned',
          groupId: 'g-unassigned',
        },
      };
    }
    return student;
  });
};

export const replaceGroupDetails = (groups: IClientTeacherGroups[], updatedGroup: IGroupPut['response']['group']): IClientTeacherGroups[] => {
  return groups.map((group) => {
    if (group.groupId === updatedGroup.groupId) {
      return { ...group, ...updatedGroup };
    }
    return group;
  });
};

export const getHomeworks = (homeworks: IHomeworkGet['response'][]): any => {
  return homeworks.map((homework) => formatHomework(homework));
};

export const addNewHomework = (homeworks: IClientTeacherHomeworks[], homework: IHomeworkGet['response']): any => {
  return [...homeworks, formatHomework(homework)];
};
// Helper function to remove assignments of the deleted student from each homework
export const removeAssignmentsOfDeletedStudent = (homeworks: IClientTeacherHomeworks[], deletedStudentEmail: string) => {
  // Iterate over each homework entry in the object
  Object.keys(homeworks).forEach((homeworkKey) => {
    const homework = homeworks[homeworkKey];

    // Iterate over each day in the assignments
    Object.keys(homework.assignments).forEach((dayKey) => {
      const day = homework.assignments[dayKey];

      // Filter out assignments for the deleted student
      const filteredItems = day.items.filter((item) => item.student.email !== deletedStudentEmail);

      // Count the number of completed assignments in the filteredItems
      const completedAssignmentsCount = filteredItems.filter((item) => item.status === 'completed').length;

      // Check if the filtered items array is empty
      if (filteredItems.length === 0) {
        // Remove the day from assignments if no items remain
        delete homework.assignments[dayKey];
      } else {
        // Update the day's assignments with the filtered items and correct submissions count
        homework.assignments[dayKey] = {
          ...day,
          items: filteredItems,
          // Correctly reflect the number of completed assignments
          submissions: `${completedAssignmentsCount}/${filteredItems.length}`,
        };
      }
    });

    // If after removal, the assignments object is empty, reset it
    if (Object.keys(homework.assignments).length === 0) {
      homework.assignments = {}; // Reset to an empty object if no assignments left
    }
  });

  return homeworks;
};
